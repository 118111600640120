import React, { useEffect } from 'react'
import { StepType, useTour } from './Tour'
import { Box, Typography } from '@material-ui/core';
import { getCookie, saveToCookie } from './ManageCookies';

const SurveyQuestionsTourSteps = ({ navigate }: { navigate: any }) => {
    const { setSteps, setIsOpen, setTourLoading, setCurrentStep } = useTour();

    const steps: StepType[] = [
        {
            selector: "#a9",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>You can add standalone questions into the survey by clicking on 'Standalone Section' button. The standalone questions will automatically be added at the top of the survey form</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 2,
            disableScroll: true,
        },
        {
            selector: "#a1",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>You can add a section into the survey by clicking on the 'Section header' in the left hand side menu, or by clicking on the 'Add new section' button in the survey form</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 2,
            disableScroll: true,
        },
        {
            selector: "#survey-render",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>You can also add a section by clicking on the 'add new section' button</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#survey",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Here you can find all types of survey questions that you can add by drag and drop in the survey builder</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#question-form",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>This one is the form which will be created.</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            disableScroll: true,
            // padding: 0
        },
        {
            selector: "#share-button",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Click here to schedule survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            disableScroll: true,
            // padding: 0
        },
        {
            selector: "#survey-modal",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography> From here you can schedule survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            OnNext: () => {
                navigate.navigate("AdminSurvey")
                saveToCookie("Part two completed");
            },
            position: "bottom",
            disableScroll: true,
            // padding: 0
        },
    ];

    useEffect(() => {
        const part = getCookie("productTour");
        if (part !== "Part two completed" && part === "Part one completed") {
            setSteps?.(steps);
            setCurrentStep(0)
        }
    }, [])

    return null
}

export default SurveyQuestionsTourSteps