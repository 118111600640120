import { ComponentType } from 'react'
import Close, { CloseProps } from './Close'
import Content, { ContentProps } from './Content'
import Navigation, {
  NavigationProps,
} from './Navigation'

export interface PopoverComponents {
  Close: ComponentType<CloseProps>
  Content: ComponentType<ContentProps>
  Navigation: ComponentType<NavigationProps>
}

export type PopoverComponentsType = Partial<PopoverComponents>

export const components = {
  Close,
  Content,
  Navigation
}

export type PopoverComponentGeneric = typeof components

export const defaultComponents = (
  comps: PopoverComponentsType
): PopoverComponentGeneric =>
  ({
    ...components,
    ...comps,
  } as PopoverComponentGeneric)
