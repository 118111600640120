import React, { useRef, useState } from 'react'
import {
    useRect,
    bestPositionOf,
    isOutsideX,
    isOutsideY,
    getWindow,
    getPadding,
    RectResult,
    PositionsObjectType,
    CoordsObjectType,
    CoordType
} from '../utils'

import { StylesObj, stylesMatcher } from './styleData';

const Popover: React.FC<PopoverProps> = ({
    children,
    position: providedPosition = 'bottom',
    padding = 10,
    styles = {},
    sizes,
    refresher,
    ...props
}) => {
    const helperRef = useRef(null)
    const positionRef = useRef('')
    const verticalAlignRef = useRef('')
    const horizontalAlignRef = useRef('')
    const { w: windowWidth, h: windowHeight } = getWindow()
    const getStyles = stylesMatcher(styles)

    const helperRect = useRect(helperRef, refresher)
    const { width: helperWidth, height: helperHeight } = helperRect

    const targetLeft = sizes?.left
    const targetTop = sizes?.top
    const targetRight = sizes?.right
    const targetBottom = sizes?.bottom
    const arrowPosition = useRef<'Top' | 'Bottom' | 'Left' | 'Right' | 'Center'>('Center')
    const position =
        !targetLeft && !targetBottom && !targetLeft && !targetRight ? 'center' : (providedPosition && typeof providedPosition === 'function'
            ? providedPosition(
                {
                    width: helperWidth,
                    height: helperHeight,
                    windowWidth,
                    windowHeight,
                    top: targetTop,
                    left: targetLeft,
                    right: targetRight,
                    bottom: targetBottom,
                    x: sizes.x,
                    y: sizes.y,
                },
                helperRect
            )
            : providedPosition)

    const available: PositionsObjectType = {
        left: targetLeft,
        right: windowWidth - targetRight,
        top: targetTop,
        bottom: windowHeight - targetBottom,
    }

    const [pt, pr, pb, pl] = getPadding(padding)

    const couldPositionAt = (
        position: string,
        isOutsideX: boolean,
        isOutsideY: boolean
    ) => {
        switch (position) {
            case 'top':
                return available.top > helperHeight + pb
            case 'right':
                return isOutsideX ? false : available.right > helperWidth + pl
            case 'bottom':
                return isOutsideY ? false : available.bottom > helperHeight + pt
            case 'left':
                return available.left > helperWidth + pr

            default:
                return false
        }
    }

    const autoPosition = (
        coords: CoordsObjectType,
        outX: boolean,
        outY: boolean
    ): CoordType => {
        const positionsOrder: string[] = bestPositionOf(
            available,
            outY ? ['right', 'left'] : outX ? ['top', 'bottom'] : []
        )
        for (let j = 0; j < positionsOrder.length; j++) {
            if (couldPositionAt(positionsOrder[j], outX, outY)) {
                positionRef.current = positionsOrder[j]
                arrowPosition.current = (getArrowPos(positionsOrder[j]))
                if (sizes.width < windowWidth && (positionsOrder[j] === 'top' || positionsOrder[j] === 'bottom')) {
                    coords[positionsOrder[j]][0] += sizes.width / 2 - helperWidth / 2 + 14
                }
                if (sizes.height < windowHeight && (positionsOrder[j] === 'left' || positionsOrder[j] === 'right')) {
                    coords[positionsOrder[j]][1] +=  sizes.height / 2 - helperHeight / 2 + 14
                }
                if (coords[positionsOrder[j]][0] + helperWidth > windowWidth) coords[positionsOrder[j]][0] = windowWidth - helperWidth - 14;
                if (coords[positionsOrder[j]][1] + helperHeight > windowHeight) coords[positionsOrder[j]][1] = windowHeight - helperHeight - 14;
                if (coords[positionsOrder[j]][0] < 0) coords[positionsOrder[j]][0] = 0;
                if (coords[positionsOrder[j]][1] < 0) coords[positionsOrder[j]][1] = 0;
                return coords[positionsOrder[j]]
            }
        }
        positionRef.current = 'top'
        arrowPosition.current = (getArrowPos('center'))
        if (sizes.width < windowWidth) coords['top'][0] += sizes.width / 2 - helperWidth / 2 + 14;
        if (coords['top'][0] + helperWidth > windowWidth) coords['top'][0] = windowWidth - helperWidth - 14;
        if (coords['top'][1] + helperHeight > windowHeight) coords['top'][1] = windowHeight - helperHeight - 14;
        if (coords['top'][0] < 0) coords['top'][0] = 0;
        if (coords['top'][1] < 0) coords['top'][1] = 0;
        return coords.top
    }

    const pos = (helperPosition: Position) => {
        if (Array.isArray(helperPosition)) {
            const isOutX = isOutsideX(helperPosition[0], windowWidth)
            const isOutY = isOutsideY(helperPosition[1], windowHeight)

            positionRef.current = 'custom'
            return [
                isOutX ? windowWidth / 2 - helperWidth / 2 : helperPosition[0],
                isOutY ? windowHeight / 2 - helperHeight / 2 : helperPosition[1],
            ]
        }

        const isHelperOutsideX = isOutsideX(targetLeft + helperWidth, windowWidth)
        const isHelperOutsideY = isOutsideY(
            targetBottom + helperHeight,
            windowHeight
        )

        const x = isHelperOutsideX
            ? Math.min(targetLeft, windowWidth - helperWidth)
            : Math.max(targetLeft, 0)

        const y = isHelperOutsideY
            ? helperHeight > available.bottom
                ? Math.max(targetBottom - helperHeight, 0)
                : Math.max(targetTop, 0)
            : targetTop

        if (isHelperOutsideY) {
            if (helperHeight > available.bottom) {
                verticalAlignRef.current = 'bottom'
            } else {
                verticalAlignRef.current = 'top'
            }
        } else {
            verticalAlignRef.current = 'top'
        }
        if (isHelperOutsideX) {
            horizontalAlignRef.current = 'left'
        } else {
            horizontalAlignRef.current = 'right'
        }

        const coords = {
            top: [x - 14, targetTop - helperHeight - pb - 14],
            right: [targetRight + pl + 14, y - 14],
            bottom: [x - 14, targetBottom + pt + 14],
            left: [targetLeft - helperWidth - pr - 14, y - 14],
            center: [
                windowWidth / 2 - helperWidth / 2,
                windowHeight / 2 - helperHeight / 2,
            ],
        }

        if (helperPosition === 'center' || (couldPositionAt(helperPosition, isHelperOutsideX, isHelperOutsideY) && !isHelperOutsideX && !isHelperOutsideY)) {
        if (sizes.width < windowWidth && (helperPosition === 'top' || helperPosition === 'bottom')) coords[helperPosition][0] += sizes.width / 2 - helperWidth / 2 + 14
        if (sizes.height < windowHeight && (helperPosition === 'left' || helperPosition === 'right')) coords[helperPosition][1] += sizes.height / 2 - helperHeight / 2 + 14
        if (coords[helperPosition][0] + helperWidth > windowWidth) coords[helperPosition][0] = windowWidth - helperWidth - 14;
        if (coords[helperPosition][1] + helperHeight > windowHeight) coords[helperPosition][1] = windowHeight - helperHeight - 14;
        if (coords[helperPosition][0] < 0) coords[helperPosition][0] = 0;
        if (coords[helperPosition][1] < 0) coords[helperPosition][1] = 0;
            positionRef.current = helperPosition
            arrowPosition.current = (getArrowPos(helperPosition))
            return coords[helperPosition]
        }

        return autoPosition(coords, isHelperOutsideX, isHelperOutsideY)
    }

    const getArrowPos = (position: any = 'center') => {
        if (position === 'top') return 'Bottom';
        if (position === 'bottom') return 'Top';
        if (position === 'left') return 'Right';
        if (position === 'right') return 'Left';
        return 'Center'
    }

    const p = pos(position)

    let arrowLeft = 0, arrowTop = 0;
    switch (arrowPosition.current) {
        case 'Bottom':
        case 'Top':
            arrowLeft = sizes.left + sizes.width / 2 - p[0] - 16;
            if (arrowLeft > helperWidth - 38) arrowLeft = helperWidth - 38
            if (arrowLeft < 0) arrowLeft = 0
            break;
        case 'Right':
        case 'Left':
            arrowTop = sizes.top + sizes.height / 2 - p[1] - 16;
            if (arrowTop > helperHeight - 38) arrowTop = helperHeight - 38
            if (arrowTop < 0) arrowTop = 0
            break;
    }

    return (
        <div
            className="cedreactour__popover"
            style={{
                ...getStyles('popover', {
                    position: positionRef.current,
                    verticalAlign: verticalAlignRef.current,
                    horizontalAlign: horizontalAlignRef.current,
                }),
                transform: `translate(${Math.round(p[0])}px, ${Math.round(p[1])}px)`,
            }}
            ref={helperRef}
            {...props}
        >
            {/* <div className='cedreactour__popover-arrow' style={{
                ...getStyles(`arrow${arrowPosition.current}`, {
                    position: positionRef.current,
                    verticalAlign: verticalAlignRef.current,
                    horizontalAlign: horizontalAlignRef.current,
                }),
                transform: `translate(${arrowLeft}px, ${arrowTop}px)`,
            }} /> */}
            {children}
        </div>
    )
}

export default Popover

export type PopoverProps = {
    sizes: RectResult
    children?: React.ReactNode
    position?: PositionType
    padding?: number | number[]
    styles?: StylesObj
    className?: string
    refresher?: any
}

export type PositionType = Position | ((postionsProps: PositionProps, prevRect: RectResult) => Position)

export type PositionProps = RectResult & {
    windowWidth: number
    windowHeight: number
}

export type Position =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]
