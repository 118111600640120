import React, {
  FunctionComponent,
  // Customizable Area Start
  useEffect,
  useState,
  // Customizable Area End
} from "react";

import { withStyles } from "@material-ui/core/styles";

// Customizable Area Start
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
// Customizable Area End

interface Props {
  // Customizable Area Start
  graphData: any;
  classes: any
  // Customizable Area End
}

// Customizable Area Start
const formatData = (graphData: any) => {
  return graphData?.map((data:any)=>({
    name:data.x_axis_data,
    resp:data.user_count,
  }));
};
// Customizable Area End

const SurveyChart: FunctionComponent<Props> = ({
  // Customizable Area Start
  graphData,
  classes
  // Customizable Area End
}) => {
  // Customizable Area Start
  const [chartWidth, setChartWidth] = useState<number>(0);
  const data = formatData(graphData);
  useEffect(() => {
    const numDataPoints = data.length;
    const minWidth = 300;
    const widthPerDataPoint = 28;
    const newWidth = minWidth + numDataPoints * widthPerDataPoint;

    setChartWidth(Math.min(newWidth, window.innerWidth * 0.9));
  }, [data]);

  const CustomTooltip = ({ active, payload ,coordinate }: any) => {
    const { x, y } = coordinate || {};

    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        return (
            <div className={classes.customTooltip} style={{left: x-20,top: y - 0,position: 'absolute',pointerEvents: 'none',transform: 'translateX(-50%)',whiteSpace: 'nowrap',}}>
                <span>{dataPoint.resp}</span>
            </div>
        );
    }
    return null
  };
  const CustomizedTick = ({ x, y , angle , payload}: any) => {
    return (
      <text
        x={data.length>6 ? x-22 : x}
        y={data.length>6 ? y-22 : y}
        dy={16}
        textAnchor="middle"
        fill="#535557"
        fontFamily="aeonik-regular"
        transform={data.length>6 ? `rotate(${angle},${x},${y})` : 'none'}
        style={{fontSize: '14px'}}
      >
        {payload.value}
      </text>
    );
  };
  
  // Customizable Area End
  return (
    // Customizable Area Start
    <div style={{ width: `${chartWidth}px`}}>
      <ResponsiveContainer className={classes.chart} width="100%" height={250}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 70, }}
           // @ts-ignore
            isAnimationActive={false}
          >
          <CartesianGrid strokeDasharray="none" />
          <XAxis
          style={{
            fontSize: '14px',
            }}
            label={{
              value: 'Date',
              position: 'insideBottom',
              offset: 0,
              dy: 50,
              style: { fontFamily: 'aeonik-regular' , fontSize :"16px" , fill:"#000"}
            }}
            dataKey="name"
            interval={0}
            tick ={<CustomizedTick angle={-60} />}
             />
          <YAxis
          style={{
            fontSize: '14px',
            }}
            tick={{ stroke: '#535557' }}
            label={{ value: 'Number of respondants', dx: -20, angle: -90, position: 'center', fontFamily: "aeonik-regular",fontSize:"16px" , fill:"#000"}}
          />
          
          <Tooltip content={CustomTooltip} />
          <Legend wrapperStyle={{ fontWeight: 900 }} />
          <Bar dataKey="resp" fill="#4B6EB0" barSize={8} radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>

    </div>


    // Customizable Area End
  );
};

// Customizable Area Start

const styles = {
  chart: {
    borderRadius: "10px",
    marginTop: "20px",
    '& .QuestionAnalysis-contentData-52': {
      padding: "10px"
    },
    '& .recharts-cartesian-axis-line': {
      display: "none",
    },
    '& .recharts-cartesian-grid-vertical': {
      display: "none",
    },
    '& .recharts-tooltip-cursor': {
      display: "none" 
    },
    '& .recharts-legend-icon': {
      display: "none"
    },
    '& .recharts-default-legend': {
      display: "none"
    },
    '& .recharts-cartesian-axis-tick-line':{
      display:"none"
    }
  },
  customTooltip: {
    padding: "4px 8px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "14px",
    color: "#333",
    border: "1px solid #ddd",
  }
};

export default withStyles(styles)(SurveyChart);
// Customizable Area End