Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET"; 
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.PutApiMethod = "PUT";
exports.DeleteApiMethod = "DELETE";
exports.UpdateApiMethod = "PUT"; 
exports.PatchApiMethod = "PATCH"; 
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";
exports.getPastApiEndPoint="/bx_block_surveys/surveys/past_surveys"
exports.getGroupApiEndPoint="/bx_block_groups/groups"
exports.createGroupApiEndPoint="/bx_block_groups/groups/create"
exports.btnExampleTitle = "CLICK ME";
exports.getUserListApiEndPoint = "/bx_block_user/users/users_list";
exports.createUserApiEndPoint = "/bx_block_groups/groups/create_user";
exports.updateUserApiEndPoint = "/bx_block_groups/groups/update_user";
exports.deleteUserApiEndPoint = "/bx_block_groups/groups/delete_user"
exports.uploadBulkUserEndPoint = "/bx_block_groups/groups/bulk_upload_users";
exports.selectUserGroupApiEndPoint = "/bx_block_groups/groups/add_user"
exports.getDistrictDetailsApiEndPoint = "/bx_block_user/districts"
exports.getIndustryDetailsApiEndPoint = "/bx_block_user/industry_sectors"
exports.selectUserGroupApiMethod = "POST"
exports.removeUserGroupApiEndPoint = "/bx_block_groups/groups/remove_user"
exports.removeUserGroupApiMethod = "POST"
exports.updateGroupApiEndPoint = "/bx_block_groups/groups/update"
exports.addUserToGroupEndPoint = "/bx_block_groups/groups/add_user"
exports.removeUserFromGroupApiEndPoint = "/bx_block_groups/groups/remove_user"
exports.updateGroupApiMethod = "PUT"
exports.getTagsApiEndPoint="bx_block_tags/tags"
exports.getTagsApiMethod="GET"
exports.createTagApiEndPoint="bx_block_tags/tags"
exports.deactivateTagApiEndPoint="bx_block_tags/tags/"
exports.ReactivateTagApiEndPoint="bx_block_tags/tags/"
exports.createTagApiMethod="POST"
exports.updateTagApiEndPoint="bx_block_tags/tags/"
exports.updateTagApiMethod="PUT"
exports.deleteGroupApiEndPoint = "bx_block_groups/groups/delete_group"
exports.deleteTagApiEndPoint = "bx_block_tags/tags/"
exports.getUserEndPoint = "bx_block_user/users/edit_user";
exports.updateUserEndPoint = "bx_block_user/users/update_user";
// Customizable Area End