import { InViewArgs, PositionsObjectType } from './type.d'

export function safe(sum: number): number {
  return sum < 0 ? 0 : sum
}

export function getInViewThreshold(threshold: InViewArgs['threshold']) {
  if (typeof threshold === 'object' && threshold !== null) {
    return {
      thresholdX: threshold.x || 0,
      thresholdY: threshold.y || 0,
    }
  }
  return {
    thresholdX: threshold || 0,
    thresholdY: threshold || 0,
  }
}

export function getWindow(): { w: number; h: number } {
  const w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  const h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )
  return { w, h }
}

export function disableScroll() {
  document.body.classList.add("stop-scrolling");
}

export function enableScroll() {
  document.body.classList.remove("stop-scrolling");
}

export function inView({
  top,
  right,
  bottom,
  left,
  threshold,
}: InViewArgs): boolean {
  const { w: windowWidth, h: windowHeight } = getWindow()
  const { thresholdX, thresholdY } = getInViewThreshold(threshold)

  return (
    (bottom - top) > windowHeight
      ? top > windowHeight ? false : bottom < 0 ? false : true
      :
      top >= (0 + thresholdY) &&
      left >= (0 + thresholdX) &&
      bottom <= (windowHeight - thresholdY) &&
      right <= (windowWidth - thresholdX))
}

export const isHoriz = (pos: string) => /(left|right)/.test(pos)
export const isOutsideX = (val: number, windowWidth: number): boolean => {
  return val > windowWidth
}
export const isOutsideY = (val: number, windowHeight: number): boolean => {
  return val > windowHeight
}

export function bestPositionOf(
  positions: PositionsObjectType,
  filters: string[] = []
): string[] {
  const compareFn = (a: string, b: string) =>
    filters.includes(a) ? 1 : filters.includes(b) ? -1 : 0
  return Object.keys(positions)
    .map((p) => {
      return {
        position: p,
        value: positions[p],
      }
    })
    .sort((a, b) => b.value - a.value)
    .sort((a, b) => compareFn(a.position, b.position))
    .filter((p) => p.value > 0)
    .map((p) => p.position)
}

const defaultPadding = 10

export function getPadding(
  padding: number | number[] = defaultPadding
): number[] {
  if (Array.isArray(padding)) {
    if (padding.length === 1) {
      return [padding[0], padding[0], padding[0], padding[0]]
    }
    if (padding.length === 2) {
      return [padding[1], padding[0], padding[1], padding[0]]
    }
    if (padding.length === 3) {
      return [padding[0], padding[1], padding[2], padding[1]]
    }
    if (padding.length > 3) {
      return [padding[0], padding[1], padding[2], padding[3]]
    }
    return [defaultPadding, defaultPadding]
  }
  return [padding, padding, padding, padding]
}


export function smoothScroll(
  elem: Element | null,
  // @ts-ignore
  options: any = {}
) {
  return new Promise((resolve) => {
    if (!(elem instanceof Element)) {
      throw new TypeError('Argument 1 must be an Element')
    }
    let same = 0
    let lastPos: undefined | null | number = null
    const scrollOptions = Object.assign({ behavior: 'smooth' }, options)

    elem.scrollIntoView(scrollOptions)
    requestAnimationFrame(check)

    function check() {
      const newPos = elem?.getBoundingClientRect().top
      if (newPos === lastPos) {
        if (same++ > 2) {
          return resolve(null)
        }
      } else {
        same = 0
        lastPos = newPos
      }
      requestAnimationFrame(check)
    }
  })
}

import { useEffect, useCallback, useState } from 'react'

export function getRect<T extends Element>(
  element?: T | undefined | null
): RectResult {
  let rect: RectResult = initialState
  if (element) {
    const domRect: DOMRect = element.getBoundingClientRect()
    rect = domRect
  }
  return rect
}

export function useRect<T extends Element>(
  ref: React.RefObject<T> | undefined,
  refresher?: any
): RectResult {
  const [dimensions, setDimensions] = useState(initialState)
  const handleResize = useCallback(() => {
    if (!ref?.current) return
    setDimensions(getRect(ref?.current))
  }, [ref?.current])

  useEffect(() => {
    handleResize();
    // const observer = new ResizeObserver(() => { handleResize() })
    // ref?.current && observer.observe(ref?.current)
    window.addEventListener('resize', handleResize)
    return () => {
      // ref?.current && observer.unobserve(ref?.current);
      window.removeEventListener('resize', handleResize)
    }
  }, [ref?.current, refresher])

  return dimensions
}

export function useElemRect(
  elem: Element | undefined,
  refresher?: any
): RectResult {
  const [dimensions, setDimensions] = useState(initialState)
  const handleResize = useCallback(() => {
    if (!elem) return
    setDimensions(getRect(elem))
  }, [elem])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [elem, refresher])

  return dimensions
}

const initialState = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
}

export type RectResult = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}
