import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Select,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  MenuItem,
  Breadcrumbs,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Pagination from "@material-ui/lab/Pagination";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import TagsUsersController, { Props } from "./TagsUsersController.web";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import { Link as RouterLink } from "react-router-dom";
// Customizable Area End

export class TagsUsers extends TagsUsersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { userList, groupList, userCount, page, rowsPerPage, adminDetails } = this.state;
    const { classes } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box className={classes.mainBox}>
          <Box className={classes.cardHeader}>
            <Breadcrumbs
              separator={<ArrowIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <RouterLink
                to="/AccountCreation/user"
                data-test-id="group-breadcrumb"
                className={classes.groupLink}
                id="group-tour"
              >
                <Typography style={{ fontFamily: 'aeonik-regular', fontWeight: 900 }} color="inherit">Tags</Typography>
              </RouterLink>
              <Select
                defaultValue="user1"
                variant="outlined"
                displayEmpty
                style={{
                  color: "#1C386D",
                  fontFamily: "aeonik-regular",
                  fontWeight: 900,
                  minWidth: 110,
                  borderRadius: "8px",
                  border: "1px solid #1C386D",
                }}
                data-test-id="select-filter"
                value={this.state.selectedFilter}
                onChange={(ev) => { this.handleFilterChange(ev) }}
              >
                <MenuItem value="show_users">Users</MenuItem>
                <MenuItem value="show_groups" style={{ backgroundColor: "#F5F5F5" }}>
                  Groups
                </MenuItem>
              </Select>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12}>
              {this.state.selectedFilter === "show_users" ? < TableContainer >
                <Table className={classes.table} aria-label="customized table">
                  <TableHead className={classes.tableheader}>
                    <TableRow>
                      <TableCell className={classes.tabelCellHead}>
                        First Name
                      </TableCell>
                      <TableCell className={classes.tabelCellHead}>
                        Last Name
                      </TableCell>
                      <TableCell className={classes.tabelCellHead}>
                        Email
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row?.first_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.tag_ids?.includes(
                            Number(this.props.navigation.getParam("groupId"))
                          ) ? (
                            <Button
                              variant="outlined"
                              className={classes.btn}
                              data-test-id={`Remove-button1-${index}`}
                              onClick={() =>
                                this.removeUserForTag(row?.user_id)
                              }
                            >
                              Added
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              data-test-id={`Select-button-${row?.user_id}`}
                              className={classes.EditButton}
                              onClick={() =>
                                this.selectUserForTag(row?.user_id)
                              }
                            >
                              Select
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>
              </TableContainer> :
                <TableContainer>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead className={classes.tableheader}>
                      <TableRow>
                        <TableCell className={classes.tabelCellHead}>
                          Group name
                        </TableCell>
                        <TableCell className={classes.tabelCellHead}>
                          Number of Users
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userList.map((row: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row?.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.user_count}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.tag_ids?.includes(
                              Number(this.props.navigation.getParam("groupId"))
                            ) ? (
                              <Button
                                variant="outlined"
                                className={classes.btn}
                                data-test-id={`Remove-button2-${row?.id}`}
                                onClick={() =>
                                  this.removeGroupFromTag(row?.id)
                                }
                              >
                                Added
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                data-test-id={`Select-button-${row?.id}`}
                                className={classes.EditButton}
                                onClick={() =>
                                  this.selectGroupForTag(row?.id)
                                }
                              >
                                Select
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  border: "1px solid #BBBBBB",
                }}
              >
                <span className={classes.pagination}>
                  {rowsPerPage * (page - 1) + 1} to{" "}
                  {rowsPerPage * page > userCount
                    ? userCount
                    : rowsPerPage * page}{" "}
                  of {userCount} Items
                </span>
                <Pagination
                  className={classes.root}
                  count={Math.ceil(userCount / rowsPerPage)}
                  page={page}
                  onChange={this.handleUserPagination}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = {
  root: {
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: "#BFCAE4",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#BFCAE4",
    },
    "& .MuiPaginationItem-outlined": {
      border: "2px solid #F1F1F1",
    },
    "& .MuiPaginationItem-rounded": {
      borderRadius: "8px solid #F1F1F1",
    },
  },
  tabelCellHead: {
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  pagination: {
    marginTop: "3px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
  },
  EditButton: {
    borderRadius: "5px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#304a7c",
    fontWeight: 600,
    width: "65%",
  },
  cardHeader: {
    borderBottom: "1px solid #E7E7E7",
    padding: "0 40px",
    minHeight: "72px",
    display: "flex",
    alignItems: "center",
  },
  mainBox: {
    flex: "1 1 0px",
    border: "1px solid #ddd",
    margin: "50px",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
    borderRadius: "20px",
    background: "#FFFFFF",
    height: "auto",
    "@media (max-width: 500px)": {
      margin: "20px",
    },
  },
  tabsurvey: {
    "&.MuiTab-root": {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: " 23px",
      color: " #202020",
      opacity: " 0.4",
      textTransform: "capitalize" as const,
      minHeight: "100px",
    },
    "&.MuiTab-root.Mui-selected": {
      color: " #202020",
      opacity: 1,
    },
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    marginBottom: "10px",
  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  edit: {
    padding: "18px 55px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    fontSize: "16px",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-regular',
    fontSize: "20px",
    fontWeight: 700,
    textTransform: "none" as const
  },
  confrim: {
    textAlign: "center" as const,
    marginTop: "20px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
  },
  modalbtn: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "10px",
    // marginTop: "15px",
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px",
  },
  createUser: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  link: {
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  uploadUsers: {
    width: "auto",
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  table: {
    border: "1px solid #B2B2B2",
  },
  tableheader: {
    backgroundColor: "#ECF2FF",
  },
  newSurvey: {
    position: "relative" as const,
    height: "280px",
    cursor: "pointer",
  },
  rectangle: {
    height: "100%",
    width: "100%",
  },
  dialogBody: {
    width: "540px",
    height: "370px",
    borderRadius: "16px",
    background: "#FFF",
  },
  headContainer: {
    borderBottom: "1px solid #E7E7E7",
    padding: "32px 0px 24px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogHeader: {
    color: " var(--text, #202020)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  dialogContent: {
    padding: "16px 50px 40px 50px",
  },
  formTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginBottom: "12px",
    color: "rgba(51, 51, 51, 1)",
  },
  formInput: {
    padding: "16px",
    borderRadius: "10px",
    border: "1px solid #E0E0E0",
    background: "#FFF",
    width: "100%",
    marginBottom: "40px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  rectangleBody: {
    // position: 'absolute' as const,
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    borderRadius: "50%",
    width: "58px",
    height: "58px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "24px",
  },
  plusIcon: {
    width: "43.5px",
    height: "43.5px",
  },
  surveyText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    color: "rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)",
  },
  text: {
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "36px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  btn: {
    borderRadius: "5px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "rgba(255, 255, 255, 1)",
    fontWeight: 600,
    width: "65%",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
  },
  btn2: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    color: "rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)",
    background: "none",
    marginTop: "20px",
  },
  card: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    minHeight: "240px",
    padding: "24px 16px",
    borderRadius: "28px" as const,
    border: "0.5px solid #DEDEDE",
    background: "#FCFCFC",
  },
  container: {
    display: "flex",
    flexDirection: "column" as const,
    color: "var(--text, #202020)",
  },
  img: {
    width: "50px",
    height: "50px",
    marginBottom: "24px",
  },
  subHead: {
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "8px",
    lineHeight: "normal",
    fontStyle: "normal",
  },
  cardBox: {
    display: "flex",
    justifyContent: "center",
    border: "1px dashed #A7A7A7",
    height: "280px",
    borderRadius: "28px" as const,
    background: "#FCFCFC",
  },
  Respondents: {
    // width: "150px",
    height: "22px",
    borderRedius: "5px",
    gap: "10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
    padding: "5px",
    color: "#304a7c",
  },
  ErrorMessage: {
    fontSize: "15px",
    fontWeight: 400,
    marginBottom: "10px",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    color: "#ff0404",
  },
  groupLink: {
    color: "#9FA0A1",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "normal",
    textDecoration: "none",
    cursor: "pointer",
    display: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default withStyles(styles)(TagsUsers);
// Customizable Area End
