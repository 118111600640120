import React from "react";
// Customizable Area Start
// Customizable Area End

import SchedulingController, {
  GroupType,
  Props,
  User
  // Customizable Area Start
  // Customizable Area End
} from "./SchedulingController";
import { Box, Button, Checkbox, Container, Dialog, FormControl, Grid, ListItemText, MenuItem, Select, styled, TextField, Typography, MenuProps, PopoverOrigin } from "@material-ui/core";
import Calender from "../../../components/src/Calender/Calender.web";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleStyle(user: { is_in_group: boolean }): React.CSSProperties {
    return {
      color: user.is_in_group ? 'grey' : '#3c5584',
      fontSize: '16px',

    };
  }

  handleChecked(user: { id: number; is_in_group: boolean; }) {
    return this.state.selectedUsers?.indexOf(user.id) > -1 || user.is_in_group
  }

  quickScheduleStyle(): React.CSSProperties {
    return {
      color: this.state.quickSchedule ? "white" : "#1C386D",
      backgroundColor: this.state.quickSchedule ? "#1C386D" : "white", textTransform: 'none'
    }
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const timeSlots = this.generateTimeSlots();

    const baseMenuProps: Partial<MenuProps> = {
      anchorOrigin: {
        vertical: 'bottom' as PopoverOrigin['vertical'],
        horizontal: 'left' as PopoverOrigin['horizontal'],
      },
      transformOrigin: {
        vertical: 'top' as PopoverOrigin['vertical'],
        horizontal: 'left' as PopoverOrigin['horizontal'],
      },
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          marginTop: '10px',
          borderRadius: '22px',
          border: '1px solid #E6E6E6',
        },
      },
    };

    const userMenuProps: Partial<MenuProps> = {
      ...baseMenuProps,
      PaperProps: {
        ...baseMenuProps.PaperProps,
        style: {
          ...baseMenuProps.PaperProps?.style,
          maxHeight: '40%',
        },
      },
    };

    const groupMenuProps: Partial<MenuProps> = {
      ...baseMenuProps,
      PaperProps: {
        ...baseMenuProps.PaperProps,
        style: {
          ...baseMenuProps.PaperProps?.style,
          maxHeight: '50%',
        },
      },
    };
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <MainContainer>
        <Box sx={{ width: '100%' }} className="tabs" >
          <Box className="cardHeader" >
            <Typography className="header-text">
              Calendar
            </Typography>
          </Box>
        </Box>
        <Calender getCalendarEventData={this.getCalendarEventData} handleOpenMoadal={this.handleOpenMoadal} events={this.state.calendarEvents} />
        <Modaldialog data-test-id="modal" open={this.state.isOpen} onClose={() => this.setState({ isOpen: false })}>
          <div className="modalBody">
            <Container maxWidth="sm">
              <Typography variant="h5" align="center" gutterBottom>
                Edit Schedule Survey
              </Typography>
              <FormControl fullWidth margin="normal" variant="outlined" error={!!this.state.errors.group}>
                <Typography variant="body1">Select group</Typography>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  name="selectedGroup"
                  data-test-id="select-group"
                  value={this.state.selectedGroup}
                  onChange={this.handleCheck}
                  renderValue={this.groupRender}
                  MenuProps={groupMenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                >
                  <MenuItem value="" disabled>
                    <em style={{ fontFamily: 'aeonik-regular', }}>Group Name</em>
                  </MenuItem>
                  {this.state.groupList.map((group: any, i: number) => (
                    <StyledMenuItem
                      key={group.id}
                      value={group.id}
                    >
                      <ListItemText primary={group.name} style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontFamily: 'aeonik-regular'
                      }} />
                      <Checkbox
                        style={{ color: "#3c5584" }}
                        checked={this.state.selectedGroup?.indexOf(group.id) > -1}
                      />
                    </StyledMenuItem>

                  ))}
                </Select>
                <Typography color="error">{this.state.errors.group}</Typography>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined" error={!!this.state.errors.survey}>
                <Typography variant="body1">Select user</Typography>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  name="selectedUser"
                  data-test-id="select-user"
                  value={this.state.selectedUsers}
                  onChange={this.handleCheckUser}
                  renderValue={this.userRender}
                  MenuProps={userMenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                >
                  <MenuItem value="" disabled>
                    <em style={{ fontFamily: 'aeonik-regular', }}>User List</em>
                  </MenuItem>
                  {this.state.userList.map((user: any, i: number) => (
                    <StyledMenuItem key={user.id} value={user.id} disabled={user.is_in_group}>
                      <ListItemText primary={user.first_name + " " + user.last_name} style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontFamily: 'aeonik-regular'
                      }} 
                      />
                      <Checkbox
                        style={this.handleStyle(user)}
                        checked={this.handleChecked(user)}
                        onMouseDown={()=>this.handleMouseDown(user)}
                        disabled={user.is_in_group}
                      />
                    </StyledMenuItem>
                  ))}
                </Select>
                <Typography color="error">{this.state.errors.survey}</Typography>
              </FormControl>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                  <Typography variant="body1">Start Date</Typography>
                  <TextField
                    disabled={this.state.quickSchedule}
                    data-test-id="start-date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={this.state.startDate}
                    onChange={(e) => this.handleInputChange("startDate", e.target.value)}
                    error={!!this.state.errors.startDate}
                    helperText={this.state.errors.startDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Time</Typography>
                  <FormControl fullWidth variant="outlined" error={!!this.state.errors.startTime}>
                    <Select data-test-id="start-time" disabled={this.state.quickSchedule} value={this.state.startTime} onChange={(e) => this.handleInputChange("startTime", e.target.value)}>
                      <MenuItem value="">Select Time</MenuItem>
                      {timeSlots.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography color="error">{this.state.errors.startTime}</Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button data-test-id="quick-schedule-btn"
                    className="one-min"
                    style={this.quickScheduleStyle()}
                    onClick={this.quickScheduleHandler}>Start within 1 minute</Button>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">End Date</Typography>
                  <TextField
                    data-test-id="end-date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={this.state.endDate}
                    onChange={(e) => this.handleInputChange("endDate", e.target.value)}
                    error={!!this.state.errors.endDate}
                    helperText={this.state.errors.endDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Time</Typography>
                  <FormControl fullWidth variant="outlined" error={!!this.state.errors.endTime}>
                    <Select data-test-id="end-time" value={this.state.endTime} onChange={(e) => this.handleInputChange("endTime", e.target.value)}>
                      <MenuItem value="">Select Time</MenuItem>
                      {timeSlots.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography color="error">{this.state.errors.endTime}</Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
            <div className="buttons">
              <Button data-test-id="submit-btn" className="primaryButton" type="button" onClick={this.handleSubmit}>
                Schedule
              </Button>
              <Button data-test-id="cancel-btn" onClick={() => this.setState({ isOpen: false })} className="secondaryButton">
                Cancel
              </Button>
            </div>
          </div>
        </Modaldialog>
      </MainContainer >
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const StyledMenuItem = styled(MenuItem)({
  '&:not(:last-child)': {
    borderBottom: '1px solid #ccc',
  },
});

const baseMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom' as PopoverOrigin['vertical'],
    horizontal: 'left' as PopoverOrigin['horizontal'],
  },
  transformOrigin: {
    vertical: 'top' as PopoverOrigin['vertical'],
    horizontal: 'left' as PopoverOrigin['horizontal'],
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      marginTop: '10px',
      borderRadius: '22px',
      border: '1px solid #E6E6E6',
    },
  },
};

const MainContainer = styled(Box)({
  margin: "50px",
  boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
  borderRadius: "20px",
  background: "#FFFFFF",
  height: "auto",
  margintop: "0px",
  paddingTop: "0px",
  flex: 1,
  "@media (max-width: 500px)": {
    margin: "20px",
  },
  width: "100%",
  color: "#616161",
  fontFamily: "'Raleway', sans-serif",
  "& .cardHeader": {
    paddingLeft: '20px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 402px)": {
      padding: '0 10px',
    }
  },
  "& .header-text": {
    color: "#202020",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    fontFamily: "aeonik-bold",
    marginRight: "24px",
    textDecoration: "none",
    cursor: "pointer",
    textTransform: "none"
  },
  "& .button-box": {
    width: "100%",
    color: "#616161",
    padding: "20px",
  },
  "& .primaryButton": {
    padding: '18px 32px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none' as const,
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    borderRadius: '10px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    flex: '1 1 0px',
    marginTop: '30px',
    fontFamily: 'aeonik-regular',
  },
});

const Modaldialog = styled(Dialog)({
  borderRadius: '16px',
  color: "#000000",
  "& .modalBody": {
    borderRadius: '16px',
    background: '#FFF',
    padding: '20px',
    color: "#000000",
    width: "690px"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "unset"
  },
  "& .buttons": {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center'
  },
  "& .one-min": {
    padding: '18px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    width: "100%",
    border: "1px solid #1C386D"
  },
  "& .primaryButton": {
    padding: '18px 32px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none' as const,
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    borderRadius: '10px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    flex: '1 1 0px',
    marginTop: '30px',
    fontFamily: 'aeonik-regular',
  },
  "& .secondaryButton": {
    alignItems: 'center',
    textTransform: 'none' as const,
    padding: '18px 32px',
    justifyContent: 'center',
    border: 'none',
    fontSize: '16px',
    fontWeight: 700,
    background: 'none',
    color: '#333',
    fontStyle: 'normal',
    lineHeight: 'normal',
    flex: '1 1 0px',
    fontFamily: 'aeonik-regular',
  },
})
// Customizable Area End
