import React, { FunctionComponent, useState } from 'react';

import Header from './HeaderQuestion.web';
import ShortAnswerQuestion from './ShortAnswerQuestion.web';
import ParagraphQuestion from './ParagraphQuestion.web';
import MCQ from './MultipleChoiceQuestion.web';
import CheckboxQuestion from './CheckboxQuestion.web';
import SliderQuestion from './SliderQuestion.web';
import DateQuestion from './DateQuestion.web';
import RankingQuestion from './RankingQuestion.web';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Typography, styled } from '@material-ui/core';
import { deleteIcon } from '../../blocks/AccountCreation/src/assets';
interface QuestionTemplates {
    [key: string]: (data: any) => JSX.Element;
}

interface item {
    id: number | null,
    question_type?: string,
    question_title?: any
    options?: any
    answer?: any
    required?: boolean
    min_number?: number
    max_number?: number
    ind?: number
}

interface Props {
    dropBoxes: any[];
    onDragEnd: (result: any) => void;
    handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (boxIndex: number, itemIndex: number) => void;
    handleHeaderChange: (boxIndex: number, newHeader: string) => void;
    handleDeleteBox: (boxIndex: number) => void;
    handleItemChange: (boxIndex: number, itemIndex: number, newData: any) => void;
    handleRemoveItem: (boxIndex: number, itemIndex: number) => void;
    handleInputChange: (data: any) => any;
    handleDeleteClick: (ind: any) => any;
    isLiveorPastSurvey: boolean
}

const RenderSurveyQuestion: FunctionComponent<Props> = ({
    dropBoxes,
    onDragEnd,
    handleDragOver,
    handleDrop,
    handleHeaderChange,
    handleDeleteBox,
    handleItemChange,
    handleRemoveItem,
    handleInputChange,
    handleDeleteClick,
    isLiveorPastSurvey
}) => {

    const [mouseIn , setMouseIn] = useState<number>(0);

    const handleRankDrag = ()=>{};
    
    return (
        <div id='question-form'>

            <DragDropContext data-test-id="drag-drop-context-id" onDragEnd={mouseIn === 1 ? handleRankDrag: onDragEnd}>
                {dropBoxes.map((box, boxIndex) => (
                    <div
                        key={box.id}
                        style={{
                            borderLeft: '2px solid #939db3',
                            marginBottom: '20px',
                            position: 'relative',
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                        }}
                        data-test-id="drop-div-id"
                        onDragOver={handleDragOver}
                        onDrop={() => handleDrop(boxIndex, box.items.length)}
                    >
                        {!box.individual ? (
                            <>
                                <Section>Section</Section>
                                <div style={{ marginTop: '-30px' }}>
                                    <Header
                                        isLiveorPastSurvey={isLiveorPastSurvey}
                                        data={{ question_title: box.header }}
                                        onChange={(data) => handleHeaderChange(boxIndex, data.question_title)}
                                        onDeleteClick={() => handleDeleteBox(boxIndex)}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <Section>Individual Questions</Section>


                                <div style={{ marginTop: box.items.length > 0 ? '-30px' : '0px' }}>

                                    <div
                                        style={{
                                            height: box.items.length > 0 ? '0px' : '100px',
                                            border: box.items.length > 0 ? 'none' : '1px solid #e2e2e2',
                                            borderRadius: '0px 8px 8px 8px',
                                        }}
                                    >

                                        {box.items.length < 1 && <h4 style={{ fontWeight: 'bolder', marginLeft: '25px' }}>Add questions here</h4>}
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        right: box.items.length > 0 ? '0' : '25px',
                                        top: box.items.length > 0 ? '65px' : 'auto',
                                        bottom: box.items.length > 0 ? 'auto' : '19px',
                                        margin: '0px',
                                    }}>
                                        {box.individual && (<button disabled={isLiveorPastSurvey} onClick={() => handleDeleteBox(boxIndex)} style={styles.btn}><img src={deleteIcon} alt="delete" /></button>)}
                                    </div>
                                </div>

                            </>
                        )}
                        <Droppable data-test-id="droppable-id" droppableId={`${box.id}`} key={box.id}
                            isDropDisabled = { mouseIn === 1 }
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{ backgroundColor: snapshot.isDraggingOver ? '#f5f6f8' : 'transparent' }}
                                >
                                    {box.items.map((itemObj: any, itemIndex: number) => (
                                        <Draggable  isDragDisabled={isLiveorPastSurvey || mouseIn === 1 } data-test-id="draggable" key={`${box.id}-${itemIndex}`} draggableId={`${box.id}-${itemIndex}`} index={itemIndex}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                    }}
                                                >
                                                    {itemObj.itemType === 'short-text' && (
                                                        <ShortAnswerQuestion
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data-test-id="short-text-question"
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                        />
                                                    )}
                                                    {itemObj.itemType === 'radio' && (
                                                        <MCQ
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                        />
                                                    )}
                                                    {itemObj.itemType === 'long-text' && (
                                                        <ParagraphQuestion
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                        />
                                                    )}
                                                    {itemObj.itemType === 'date' && (
                                                        <DateQuestion
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                        />
                                                    )}
                                                    {itemObj.itemType === 'checkbox' && (
                                                        <CheckboxQuestion
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                        />
                                                    )}
                                                    {itemObj.itemType === 'slider' && (
                                                        <SliderQuestion
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                        />
                                                    )}
                                                    {itemObj.itemType === 'ranking' && (
                                                        <RankingQuestion
                                                            isLiveorPastSurvey={isLiveorPastSurvey}
                                                            data={itemObj.data}
                                                            onChange={(newData) => handleItemChange(boxIndex, itemIndex, newData)}
                                                            onDeleteClick={() => handleRemoveItem(boxIndex, itemIndex)}
                                                            setMouseIn = {setMouseIn}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                    </div>
                ))}

            </DragDropContext>
        </div>
    )
};

const styles = {
    listItem: {
        cursor: 'move',
    },
    btn: {
        border: 'none',
        background: 'none',
        padding: '0px',
        margin: '-12px',
        cursor: 'pointer',
        marginRight: '18px',
    },
};
const Section = styled(Typography)({
    width: 'fit-content',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: 'white',
    padding: '10px 20px',
    fontFamily: 'aeonik-regular',
    fontWeight: 'bold'

})

export default RenderSurveyQuestion;