import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Mask } from '../Mask'
import { Popover } from '../Popover'
import { useSizes } from './hooks'
import { TourProps, Padding } from './type.d'
import Keyboard from './Keyboard'
import PopoverContent from './components/PopoverContent'
import { disableScroll, enableScroll } from '../utils/util';

const 
Tour: React.FC<TourProps> = ({
  currentStep,
  setCurrentStep,
  setIsOpen,
  steps = [],
  setSteps,
  styles: globalStyles = {},
  scrollSmooth,
  afterOpen,
  beforeClose,
  padding = 10,
  position,
  onClickMask,
  closeOnMaskClick,
  onClickHighlighted,
  keyboardHandler,
  className = 'cedreactour__popover',
  maskClassName = 'cedreactour__mask',
  highlightedMaskClassName,
  clipId,
  maskId,
  disableInteraction,
  disableKeyboardNavigation,
  inViewThreshold,
  hideActions,
  setHideActions,
  rtl,
  accessibilityOptions = {
    closeButtonAriaLabel: 'Close Tour',
    showNavigationScreenReaders: true,
  },
  ContentComponent,
  Wrapper,
  onTransition = () => {
    return 'center'
  },
  OnNext,
  OnBack,
  tourLoading,
  setTourLoading,
  isOpen,
  updateInStep,
  components,
  nextButton,
  onClickClose,
  prevButton,
  showNavigation
}) => {
  const step = steps[currentStep]
  const styles = { ...globalStyles, ...step?.styles }

  const {
    sizes,
    transition,
    target,
  } = useSizes(step, {
    block: 'center',
    behavior: scrollSmooth ? 'smooth' : 'auto',
    inViewThreshold,
  }, currentStep, !!tourLoading?.valueOf())

  useEffect(() => {
    if (afterOpen && typeof afterOpen === 'function') {
      afterOpen(target)
    }
    return () => {
      if (beforeClose && typeof beforeClose === 'function') {
        beforeClose(target)
      }
    }
  }, [])

  const { maskPadding, popoverPadding, wrapperPadding } = getPadding(
    step?.padding ?? padding
  )

  const clickProps = {
    setCurrentStep,
    setIsOpen,
    setTourLoading,
    currentStep,
    setSteps,
    steps,
  }

  function maskClickHandler() {
    if (onClickMask && typeof onClickMask === 'function') {
      onClickMask(clickProps)
    }
    if (closeOnMaskClick) {
      setIsOpen(false)
      step?.OnClose?.()
    }
    setCurrentStep(Math.min(currentStep + 1, steps.length - 1))
    steps?.[currentStep]?.OnNext?.()
  }

  const doDisableInteraction =
    typeof step?.stepInteraction === 'boolean'
      ? !step?.stepInteraction
      : disableInteraction

  useEffect(() => {
    if (step?.disableScroll) disableScroll();
    else enableScroll();

    if (step?.hideActions !== undefined) {
      setHideActions(step?.hideActions)
    } else {
      setHideActions({})
    }
  }, [step])

  const popoverPosition = transition
    ? onTransition
    : step?.position
      ? step?.position
      : position

  const TourWrapper = Wrapper ? Wrapper : React.Fragment

  return step ? ReactDOM.createPortal(
    <TourWrapper>
      <Keyboard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        setIsOpen={setIsOpen}
        setLoading={setTourLoading}
        stepsLength={steps.length}
        disableKeyboardNavigation={disableKeyboardNavigation}
        hideActions={hideActions}
        rtl={rtl}
        clickProps={clickProps}
        keyboardHandler={keyboardHandler}
        onNext={OnNext}
        onBack={OnBack}
      />

      <Mask
        sizes={transition ? initialState : sizes}
        onClick={maskClickHandler}
        styles={{
          highlightedArea: (base: any) => ({
            ...base,
            display: doDisableInteraction ? 'block' : 'none',
          }),
          ...styles,
        }}
        padding={transition ? 0 : maskPadding}
        highlightedAreaClassName={highlightedMaskClassName}
        className={maskClassName}
        onClickHighlighted={onClickHighlighted}
        wrapperPadding={wrapperPadding}
        clipId={clipId}
        maskId={maskId}
        loading={!!tourLoading}
      />

      {!tourLoading && <Popover
        sizes={sizes}
        // styles={styles}
        position={popoverPosition}
        padding={popoverPadding}
        aria-labelledby={accessibilityOptions?.ariaLabelledBy}
        className={className}
        refresher={currentStep}
      >
        {ContentComponent ? (
          <ContentComponent
            styles={styles}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setIsOpen={setIsOpen}
            steps={steps}
            accessibilityOptions={accessibilityOptions}
            hideActions={hideActions}
            transition={transition}
            rtl={rtl}
            loading={tourLoading}
            setTourLoading={setTourLoading}
          />
        ) : (
          <PopoverContent
            styles={styles}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setIsOpen={setIsOpen}
            steps={steps}
            setSteps={setSteps}
            accessibilityOptions={accessibilityOptions}
            hideActions={hideActions}
            transition={transition}
            rtl={rtl}
            onNext={OnNext}
            onBack={OnBack}
            loading={tourLoading}
            setTourLoading={setTourLoading}
          />
        )}
      </Popover>}
    </TourWrapper>
    , document.body) : null
}

export default Tour

export interface CustomCSS extends React.CSSProperties {
  rx: number
}

function getPadding(padding?: Padding) {
  if (typeof padding === 'object' && padding !== null) {
    return {
      maskPadding: padding.mask,
      popoverPadding: padding.popover,
      wrapperPadding: padding.wrapper,
    }
  }
  return {
    maskPadding: padding,
    popoverPadding: padding,
    wrapperPadding: 0,
  }
}

const initialState = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
}
