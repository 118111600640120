// Customizable Area Start
import React from "react";

import {
    Box,
    Typography,
    Grid
} from "@material-ui/core";

import Card from "../../../components/src/Card.web";
import { rectangleIcon, plusIcon } from "./assets";
import SurveyTemplateCard from "../../../components/src/SurveyTemplateCard";
import SurveysTemplateDialog from "../../../components/src/SurveysTemplateDialog.web";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";

import SurveysTemplateController, {
    Props,
} from "./SurveysTemplateController.web";
import { withTour } from "../../../components/src/Tour";

 class SurveysTemplate extends SurveysTemplateController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const errors = this.state.errors;
        return (
            <div style={{padding:'24px'}}>
                <SurveysTemplateDialog
                    visible={this.state.visible}
                    surveyName={this.state.surveyName}
                    surveyDescription={this.state.surveyDescription}
                    surveyId={this.state.surveyId}
                    surveyNameChange={this.surveyNameChange}
                    surveyDescriptionChange={this.surveyDescriptionChange}
                    createSurveyClick={this.createSurveyClick}
                    editSurveyClick={this.onEditSurvey}
                    closeDialog={this.closeDialog}
                    errors={errors}
                    loading={this.state.loading}
                />
                <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <Box onClick={this.openCreateSurveyDialog} style={webStyle.newSurvey}>
                        <div id="open-survey">
                        <img style={webStyle.rectangle} src={rectangleIcon} alt="rectangleIcon" />
                        <div style={webStyle.rectangleBody} >
                            <div style={webStyle.circle}>
                                <img style={webStyle.plusIcon} src={plusIcon} alt="plusIcon" />
                            </div>
                            <Typography style={webStyle.surveyText as any}>Create new survey</Typography>
                        </div>
                        </div>
                    </Box>
                    </Grid>
                    {this.renderCard().map((el, ind) => {
                        return (
                            <SurveyTemplateCard
                                key={ind}
                                item={el} {...this.props}
                                onDeleteClick={this.onDeleteClick}
                                onEditClick={this.onEditClick}
                            />
                        )
                    })}
                </Grid>
                <DeleteConfirmationDialog
                    title='Delete survey'
                    description="Are you sure you want to delete this survey?"
                    visible={this.state.visibleDeleteConfirmationDialog}
                    handleClose={this.handleCloseConfirmationDialog}
                    onDeleteClick={this.onDeleteSurvey}
                    loading={this.state.loading}
                    
                />
            </div>
        );
    }
}

const webStyle = {
    dialog: {
        borderRadius: '16px',
    },
    newSurvey: {
        position: 'relative' as const,
        cursor: 'pointer',
        height: "300px",
    },
    rectangle: {
        height: '100%',
        width: '100%'
    },
    dialogBody: {
        width: '540px',
        borderRadius: '16px',
        background: '#FFF'
    },
    headContainer: {
        padding: '32px 0px 24px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogHeader: {
        color: ' var(--text, #202020)',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    dialogContent: {
        padding: '16px 50px 40px 50px'
    },
    formTitle: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginBottom: '12px',
        color: 'rgba(51, 51, 51, 1)'
    },
    formInput: {
        marginBottom: '24px'
    },
    formArea: {
        display: 'flex',
        flexDirection: 'column' as const,
        width: '100%',
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    rectangleBody: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center'
    },
    circle: {
        borderRadius: '50%',
        width: '58px',
        height: '58px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '24px'
    },
    plusIcon: {
        width: '43.5px',
        height: '43.5px'
    },
    surveyText: {
        whiteSpace: 'nowrap',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: 'normal',
        color: '#3C5585',
        fontFamily: 'aeonik-bold'
    },
    text: {
        fontSize: '14px',
        fontWeight: 500,
        marginBottom: '36px',
        lineHeight: 'normal',
        fontStyle: 'normal',
    },
    btn: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        fontSize: '18px',
        fontWeight: 600,
        color: 'rgba(255, 255, 255, 1)',
        lineHeight: 'normal',
        fontStyle: 'normal',
        flex: '1 1 0px',
        textTransform: 'unset' as const,
        minWidth: '190px'
    },
    btn2: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        color: '#3D5685',
        background: 'none',
        marginTop: '20px',
        textTransform: 'unset' as const,
        minWidth: '190px'
    }
};
export default withTour(SurveysTemplate)
// Customizable Area End
