export const setCookie = (name: any, value: any) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 100);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value}; ${expires}; path=/`;
};

export const saveToCookie = (msg: string) => {
    setCookie("productTour", msg);
};
export const saveToCookieDashboard = (msg: string) => {
    setCookie("Dashboard", msg);
};

export const getCookie = (name: string) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        const [key, value] = cookies[i].split("=");
        if (key === name) {
            return value;
        }
    }
    return null;
};