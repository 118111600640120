import React, { FunctionComponent } from 'react'
import Dialog from '../../components/src/Dialog.web';

import {
    Button, styled,
} from "@material-ui/core";

interface Props {
    title?: string;
    description?: string;
    visible: boolean;
    handleClose: () => void;
    onDeleteClick: () => void;
    loading: boolean;
}

const DeleteConfirmationDialog: FunctionComponent<Props> = ({ title = '', description = '', visible, handleClose, onDeleteClick, loading }) => {
    return (
        <Dialog style={webStyle.deletemodal} visible={visible}>
            <Container>
                <div>
                    <p style={webStyle.head}>{title}</p>
                </div>
                <div>
                    <p style={webStyle.text}>
                        {description}
                    </p>
                </div>
                <Actions>
                    <Button onClick={onDeleteClick} style={webStyle.buttonPrimary} disabled={loading}>{title}</Button>
                    <Button onClick={() => handleClose()} style={webStyle.btn}>Close</Button>
                </Actions>
            </Container>
        </Dialog>
    )
}
const Container = styled('div')({
    width: '290px',
    background: '#FFF',
    padding: '40px',
    borderRadius: '16px',
    '@media (min-width:520px) and (max-width:630px)': {
        // width:'100%',
        width: '440px',
        padding:'20px',
    },
    '@media(max-width:519px)': {
        width: '99%',
        padding:'20px 0px',
    },
})
const Actions = styled('div')({
    flexDirection: 'column' as const,
    alignItems: 'center',
    display: 'flex',
    '@media (min-width:520px) and (max-width:630px)': {
        scale:'0.9',
    },
    '@media(max-width:519px)': {
        scale:'0.8',
    },
})
const webStyle = {
    deletemodal: {
        borderRadius: '16px',
    },
    container: {
        width: '540px',
        background: '#FFF',
        padding: '40px',
        borderRadius: '16px',
    },
    head: {
        fontSize: '18px',
        color: 'black',
        fontWeight: 700,
        lineHeight: '20px',
        fontFamily: 'aeonik-regular',
        textAlign: 'center' as const,
    },
    actions: {
        flexDirection: 'column' as const,
        alignItems: 'center',
        display: 'flex',
    },
    buttonPrimary: {
        alignItems: 'center',
        padding: '18px 32px',
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        justifyContent: 'center',
        borderRadius: '10px',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: 'normal',
        color: 'rgba(255, 255, 255, 1)',
        fontWeight: 700,
        marginTop: '30px',
        flex: '1 1 0px',
        fontFamily: 'aeonik-regular',
        textTransform: 'none' as const
    },
    btn: {
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        padding: '18px 32px',
        border: 'none',
        background: 'none',
        fontWeight: 700,
        fontStyle: 'normal',
        lineHeight: 'normal',
        color: '#333',
        flex: '1 1 0px',
        fontFamily: 'aeonik-regular',
        textTransform: 'none' as const
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 600,
        textAlign: 'center' as const,
        color: '#202020',
        fontSize: '14px',
        fontFamily: 'aeonik-regular',
        lineHeight: '20px',
    },
};

export default DeleteConfirmationDialog;
