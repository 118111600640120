import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  TextField,
  ListItemText,
  Checkbox,
  Snackbar,
  Slide,
  PopoverOrigin,
  MenuProps,
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Alert } from '@material-ui/lab';
import SurveysTemplate from "../../SurveysTemplate/src/SurveysTemplate.web";
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { styled, withStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Link as RouterLink } from "react-router-dom";

interface SurveyData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    respondents: number;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    status: string;
    groups: number[];
    user_avatar: string;
    user_first_name: string;
    user_last_name: string;
  };
}

interface TabPanelData {
  value: number;
  index: number;
  children: React.ReactNode;
}
// Customizable Area End

import AdminSurveysController, {
  Props,
} from "./AdminSurveysController.web";
import { cardIcon2 } from "./assets";
import moment from "moment";
import ProductTour from "../../../components/src/Tour/ProductTour";
import { StepType, withTour } from "../../../components/src/Tour";
import { getCookie } from "../../../components/src/Tour/ManageCookies";

export class AdminSurveys extends AdminSurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleStyle = this.handleStyle.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.liveModalStyle = this.liveModalStyle.bind(this);
    this.quickScheduleStyle = this.quickScheduleStyle.bind(this);

    // Customizable Area End
  }

  // Customizable Area Start
  handleMouseDown(user:any) {
      if (user.is_individual_user_added) {
         this.removeUsers(user.id);
      } else {
        this.addUsers(user.id);
      }
  }
  
  handleStyle(user: any): React.CSSProperties {
    
    return {
      color: user.is_in_group ? 'grey' : '#3c5584',
      fontSize: '16px',
     
    };
  }
 handleChecked(user:any){
   return this.state.selectedUsers?.indexOf(user.id) > -1 || user.is_in_group
 }


 
 liveModalStyle(): React.CSSProperties {
  return {
    ...styles.quickScheduleBtnUnselected,
    color: this.state.quickEnd ? "white" : "#1C386D",
    backgroundColor: this.state.quickEnd ? "#1C386D" : "white",
    textTransform: 'none',
  };
}
quickScheduleStyle(): React.CSSProperties {
  return {...styles.quickScheduleBtnUnselected,
    color: this.state.quickSchedule ? "white" : "#1C386D",
    backgroundColor: this.state.quickSchedule ? "#1C386D" : "white",textTransform:'none'
  }
}


  // Customizable Area End

  render() {

    const part = getCookie("productTour");
    const { tabValue } = this.state;
    const { classes } = this.props;

    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const TabPanelSurvey = (props: TabPanelData) => {
      const { children, value, index } = props;

      return (
        <div
          id={`simple-tabpanel-${index}`}
          hidden={value !== index}
          aria-labelledby={`simple-tab-${index}`}
          role="tabpanel"
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    };

    return (
      // Customizable Area Start
      <Box className={classes.mainBox}>
        <ProductTour navigate={this.props.navigation}/>
        {
          <Box sx={{ width: '100%' }} className={classes.tabs} >
            <Box className={classes.cardHeader} >
              <Tabs value={tabValue} data-test-id="tabChange" onChange={this.handleChangeTab} aria-label="basic tabs example" id="tabChangeSection"
                TabIndicatorProps={{ style: { background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)", height: "10px", borderRadius: "15px 15px 0 0" } }
                }>
                <Tab className={classes.tabsurvey} label="Drafts" {...a11yProps(0)} />
                <Tab className={classes.tabsurvey} label="Live" {...a11yProps(1)} />
                <Tab className={classes.tabsurvey} label="Scheduled" {...a11yProps(2)} />
                <Tab data-test-id="past-tab" onClick={this.handlePastClick} className={classes.tabsurvey} label="Past" {...a11yProps(3)} />
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <SurveysTemplate {...this.props} />
            )}
            {tabValue === 1 && (
              <TabPanelSurvey value={tabValue} index={1}>
                <Grid container spacing={2}>
               {(part !== "Part three completed" && part === "Part two completed") && <Grid id={`live-survey-sample`} key={'live-survey-sample'} item xs={12} md={6} lg={3}>
                      <div className={classes.card}>
                        <div className={classes.container}>
                          <div className={classes.mainContainer}>
                            <img className={classes.img} src={cardIcon2} alt="cardIcon" />
                            <Typography className={classes.Respondents}>{`0 Respondents`}</Typography>
                          </div>
                          <Typography className={classes.cardHeaderName}>{'Sample'}</Typography>
                          <div className={classes.dateAndTime}>
                            <div>
                              <Typography className={classes.date}><span className={classes.tital}>Start date</span></Typography>
                              <Typography className={classes.dateshow1}><span className={classes.dateBox}>{"20 February 2025"}</span></Typography>
                            </div>
                            <div>
                              <Typography className={classes.date}><span className={classes.tital}>End date</span></Typography>
                              <Typography className={classes.dateshow2}><span className={classes.dateBox}>{"20 February 2025"}</span></Typography>
                            </div>
                          </div>
                          <RouterLink to={`/AnalyticsWeb?surveyId=000`}>
                          <Button
                            data-test-id={`Edit-survey-btn`}
                            className={classes.btn}                          
                          >
                            View results
                          </Button>
                          </RouterLink>
                        </div>
                      </div>
                    </Grid>}
                  {(this.state.surveyDataLive).map((surveyDataLive: any, ind: any) => (
                    <Grid id={`live-survey-${ind}`} key={ind} item xs={12} md={6} lg={3}>
                      <div className={classes.card}>
                        <div className={classes.container}>
                          <div className={classes.mainContainer}>
                            <img className={classes.img} src={cardIcon2} alt="cardIcon" />
                            <Typography className={classes.Respondents}>{`${+surveyDataLive?.attributes?.respondents} Respondents`}</Typography>
                          </div>
                          <Typography className={classes.cardHeaderName}>{surveyDataLive?.attributes?.name}</Typography>
                          <div className={classes.dateAndTime}>
                            <div>
                              <Typography className={classes.date}><span className={classes.tital}>Start date</span></Typography>
                              <Typography className={classes.dateshow1}><span className={classes.dateBox}>{this.getCardDate(surveyDataLive?.attributes?.start_date, surveyDataLive?.attributes?.start_time)}</span></Typography>
                            </div>
                            <div>
                              <Typography className={classes.date}><span className={classes.tital}>End date</span></Typography>
                              <Typography className={classes.dateshow2}><span className={classes.dateBox}>{this.getCardDate(surveyDataLive?.attributes?.end_date, surveyDataLive?.attributes?.end_time)}</span></Typography>
                            </div>
                          </div>
                          <RouterLink to={`/AnalyticsWeb?surveyId=${surveyDataLive?.attributes?.id}`}>
                          <Button
                            data-test-id={`Edit-survey-btn`}
                            className={classes.btn}                          
                          >
                            View results
                          </Button>
                          </RouterLink>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </TabPanelSurvey>
            )}
            {tabValue === 2 && (
              <TabPanelSurvey value={tabValue} index={2}>
                <Grid container spacing={2}>
                  {(this.state.surveyDataScheduled).map((surveyDataScheduled: any, ind: any) => (
                    <Grid key={ind} item xs={12} md={6} lg={3}>
                      <Box className={classes.card}>
                        <div className={classes.container}>
                          <img className={classes.img} src={cardIcon2} alt="cardIcon" />
                          <Typography className={classes.cardHeaderName}>{surveyDataScheduled?.attributes?.name}</Typography>
                          <Box className={classes.dateAndTime}>
                            <Box>
                              <Typography className={classes.date}>
                                <span className={classes.tital}>Start Date
                                </span>
                              </Typography>
                              <Typography className={classes.dateshow1}>
                                <span className={classes.dateBox}>{this.getCardDate(surveyDataScheduled?.attributes?.start_date, surveyDataScheduled?.attributes?.start_time)}
                                </span>
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className={classes.date}>
                                <span className={classes.tital}>End date
                                </span>
                              </Typography>
                              <Typography className={classes.dateshow2}>
                                <span className={classes.dateBox}>{this.getCardDate(surveyDataScheduled?.attributes?.end_date, surveyDataScheduled?.attributes?.end_time)}
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            data-test-id="Edit-btn"
                            className={classes.btn}
                            onClick={() => {this.setState({individualUserGroup : surveyDataScheduled?.attributes.individual_user_group});
                              this.handleClickOpen(surveyDataScheduled?.attributes, surveyDataScheduled?.relationships)
                              this.setState({isLiveSurveyModal:false});
                              
                            }
                            
                          }
                          >
                            Edit
                          </Button>
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </TabPanelSurvey>
            )}
            {tabValue === 3 && (
              <TabPanelSurvey data-test-id={'tabsdata'} value={tabValue} index={3}>
                <Grid container spacing={2}>
                  {(this.state.surveyData).map((surveyData: SurveyData, arrIndex: number) => (
                    <Grid item xs={12} md={6} lg={3} key={arrIndex}>
                      <Box className={classes.card}>
                        <div className={classes.container}>
                          <Box className={classes.mainContainer}>
                            <img className={classes.img} src={cardIcon2} alt="cardIcon" />
                            <Typography data-test-id={`user-label${surveyData?.id}`} className={classes.Respondents}>{`${+surveyData?.attributes?.respondents} Respondents`}</Typography>
                          </Box>
                          <Typography className={classes.cardHeaderName}>{surveyData?.attributes?.name}</Typography>
                          <Box className={classes.dateAndTime}>
                            <Box>
                              <Typography className={classes.date}>
                                <span className={classes.tital}>Start Date</span>
                              </Typography>
                              <Typography className={classes.dateshow1}>
                                <span className={classes.dateBox}>{this.getCardDate(surveyData?.attributes?.start_date, surveyData?.attributes?.start_time)}</span>
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className={classes.date}>
                                <span className={classes.tital}>End date</span>
                              </Typography>
                              <Typography className={classes.dateshow2}>
                                <span className={classes.dateBox}>{this.getCardDate(surveyData?.attributes?.end_date, surveyData?.attributes?.end_time)}</span>
                              </Typography>
                            </Box>
                          </Box>
                          <RouterLink to={`/AnalyticsWeb?surveyId=${surveyData?.attributes?.id}`}>
                            <Button
                              disabled={true}
                              data-test-id={`Survey-button${surveyData?.id}`}
                              className={classes.btn}
                            >
                              {this.renderBtntext()}
                            </Button>
                          </RouterLink>
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </TabPanelSurvey>
            )}
          </Box>
        }
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth maxWidth="sm"
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
            },
          }}>
          <Box id="liveedit" className={classes.surveyTital} >
            {
              "Edit surveys"
            }
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 20px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Select group</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  name="selectedGroup"
                  data-test-id="select-group"
                  value={this.state.selectedGroup}
                  onChange={this.handleCheck}
                  renderValue={this.groupRender}
                  MenuProps={groupMenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                >
                  <MenuItem value="" disabled>
                    <em style={{ fontFamily: 'aeonik-regular', }}>Group Name</em>
                  </MenuItem>
                  {this.state.groupList.map((group: any , i:number) => (
                  <StyledMenuItem
                  key={group.id}
                  value={group.id}
                >
                      <ListItemText primary={group.name} style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: 'aeonik-regular'
                           }}/>
                      <Checkbox 
                      style={{ color: "#3c5584" }} 
                      checked={this.state.selectedGroup?.indexOf(group.id) > -1} 
                      />
                    </StyledMenuItem>
                
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Select user</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  name="selectedUser"
                  data-test-id="select-user"
                  value={this.state.selectedUsers}
                  onChange={this.handleCheckUser}
                  renderValue={this.userRender}
                  MenuProps={userMenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                >
                  <MenuItem value="" disabled>
                    <em style={{ fontFamily: 'aeonik-regular', }}>User List</em>
                  </MenuItem>
                  {this.state.userList.map((user: any , i:number) => (
                    <StyledMenuItem key={user.id} value={user.id}>
                      <ListItemText primary={user.first_name + " " + user.last_name} style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: 'aeonik-regular'
                           }}/>
                      <Checkbox 
                      style={this.handleStyle(user)}
                      checked={this.handleChecked(user)}
                      onMouseDown={()=>this.handleMouseDown(user)}
                      disabled = {user.is_in_group }
                      />
                    </StyledMenuItem>
                  ))}
                </Select>
              </Grid>

              

              {/* <Grid item xs={12} >
                {this.state.selectedTags?.map((field: any, id: number) => (<Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <Box style={{ width: "85%" }}>
                    <InputLabel className={classes.lable} style={{ marginTop: "10px" }}>
                      Select a tag
                    </InputLabel>
                    <Select
                      fullWidth
                      variant="outlined"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      className={classes.select}
                      style={{ height: "63px" }}
                      name="selectedTag"
                      data-test-id={`select-tag111`}
                      value={this.state.selectedTags[id]?.toString() || ''}
                      onChange={(event) => this.handleChangeTags(event, id)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <em style={{ fontFamily: 'aeonik-regular', }}>Select tag</em>
                      </MenuItem>
                      {this.state.tagList?.map((tag: any) => {
                        if (this.state.selectedTags.includes(tag.id)) {
                          return <MenuItem id="MenuItem" key={tag.id} style={{ display: 'none' }} value={tag.id.toString()}>
                            <ListItemText primary={tag.name} />
                          </MenuItem>
                        }
                        return (<MenuItem key={tag.id} value={tag.id.toString()}>
                          <ListItemText primary={tag.name} />
                        </MenuItem>)
                      })}
                    </Select>
                  </Box>
                  {id === 0 ? (
                    <Box className={classes.iconBox} style={{ pointerEvents: this.state.tagList.length <= this.state.selectedTags.length ? "none" : "auto" }}>
                      <AddIcon className={classes.addicon} onClick={this.handleAddField}
                      />
                      Add
                    </Box>
                  ) : (
                    <Box className={classes.iconBox} style={{ cursor: "pointer" }}>
                      <HighlightOffIcon style={{ color: "red", height: "30px", width: "30px" }} onClick={() => this.handleRemoveField(id)} />
                    </Box>
                  )}
                </Box>))}
              </Grid> */}
              {!this.state.isLiveSurveyModal && <>
              
              <Grid item xs={6}>
                <InputLabel className={classes.lable}>Start date</InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    name="selectedStartDate"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    data-test-id="startDate"
                    disablePast={true}
                    disabled={this.state.quickSchedule}
                    value={this.state.adminSurvey.selectedStartDate}
                    onChange={(date: any) => this.handleStartDate(date)}
                    className={classes.textField}
                    TextFieldComponent={(props: any) => (
                      <TextField
                        id="selected_Date"
                        {...props}
                        InputProps={{
                          ...props.InputProps,
                          style: { borderRadius: '10px', border: '1px solid #E0E0E0' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <CalendarTodayIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={classes.lable}>Time</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  disabled={this.state.quickSchedule}
                  name="selectedStartTime"
                  data-test-id="startTime"
                    value={
                      this.state.adminSurvey.selectedStartTime?.includes("M")
                          ?  this.state.adminSurvey.selectedStartTime
                          : moment( this.state.adminSurvey.selectedStartTime, "HH:mm").format("hh:mm A")
                  }
                    onChange={(event: any) => { this.handleChange(event); }}
                  displayEmpty
                >
                  {[ <MenuItem value="" disabled>
                    <em style={{ fontFamily: 'aeonik-regular', }}>09:00 AM</em>
                  </MenuItem>
                ]}
                 
                  {
                    this.getCustomSlot(this.state.adminSurvey.selectedStartTime).map((item: any, ind: any) => (
                      <MenuItem key={ind} value={item}>
                        {item}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button data-test-id="quick-schedule-btn"
                 style={this.quickScheduleStyle()}
                    onClick={this.quickScheduleHandler}>Start within 1 minute</Button>
                </Grid>
              </>}

              <Grid item xs={6}>
                <InputLabel className={classes.lable}>End date</InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    name="selectedEndDate"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    data-test-id="endDate"
                    disabled={this.state.quickEnd}
                    disablePast={true}
                    value={this.state.adminSurvey.selectedEndDate || null}
                    onChange={(date: any) => this.handleChangeAnother(date)}
                    className={classes.textField}
                    minDate={this.state.adminSurvey.selectedStartDate || new Date()}
                    TextFieldComponent={(props: any) => (
                      <TextField
                        {...props}
                        InputProps={{
                          ...props.InputProps,
                          style: { borderRadius: '10px', border: '1px solid #E0E0E0' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <CalendarTodayIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={classes.lable}>Time</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  disabled={this.state.quickEnd}
                  className={classes.select}
                  name="selectedEndTime"
                  data-test-id="endTime"
                  value={
                    this.state.adminSurvey.selectedEndTime?.includes("M")
                        ?  this.state.adminSurvey.selectedEndTime
                        : moment( this.state.adminSurvey.selectedEndTime, "HH:mm").format("hh:mm A")
                }
                  onChange={(event: any) => {
                        this.handleChange(event)
                }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em style={{ fontFamily: 'aeonik-regular', }}>10:00 AM</em>
                  </MenuItem>
                  {
                    this.getCustomSlot(this.state.adminSurvey.selectedEndTime).map((ele: any, ind: any) => (
                      <MenuItem key={ind} value={ele}>
                        {ele}
                      </MenuItem>
                    ))
                  }
                </Select>

              </Grid>
            {this.state.isLiveSurveyModal &&
            <Grid item xs={12}>
                  <Button data-test-id="quick-end-btn" onClick={this.quickHandler}  style={this.liveModalStyle()}>End within 1 minute</Button>
              </Grid>}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.btnschedle}>
                <Button
                  data-test-id="modal-btn"
                  className={classes.btnModal}
                  onClick={this.editSubmitHandler}
                >
                  Confirm
                </Button>
                <Button data-test-id="cancel-btn" className={classes.close} onClick={() => this.handleClose()}>Cancel</Button>
              </div>
            </Grid>

          </DialogActions>
          <Snackbar open={this.state.scheduleError} autoHideDuration={4000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            TransitionComponent={Slide}
            TransitionProps={{
              style: { transformOrigin: 'top center' },
              timeout: 500,
            }}
            onClose={this.handleSnackbarClose}>
            <Alert severity="warning"
              color="error"
              style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
              {this.state.scheduleError}
            </Alert>
          </Snackbar>
        </Dialog>

        <ContinueToEdit aria-labelledby="customized-dialog-title" open={this.state.cancelModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              padding: "25px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} gutterBottom>
              Error!
            </Typography>
            <Typography className={classes.confrim} gutterBottom>
              Are you sure you want to close the survey?<br />
              All the changes will be lost and can not be recovered.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Edit-btn"
                  className={classes.edit}
                  onClick={() => this.handleCancleModalOpen(this.state.adminSurvey)}
                >
                  Continue to edit
                </Button>
                <Button data-test-id="close-btn" className={classes.close} onClick={() => this.handleCancel()}>close</Button>
              </div>
            </Grid>
          </DialogActions>
        </ContinueToEdit>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const baseMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom' as PopoverOrigin['vertical'],
    horizontal: 'left' as PopoverOrigin['horizontal'],
  },
  transformOrigin: {
    vertical: 'top' as PopoverOrigin['vertical'],
    horizontal: 'left' as PopoverOrigin['horizontal'],
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      marginTop: '10px',
      borderRadius: '22px',
      border: '1px solid #E6E6E6',
    },
  },
};

// Extend the base menu props with specific styles
const groupMenuProps: Partial<MenuProps> = {
  ...baseMenuProps,
  PaperProps: {
    ...baseMenuProps.PaperProps,
    style: {
      ...baseMenuProps.PaperProps?.style,
      maxHeight: '50%',
    },
  },
};

const userMenuProps: Partial<MenuProps> = {
  ...baseMenuProps,
  PaperProps: {
    ...baseMenuProps.PaperProps,
    style: {
      ...baseMenuProps.PaperProps?.style,
      maxHeight: '40%',
    },
  },
};


const StyledMenuItem = styled(MenuItem)({
  '&:not(:last-child)': {
    borderBottom: '1px solid #ccc',
  },
});
const ContinueToEdit = styled(Dialog)({
  '& .MuiPaper-root': {
    '@media (max-width: 1920px)': {
      width:'40%',
    },
    '@media (max-width: 600px)': {
      width:'60%',
    },
  },
  '& .MuiDialog-paper': {
  },
})
const styles = {
  card: {
    height: '280px',
    borderRadius: '28px' as const,
    border: '0.5px solid #DEDEDE',
    background: '#FCFCFC',
  },
  container: {
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column' as const,
    color: 'var(--text, #202020)',
  },
  img: {
    width: '50px',
    height: '50px',
    marginBottom: '18px'
  },
  cardHeaderName: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '16px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    color: "#121212",
    height: "25px",
    overflow: "auto",
    wordWrap: 'break-word' as const,
  },
  subHead: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '36px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
  },
  btn: {
    padding: '18px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    width: "100%"
  },
  cardHeader: {
    borderBottom: '1px solid #E7E7E7',
    padding: '0 40px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 402px)": {
      padding: '0 10px',
    }
  },
  mainBox: {
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    height: "auto",
    flex: 1,
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },
  tabsurvey: {
    '&.MuiTab-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: ' 23px',
      fontFamily: 'aeonik-bold',
      color: ' #202020',
      opacity: ' 0.4',
      textTransform: 'capitalize' as const,
      minHeight: "100px",

    },
    '&.MuiTab-root.Mui-selected': {
      color: ' #202020',
      opacity: 1
    },
  },
  Respondents: {
    width: "150px",
    height: "22px",
    padding: "8px",
    borderRedius: "5px",
    gap: "10px",
    backgroundColor: "#E9EFFD",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
  },
  date: {
    fontSize: "11px",
    color: "#202020",
    marginBottom: "10px",
    fontFamily: 'aeonik-regular',
  },
  dateshow1: {
    padding: "5px",
    borderRadius: "5px",
    gap: "10px",
    backgroundColor: "#DEF0DB",
    color: "#202020",
    width: "max-content",
    marginBottom: "15px",
  },
  dateshow2: {
    padding: "5px",
    borderRadius: "5px",
    gap: "10px",
    backgroundColor: "#FFF3DB",
    color: "#202020",
    width: "max-content",
    marginBottom: "15px"
  },
  tital: {
    color: "Gray",
    fonSize: "10px",
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  dateAndTime: {
    display: "flex",
    gap: "2em",
    '@media (max-width: 1420px)': {
      gap: "0.5em",
      padding : "0px",
    }
  },
  SurveysDialoge: {
    height: '833px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    borderRadius: "16px",
  },
  filed: {
    padding: "20px"
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    marginBottom: "10px"
  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  btnModal: {
    padding: '18px 32px',
    width: "max-content",
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
  },
  edit: {
    padding: '18px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,

  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-regular',
    fontSize: "20px",
    fontWeight: 700
  },
  confrim: {
    textAlign: "center" as const,
    marginTop: "20px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "15px"
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  btnschedle: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px"
  },
  addicon: {
    color: "rgb(17, 82, 147)",
    height: "30px",
    width: "30px"
  },
  iconBox: {
    width: "10%",
    flexDirection: "column" as const,
    marginTop: "20px",
    marginLeft: "25px",
    fontFamily: 'aeonik-regular',
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "15px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7"
  },
  dateBox: {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  quickScheduleBtnUnselected: {
    padding: '18px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    width: "100%",
    border: "1px solid #1C386D"
}
};

export default withStyles(styles)(withTour(AdminSurveys));
// Customizable Area End
