import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface PaymentBodyData {
  cardHolderName: string;
  strtAddOne: string;
  strtAddTwo: string;
  aptUnit: string;
  city: string;
  county: string;
  postCode: string;
  country: string;
  tokenId: string;
};

interface SubscriptionPlan {
  id: number;
  title: string;
  price: string;
  stripe_product_id: string | null;
  stripe_price_id: string | null;
  created_at: string;
  sub_title: string;
  description: string[];
  duration: string;
  updated_at: string;
  billed: string | null;
};

export interface ValidResponseType {
  subscription_plans: SubscriptionPlan[];
  message: string;
};
  
export interface InvalidResponseType {
  errors: string;
  error: string;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: number;
  openModal: boolean;
  selectedPlan: SubscriptionPlan;
  plansList: SubscriptionPlan[];
  apiError: string;
  // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionPlanController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPlansApiCallId: string = "";
    makePaymentApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
          // Customizable Area Start
          activeTab: 1,
          openModal: false,
          selectedPlan: {
            id: 0,
            title: "",
            sub_title: "",
            description: [],
            duration: "",
            price: "",
            stripe_product_id: null,
            stripe_price_id: null,
            created_at: "",
            updated_at: "",
            billed: null
          },
          plansList: [],
          apiError: ""
          // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            if (responseJson.status === 500) {
              return;
            }
      
            if (this.isValidResponse(responseJson)) {
              this.apiSuccessCall(apiRequestCallId, responseJson);
            } else if (this.isInValidResponse(responseJson)) {
              this.apiFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    
  async componentDidMount() {
    this.getSubscriptionPlan();
  };

  apiCallLogin = async (valueData: {
    endPoint?: string;
    method?: string;
    body?: {};
    contentType?: string;
    type?: string;
  }) => {
    const { contentType, method, endPoint, body } = valueData;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && (responseJson.subscription_plans || responseJson.message === "Subscription created successfully");
  };
    
  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && (responseJson.errors || responseJson.error);
  };
    
  apiFailureCall = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType
  ) => {
    if (apiRequestCallId === this.getPlansApiCallId) {
      this.setState({ apiError: responseJson.errors });
      if(responseJson.errors === "Invalid Token") {
        this.handleNavigations("AdminAuth");
      }
    }
    if (apiRequestCallId === this.makePaymentApiCallId) {
      this.setState({ openModal: true });
    }
  };
    
  apiSuccessCall = async (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (apiRequestCallId === this.makePaymentApiCallId) {
      this.setState({ activeTab: 3 });
    }
    if (apiRequestCallId === this.getPlansApiCallId) {
      this.setState({ plansList: responseJson.subscription_plans });
    }
  };

  getSubscriptionPlan = async() => {
    this.getPlansApiCallId = await this.apiCallLogin({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getSubscriptionPlansEndPoint
    });
  };

  makePaymentApi = async(bodyData: PaymentBodyData) => {
    const { selectedPlan } = this.state;
    const bodyObject = {
      subscription: {
        subscription_plan_id: selectedPlan.id,
        token_stripe: bodyData.tokenId,
        address_address_1: bodyData.strtAddOne,
        address_address_2: bodyData.strtAddTwo,
        address_address_3: "",
        city: bodyData.city,
        state: "",
        county: bodyData.county,
        country: bodyData.country,
        postcode: bodyData.postCode,
        apt: bodyData.aptUnit,
        name: bodyData.cardHolderName
      }
    }
    this.makePaymentApiCallId = await this.apiCallLogin({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postMethod,
      endPoint: configJSON.makeSubscriptionPaymentEndPoint,
      body: bodyObject
    });
  };
    
  handleStartNowClick = (selectedPlanId: number) => {
    const selectedPlan = this.state.plansList.find(
      (item) => selectedPlanId === item.id
    );
    if (selectedPlan) {
      this.setState({
        activeTab: 2,
        selectedPlan: selectedPlan
      });
    }
  };

  handleBackClick = () => {
    this.setState({ 
      activeTab: 1,
      selectedPlan: {
        id: 0,
        title: "",
        sub_title: "",
        description: [],
        duration: "",
        price: "",
        stripe_product_id: null,
        stripe_price_id: null,
        created_at: "",
        updated_at: "",
        billed: null
      }
    });
  };

  handleModalClose = () => {
    this.setState({ openModal: false });
  };

  handleNavigations = (route: string) => {
    const mesg = new Message(getName(MessageEnum.NavigationMessage));
    mesg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    mesg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(mesg);
  };
  // Customizable Area End
}
