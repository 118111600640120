import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getCookie } from "../../../components/src/Tour/ManageCookies";
import { sampleRes, sqampleSurveyName } from "../../../components/src/Tour/DashboardTour";
import { TourProps } from "../../../components/src/Tour";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends TourProps {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  history: { push: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  surveyList: any;
  surveyTabValue: number;
  surveyIdValue:number;
  questionData:any[];
  headersWithCounts: { header: string; questionCount: number }[];
  questionTabValue: number;
  surveyDetails: any;
  snackbar: {
    open: boolean;
    severity: "success" | "info" | "warning" | "error" | undefined;
    message: string;
  };
  isFetchingSurveyDetails: boolean;
  isFetchingSurveyList: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AnalyticsWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSurveysCallId: string = "";
  getSurveyDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.analyticsReceive.bind(this);
    // Customizable Area Start
    this.state = {
      surveyList: [],
      questionData:[],
      headersWithCounts:[],
      surveyTabValue: -1,
      surveyIdValue:-1,
      questionTabValue: 0,
      surveyDetails: {},
      snackbar: {
        open: false,
        severity: "info",
        message: "",
      },
      isFetchingSurveyDetails: false,
      isFetchingSurveyList: true,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async analyticsReceive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const requestApiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const jsonRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!requestApiCallId || !jsonRes) {
      return;
    }

    if (requestApiCallId === this.getSurveysCallId && jsonRes) {
      this.receiveSurveyList(jsonRes);
    } else if (requestApiCallId === this.getSurveyDetailsCallId && jsonRes) {
      this.surveyDetailsReceive(jsonRes);
    }
    // Customizable Area End
  }

  getListOfSurveys = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSurveyListApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSurveyListApiMethod
    );

    this.getSurveysCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  receiveSurveyList = (responseJson: any) => {
    // Customizable Area Start
    const part = getCookie("productTour");
    if (part === "Part six completed") {
      responseJson = sqampleSurveyName;
    }
     if (responseJson?.surveys?.data) {
      const filteredSurveys = responseJson.surveys.data.filter(
        (survey: any) => survey.attributes.status !== "scheduled" && survey.attributes.status !== "draft"
      );
      const urlSearchParams = new URLSearchParams(window.location.search);
      const surveyId = urlSearchParams.get("surveyId");
      const surveyTabIndex = filteredSurveys.findIndex(
        (unitSurvey: any) => unitSurvey.id === surveyId
      );
      if (part === "Part six completed") {
        this.setState({
          surveyList: filteredSurveys,
          surveyTabValue: 1,
        });
      }else
      this.setState({
        surveyList: filteredSurveys,
        surveyTabValue: surveyTabIndex !== -1 ? surveyTabIndex : 0,
      });
    }
    this.setState({ isFetchingSurveyList: false });
    // Customizable Area End
  };

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    await Promise.all([this.getListOfSurveys()]);
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    // Customizable Area Start
    let count = await getStorageData("dashboard");
    console.log(this.state,"jhgjhdgjhge")
    const part = getCookie("productTour");
    if (prevState.surveyTabValue !== this.state.surveyTabValue) {
      await Promise.all([this.getSurveyDetails()]);
    }
    if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep && this.props.currentStep == 4) {
      this.setState({questionTabValue:1})
    }
    else if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep && this.props.currentStep == 5) {
      this.setState({questionTabValue:2})
    }
    else if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep && this.props.currentStep == 6) {
      this.setState({questionTabValue:3})
    }
    else if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep && this.props.currentStep == 7) {
      this.setState({questionTabValue:4})
    }
    else if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep && this.props.currentStep == 8) {
      this.setState({questionTabValue:5})
    }
    else if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep && this.props.currentStep == 9) {
      this.setState({questionTabValue:6})
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getHeaderForQuestion = (questionIndex: number) => {
    const headerInfo = this.state.headersWithCounts.find(headerInfo => headerInfo.questionCount === questionIndex);
    return headerInfo ? headerInfo.header : "";
}
handleChangeSurveyTab = (event:any,newValue: number) => {
  
  if (newValue !== this.state.surveyIdValue) {
    this.setState({ surveyIdValue: newValue });
    const value = newValue;
    this.props.history.push(`/AnalyticsWeb?surveyId=${this.state.surveyList[+value]?.id}`);
    const part = getCookie("productTour");
    if (part === "Part six completed") return
    this.setState({ surveyTabValue: value, questionTabValue: 0, surveyDetails: {} , questionData:[]});
  }
};

  handleChangeQuestionTab = (event: any, newValue: number) => {
    const part = getCookie("productTour");
    if (part === "Part six completed") return
    this.setState({ questionTabValue: newValue });
  };

  handleScroll = () => {
    const container = document.querySelector('[data-test-id="questionTabs"]');
    if (!container) return;
  
    const scrollContainer = container.parentElement;
    if (!scrollContainer) return; 
  
    const items = container.querySelectorAll('.MuiTab-root');
    let topIndex = -1;
    let minDistance = Infinity;
  
    items.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();
      const distance = Math.abs(rect.top - containerRect.top);
  
  
      if (distance < minDistance) {
        minDistance = distance;
        topIndex = index;
      }
    });

    this.handleChangeQuestionTab(null,topIndex);
  };
  
  

  getSurveyDetails = () => {
    this.setState({ isFetchingSurveyDetails: true });
    const authToken = localStorage.getItem("token");
    const surveyId = this.state.surveyList[this.state.surveyTabValue]?.id;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSurveyDetailsApiEndpoint}${surveyId}/survey_report`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSurveyDetailsApiMethod
    );

    this.getSurveyDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  surveyDetailsReceive = (response: any) => { 
    let responseJson=response;
    const part = getCookie("productTour");
    if (part === "Part six completed") {
      responseJson = sampleRes;
    }
        const questionData: any[] = [];
        const headersWithCounts: { header: string; questionCount: number }[] = [];
        if (responseJson?.overview) {
            if (responseJson.sections_list && Array.isArray(responseJson.sections_list)) {
                responseJson.sections_list.forEach((section: any) => {
                  if (section.header!=="") {
                    headersWithCounts.push({
                        header: section.header,
                        questionCount: questionData.length+1,
                    });
                  }
                    if (section.questions && Array.isArray(section.questions)) {
                        section.questions.forEach((question: any) => {
                            questionData.push(question);
                        });
                    }
                });
                
            }
            this.setState({
                surveyDetails: responseJson,
                questionData: questionData,
                headersWithCounts: headersWithCounts  
            });
        } 
        else {
      this.setState({
        snackbar: {
          message: responseJson.message || "Something went wrong",
          severity: "error",
          open: true,
        },
      });
    }
    this.setState({ isFetchingSurveyDetails: false });
  };


  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        severity: "info",
      },
    });
  };
  // Customizable Area End
}
