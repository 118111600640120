import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getStorageData,
  removeStorageData
} from "../../../framework/src/Utilities";

let fileUrl = require("../../../framework/src/config.js");

import { DateObject } from "react-multi-date-picker";
import FileSaver from "file-saver";

type DropDownOpen = "survey" | "tag" | "authority" | "industry" | null;

type ModalOpenType = "visualAnalytics" | "viewReport" | null;

type PostCode = number | string | null;

interface AdhocTable {
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    postcode: string;
  }
};

interface OptionsType {
  id: number;
  name: string;
};

interface QuestionsArray {
  question: string;
  question_type: string;
  answer: string;
  options: (string | number)[][];
  min_value: string;
  max_value: string;
  min_label: string;
  max_label: string;
};

interface SurveyAnswersResponse {
  first_name: string;
  last_name: string;
  survey_id: number;
  survey_name: number;
  postcode: PostCode;
  latitude: PostCode;
  longitude: PostCode;
  date_filled: string;
  answers: QuestionsArray[];
};

interface UserAnswersReport {
  user_id: string;
  surveys: SurveyAnswersResponse[];
};

export interface ValidResponseType {
  groups: { id: number, name: string }[],
  surveys: ((number | string)[] | SurveyAnswersResponse)[];
  ad_hoc_report: AdhocTable[];
  group_name: string;
  data: {date: string; users_filled: number}[];
  overall_avg_time_taken: number;
  industry_sector: {
    industry_sector: string;
    total_surveys: number;
  }[];
  local_authority: {
    local_authority: string;
    total_surveys: number;
  }[];
  total_count: number;
  total_pages: number;
  page_number: number;
  user_id: string;
};

export interface ErrorPayloadType {
  token: string;
};

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeItem: number;
  hoverItem: number;
  selectedDates: DateObject[];
  survey: number | null;
  tag: number[];
  authority: number[];
  industry: number[];
  openDropdown: DropDownOpen;
  modalOpenFor: ModalOpenType;
  groups: { id: number, name: string }[];
  surveys: (number | string)[][];
  adhocTable: AdhocTable[];
  page: number;
  totalCount: number;
  totalPages: number;
  group_name: string;
  data: {date: string; users_filled: number}[];
  overall_avg_time_taken: number;
  industry_sector: {
    industry_sector: string;
    total_surveys: number;
  }[];
  local_authority: {
    local_authority: string;
    total_surveys: number;
  }[];
  industryOptions: OptionsType[];
  localAuthorityOptions: OptionsType[];
  userAnswersReport: UserAnswersReport;
  openSnackbar: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdHocReportingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getGroupsApiCallId: string = "";
  getSurveysApiCallId: string = "";
  getAdhocReportApiCallId: string = "";
  getAnalyticsApiCallId: string = "";
  getCsvFileApiCallId: string = "";
  getAnswersApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeItem: 1,
      hoverItem: 0,
      selectedDates: [],
      survey: null,
      tag: [],
      authority: [],
      industry: [],
      openDropdown: null,
      modalOpenFor: null,
      groups: [],
      surveys: [],
      adhocTable: [],
      page: 1,
      totalCount: 20,
      totalPages: 20,
      group_name: "",
      data: [],
      overall_avg_time_taken: 0,
      industry_sector: [],
      local_authority: [],
      industryOptions: industriesOptions,
      localAuthorityOptions: localAuthority,
      userAnswersReport: {
        user_id: "",
        surveys: []
      },
      openSnackbar: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        return;
      }

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    this.getGroupsApi();
  };

  apiCall = async (valueData: {
    endPoint?: string;
    method?: string;
    body?: {};
    contentType?: string;
    type?: string;
  }) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body } = valueData;
    const header = {
      "Content-Type": contentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return !!(responseJson && !("errors" in responseJson));
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacks = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType
  ) => {
    if (apiRequestCallId === this.getGroupsApiCallId) {
      if(responseJson.errors[0].token === "Invalid token") {
        this.handleLogout();
      }
    }
  };

  handleLogout = async() => {
    await removeStorageData("refresh_token");
    await removeStorageData("token");
    await removeStorageData("user");
    this.handleNavigation("AuthBlock");
  };

  apiSuccessCallBacks = async (
    apiRequestCallId: string,
    responseJson: ValidResponseType
  ) => {
    if (apiRequestCallId === this.getGroupsApiCallId) {
      this.getGroupsSuccessResponse(responseJson);
    }
    if (apiRequestCallId === this.getSurveysApiCallId) {
      let updateResponse = responseJson.surveys as (number | string)[][];
      this.setState({
        surveys: updateResponse
      });
    }
    if (apiRequestCallId === this.getAdhocReportApiCallId) {
      this.getAdhocReportSuccessResponse(responseJson);
    }
    if (apiRequestCallId === this.getAnalyticsApiCallId) {
      const getUpdateData = responseJson.data.map(item => {
        const formattedData = this.formatDate(item.date);
        return { date: formattedData, users_filled: item.users_filled }
      })

      this.setState({
        group_name: responseJson.group_name,
        data: getUpdateData,
        overall_avg_time_taken: responseJson.overall_avg_time_taken,
        industry_sector: responseJson.industry_sector,
        local_authority: responseJson.local_authority
      }, () => this.handleModalOpen("visualAnalytics"))
    }
    if (apiRequestCallId === this.getAnswersApiCallId) {
      this.getAnswersSuccessResponse(responseJson);
    }    
  };

  getGroupsApi = async() => {
    this.getGroupsApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.adhocReportEndPoint}/show_all_groups`
    });
  };

  getSurveysApi = async() => {
    this.getSurveysApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.adhocReportEndPoint}/show_all_surveys`
    });
  };

  getAdHocReportApi = async(page: number) => {
    const { activeItem, survey, industryOptions, localAuthorityOptions, industry, authority } = this.state;
    const { startDate, endDate } = this.getStartAndEndDate();
    let getIndustries = this.getNameArrayFromIdArray(industry, industryOptions);
    let getLocalAuthorities = this.getNameArrayFromIdArray(authority, localAuthorityOptions);
    getIndustries = getIndustries.filter(item => item !== "Select all");
    getLocalAuthorities = getLocalAuthorities.filter(item => item !== "Select all");
    let body = {
      "industry_sector": getIndustries,
      "local_authority": getLocalAuthorities
    };
    this.getAdhocReportApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.adhocReportEndPoint}/show_ad_hoc_reporting?page=${page}&per_page=20&group_id=${activeItem}&start_date=${startDate}&end_date=${endDate}&survey_id=${survey}`,
      body: body
    });
  };

  getAnalyticsApi = async() => {
    const { activeItem, survey, industryOptions, localAuthorityOptions, industry, authority } = this.state;
    const { startDate, endDate } = this.getStartAndEndDate();
    let getIndustries = this.getNameArrayFromIdArray(industry, industryOptions);
    let getLocalAuthorities = this.getNameArrayFromIdArray(authority, localAuthorityOptions);
    getIndustries = getIndustries.filter(item => item !== "Select all");
    getLocalAuthorities = getLocalAuthorities.filter(item => item !== "Select all");
    let body = {
      "industry_sector": getIndustries,
      "local_authority": getLocalAuthorities
    };
    this.getAnalyticsApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      body: body,
      endPoint: `${configJSON.adhocReportEndPoint}/visual_analytics?group_id=${activeItem}&start_date=${startDate}&end_date=${endDate}&survey_id=${survey}`
    });
  };

  getAnswersApi = async(userId: number) => {
    const { survey } = this.state;
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    let { startDate, endDate } = this.getStartAndEndDate();
    if(!startDate) {
      startDate = "15/07/1994";
    }
    if(!endDate) {
      endDate = formattedDate;
    }
    this.getAnswersApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.adhocReportEndPoint}/user_answer?user_id=${userId}&start_date=${startDate}&end_date=${endDate}&survey_id=${survey}`
    });
  };

  getCsvFileApi = async() => {
    const token = await getStorageData("token");
    const { activeItem, survey, industryOptions, localAuthorityOptions, industry, authority } = this.state;
    const { startDate, endDate } = this.getStartAndEndDate();
    let getIndustries = this.getNameArrayFromIdArray(industry, industryOptions);
    let getLocalAuthorities = this.getNameArrayFromIdArray(authority, localAuthorityOptions);
    getIndustries = getIndustries.filter(item => item !== "Select all");
    getLocalAuthorities = getLocalAuthorities.filter(item => item !== "Select all");
    try {
      const response = await fetch(`${fileUrl.baseURL}/${configJSON.adhocReportEndPoint}/export_survey_data?group_id=${activeItem}group_id=${activeItem}&start_date=${startDate}&end_date=${endDate}&survey_id=${survey}&industry_sector=${getIndustries}&local_authority=${getLocalAuthorities}`,
        {
          method: configJSON.getApiMethodType,
          headers: {
            "token": token,
            "Accept": "text/csv",
          },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch CSV");
      }

      const contentType = response.headers.get("Content-Type") || "";
      if (!contentType.includes("text/csv")) {
        throw new Error("Invalid file format");
      }

      const csvContent = await response.blob();
      FileSaver.saveAs(csvContent, "Ad hoc report.csv", { autoBom: false });
    } catch (error) {
      throw new Error("Failed to fetch CSV");
    }
  }

  getGroupsSuccessResponse = (responseJson: ValidResponseType) => {
    this.setState({
      groups: responseJson.groups
    }, () => {
      this.getAdHocReportApi(1);
      this.getSurveysApi();
    });
    if(responseJson.groups.length !== 0) {
      this.setState({
        activeItem: responseJson.groups[0].id
      });
    }
  };

  getAdhocReportSuccessResponse = (responseJson: ValidResponseType) => {
    if(responseJson.ad_hoc_report.length === 0 && responseJson.page_number !== 1) {
      this.getAdHocReportApi(1);
    } else {
      this.setState({
        adhocTable: responseJson.ad_hoc_report,
        totalCount: responseJson.total_count,
        totalPages: responseJson.total_pages,
        page: responseJson.page_number
      });
    }
  };

  getAnswersSuccessResponse = ( responseJson: ValidResponseType) => {
    if(responseJson.surveys.length === 0){
      this.setState({
        openSnackbar: true
      })
    } else {
      const updateReport = {
        user_id: responseJson.user_id,
        surveys: responseJson.surveys as SurveyAnswersResponse[]
      }
      this.setState({
        userAnswersReport: updateReport
      }, ()=> this.handleModalOpen("viewReport"));
    }
  };

  formatDate = (date: string | Date): string => {
    const parsedDate = typeof date === "string" ? new Date(date) : date;    
    if (isNaN(parsedDate.getTime())) {
      throw new Error("Invalid date format");
    } 
    return parsedDate.toLocaleDateString("en-GB", { day: "2-digit", month: "short" });
  };

  formatDateWithYear = (date: string | Date): string => {
    if (!date) return "";
    let parsedDate: Date;
    if (typeof date === "string") {
      const parts = date.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      if (parts) {
        const [, day, month, year] = parts;
        parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
      } else {
        parsedDate = new Date(date);
      }
    } else {
      parsedDate = date;
    }
    if (isNaN(parsedDate.getTime())) {
      return "";
    }
    return parsedDate.toLocaleDateString("en-GB", { 
      day: "2-digit", 
      month: "short", 
      year: "numeric" 
    });
  };
  
  getStartAndEndDate = () => {
    const { selectedDates } = this.state;
    if (Array.isArray(selectedDates) && selectedDates.length > 0) {
      const startDate = selectedDates[0].format("DD/MM/YYYY");
      const endDate = selectedDates.length > 1 ? selectedDates[1].format("DD/MM/YYYY") : "";
      return { startDate, endDate };
    }
    return { startDate: "", endDate: "" };
  };

    handleHoverEnter = (itemId: number) => {
        this.setState({
            hoverItem: itemId,
        });
    };

    handleHoverLeave = () => {
        this.setState({
            hoverItem: 0,
        });
    };

  handleActiveTab = (itemId: number) => {
    this.setState({
      activeItem: itemId,
      page: 1
    }, () => this.getAdHocReportApi(1));
  };

  handleDateChange = (dates: DateObject[] | Date[] | string[]) => {
    this.setState({
      selectedDates: dates as DateObject[]
    },
      ()=> this.getAdHocReportApi(this.state.page)
    );
  }

  handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    name: string,
    options: OptionsType[]
  ) => {
    if (name === "survey") {
      let { value } = event.target;
      if (typeof value === "number") {
        this.setState({
          [name]: value,
        } as unknown as Pick<S, keyof S>, () => this.getAdHocReportApi(this.state.page));
      }
    } else {
      const { value } = event.target as HTMLSelectElement;
      if (Array.isArray(value)) {
        let updateValue = [...value];
        const idArrays = options.map(item => item.id);
        if (value.includes(1)) {
          updateValue = idArrays;
        }
        this.setState({
          [name]: updateValue,
        } as unknown as Pick<S, keyof S>, () => this.getAdHocReportApi(this.state.page));
      }
    }
  };

  handleSelectRenderValue = (value: unknown, name: string, options: { id: number, name: string }[]): React.ReactNode => {
    const placeholder: Record<typeof name, string> = configJSON.filterPlaceholders;
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return placeholder[name];
    }
    if (typeof value === "number") {
      return this.getNameFromId(value, options);
    }
    if (Array.isArray(value)) {
      const valueArray = options.filter(item => value.includes(item.id));
      const nameArray = valueArray.map(item => item.name);
      return nameArray.join(", ");
    }
  };

    handleModalOpen = (openFor: string) => {
        this.setState({
          modalOpenFor: openFor as ModalOpenType
        });
    };
    
    handleModalClose = () => {
        this.setState({
          modalOpenFor: null
        });
    };
  
  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handlePageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    this.getAdHocReportApi(newPage);
  };

  getNameFromId = (idNumber: number, options: OptionsType[]) => {
    const item = options.find(item => item.id === idNumber);
    return item ? item.name: null;
  };

  getNameArrayFromIdArray = (idNumber: number[], options: OptionsType[]) => {
    const valueArray = options.filter(item => idNumber.includes(item.id));
    const nameArray = valueArray.map(item => item.name);
    return nameArray;
  };

  formateSurveyDate = (inputDate: string) => {
    const [day, month, year] = inputDate.split('-').map(Number);
    const date = new Date(year, month - 1, day);
  
    const formattedDate = `${day} ${date.toLocaleString('en-US', { month: 'long' })} ${year}`;
    return formattedDate;
  };

  formateQuestionResponse = (questions: SurveyAnswersResponse[]) => {
    const allQuestions = questions.flatMap(survey => survey.answers);
    const updateQuestions = allQuestions.map(item => {
      let newItem = item;
      if (item.question_type === "slider") {
        const min_value = item.min_value? item.min_value: "";
        const max_value = item.max_value? item.max_value: "";
        const min_label = item.min_label? item.min_label: "";
        const max_label = item.max_label? item.max_label: "";
        newItem = {...item, min_value, max_value, min_label, max_label}
      }
      return newItem;
    });
    return updateQuestions;
  };

  handleSnackbarClose = () => {
    this.setState({
      openSnackbar: false
    });
  };

  handleVisualAnalyticsClick = () => {
    if(this.state.adhocTable.length === 0) {
      this.setState({
        openSnackbar: true
      })
    } else {
      this.getAnalyticsApi()
    }
  };

  handleExportClick = () => {
    if(this.state.adhocTable.length === 0) {
      this.setState({
        openSnackbar: true
      })
    } else {
      this.getCsvFileApi()
    }
  };

  handleDateRender = (dateArray: DateObject[]) => {
    let dateToShow = "";
    if(dateArray.length === 1) {
      dateToShow = `From ${dateArray[0].format("DD/MM/YYYY")}`
    } else if(dateArray.length === 2) {
      dateToShow = `${dateArray[0].format("DD/MM/YYYY")} - ${dateArray[1].format(
        "DD/MM/YYYY"
      )}`
    }
    return dateToShow;
  }
  // Customizable Area End
}

// Customizable Area Start
const localAuthority = [
  {
    id: 1, 
    name: "Select all"
  },
  {
    id: 6379,
    name: "Aberdeen City"
  },
  {
    id: 6380,
    name: "Aberdeenshire",
      
  },
  {
    id: 6468,
    name: "Added By Super Admin",
  },
  {
    id: 6475,
    name: "Added To Angus",
  },
  {
    id: 6470,
    name: "Added5"
  },
  {
    id: 6261,
    name: "Adur",
  },
  {
    id: 6105,
    name: "Allerdale 123"
  },
  {
    id: 6111,
    name: "Amber Valley"
  },
  {
    id: 6350,
    name: "Antrim and Newtownabbey"
  },
  {
    id: 6360,
    name: "Ards and North Down"
  },
  {
    id: 6381,
    name: "Argyll and Bute"
  },
  {
    id: 6351,
    name: "Armagh City, Banbridge and Craigavon"
  },
  {
    id: 6262,
    name: "Arun"
  },
  {
    id: 6219,
    name: "Ashfield"
  },
  {
    id: 6167,
    name: "Ashford"
  },
  {
    id: 6242,
    name: "Babergh"
  },
  {
    id: 6318,
    name: "Barking and Dagenham"
  },
  {
    id: 6319,
    name: "Barnet"
  },
  {
    id: 6296,
    name: "Barnsley"
  },
  {
    id: 6106,
    name: "Barrow-in-Furness"
  },
  {
    id: 6132,
    name: "Basildon"
  },
  {
    id: 6150,
    name: "Basingstoke and Deane"
  },
  {
    id: 6220,
    name: "Bassetlaw"
  },
  {
    id: 6062,
    name: "Bath and North East Somerset"
  },
  {
    id: 6092,
    name: "Bedford"
  },
  {
    id: 6352,
    name: "Belfast"
  },
  {
    id: 6320,
    name: "Bexley"
  },
  {
    id: 6304,
    name: "Birmingham"
  },
  {
    id: 6191,
    name: "Blaby"
  },
  {
    id: 6048,
    name: "Blackburn with Darwen"
  },
  {
    id: 6049,
    name: "Blackpool"
  },
  {
    id: 6409,
    name: "Blaenau Gwent"
  },
  {
    id: 6112,
    name: "Bolsover"
  },
  {
    id: 6281,
    name: "Bolton"
  },
  {
    id: 6198,
    name: "Boston"
  },
  {
    id: 6469,
    name: "Boston 123"
  },
  {
    id: 6095,
    name: "Bournemouth, Christchurch and Poole"
  },
  {
    id: 6074,
    name: "Bracknell Forest"
  },
  {
    id: 6311,
    name: "Bradford"
  },
  {
    id: 6133,
    name: "Braintree"
  },
  {
    id: 6205,
    name: "Breckland"
  },
  {
    id: 6321,
    name: "Brent"
  },
  {
    id: 6134,
    name: "Brentwood"
  },
  {
    id: 6404,
    name: "Bridgend"
  },
  {
    id: 6081,
    name: "Brighton and Hove"
  },
  {
    id: 6063,
    name: "Bristol, City of"
  },
  {
    id: 6206,
    name: "Broadland"
  },
  {
    id: 6322,
    name: "Bromley"
  },
  {
    id: 6268,
    name: "Bromsgrove"
  },
  {
    id: 6161,
    name: "Broxbourne"
  },
  {
    id: 6221,
    name: "Broxtowe"
  },
  {
    id: 6097,
    name: "Buckinghamshire"
  },
  {
    id: 6179,
    name: "Burnley"
  },
  {
    id: 6282,
    name: "Bury"
  },
  {
    id: 6408,
    name: "Caerphilly"
  },
  {
    id: 6312,
    name: "Calderdale"
  },
  {
    id: 6100,
    name: "Cambridge"
  },
  {
    id: 6323,
    name: "Camden"
  },
  {
    id: 6234,
    name: "Cannock Chase"
  },
  {
    id: 6168,
    name: "Canterbury"
  },
  {
    id: 6406,
    name: "Cardiff"
  },
  {
    id: 6107,
    name: "Carlisle"
  },
  {
    id: 6401,
    name: "Carmarthenshire"
  },
  {
    id: 6135,
    name: "Castle Point"
  },
  {
    id: 6353,
    name: "Causeway Coast and Glens"
  },
  {
    id: 6093,
    name: "Central Bedfordshire"
  },
  {
    id: 6399,
    name: "Ceredigion"
  },
  {
    id: 6192,
    name: "Charnwood"
  },
  {
    id: 6136,
    name: "Chelmsford"
  },
  {
    id: 6144,
    name: "Cheltenham"
  },
  {
    id: 6226,
    name: "Cherwell"
  },
  {
    id: 6086,
    name: "Cheshire East"
  },
  {
    id: 6087,
    name: "Cheshire West and Chester"
  },
  {
    id: 6113,
    name: "Chesterfield"
  },
  {
    id: 6263,
    name: "Chichester"
  },
  {
    id: 6180,
    name: "Chorley"
  },
  {
    id: 6382,
    name: "City of Edinburgh"
  },
  {
    id: 6317,
    name: "City of London"
  },
  {
    id: 6361,
    name: "Clackmannanshire"
  },
  {
    id: 6137,
    name: "Colchester"
  },
  {
    id: 6395,
    name: "Conwy"
  },
  {
    id: 6108,
    name: "Copeland"
  },
  {
    id: 6089,
    name: "Cornwall"
  },
  {
    id: 6145,
    name: "Cotswold"
  },
  {
    id: 6085,
    name: "County Durham"
  },
  {
    id: 6305,
    name: "Coventry"
  },
  {
    id: 6212,
    name: "Craven"
  },
  {
    id: 6264,
    name: "Crawley"
  },
  {
    id: 6324,
    name: "Croydon"
  },
  {
    id: 6162,
    name: "Dacorum"
  },
  {
    id: 6045,
    name: "Darlington"
  },
  {
    id: 6169,
    name: "Dartford"
  },
  {
    id: 6396,
    name: "Denbighshire"
  },
  {
    id: 6055,
    name: "Derby"
  },
  {
    id: 6114,
    name: "Derbyshire Dales"
  },
  {
    id: 6354,
    name: "Derry City and Strabane"
  },
  {
    id: 6297,
    name: "Doncaster"
  },
  {
    id: 6096,
    name: "Dorset"
  },
  {
    id: 6170,
    name: "Dover"
  },
  {
    id: 6306,
    name: "Dudley"
  },
  {
    id: 6362,
    name: "Dumfries and Galloway"
  },
  {
    id: 6387,
    name: "Dundee City"
  },
  {
    id: 6325,
    name: "Ealing"
  },
  {
    id: 6363,
    name: "East Ayrshire"
  },
  {
    id: 6101,
    name: "East Cambridgeshire"
  },
  {
    id: 6119,
    name: "East Devon"
  },
  {
    id: 6388,
    name: "East Dunbartonshire"
  },
  {
    id: 6151,
    name: "East Hampshire"
  },
  {
    id: 6276,
    name: "East Hertfordshire"
  },
  {
    id: 6199,
    name: "East Lindsey"
  },
  {
    id: 6364,
    name: "East Lothian"
  },
  {
    id: 6365,
    name: "East Renfrewshire"
  },
  {
    id: 6051,
    name: "East Riding of Yorkshire"
  },
  {
    id: 6235,
    name: "East Staffordshire"
  },
  {
    id: 6278,
    name: "East Suffolk"
  },
  {
    id: 6127,
    name: "Eastbourne"
  },
  {
    id: 6152,
    name: "Eastleigh"
  },
  {
    id: 6109,
    name: "Eden"
  },
  {
    id: 6466,
    name: "Editing"
  },
  {
    id: 6245,
    name: "Elmbridge"
  },
  {
    id: 6326,
    name: "Enfield"
  },
  {
    id: 6138,
    name: "Epping Forest"
  },
  {
    id: 6246,
    name: "Epsom and Ewell"
  },
  {
    id: 6115,
    name: "Erewash"
  },
  {
    id: 6120,
    name: "Exeter"
  },
  {
    id: 6367,
    name: "Falkirk"
  },
  {
    id: 6153,
    name: "Fareham"
  },
  {
    id: 6102,
    name: "Fenland"
  },
  {
    id: 6355,
    name: "Fermanagh and Omagh"
  },
  {
    id: 6389,
    name: "Fife"
  },
  {
    id: 6397,
    name: "Flintshire"
  },
  {
    id: 6174,
    name: "Folkestone and Hythe"
  },
  {
    id: 6146,
    name: "Forest of Dean"
  },
  {
    id: 6181,
    name: "Fylde"
  },
  {
    id: 6316,
    name: "Gateshead"
  },
  {
    id: 6222,
    name: "Gedling"
  },
  {
    id: 6391,
    name: "Glasgow City"
  },
  {
    id: 6147,
    name: "Gloucester"
  },
  {
    id: 6154,
    name: "Gosport"
  },
  {
    id: 6171,
    name: "Gravesham"
  },
  {
    id: 6207,
    name: "Great Yarmouth"
  },
  {
    id: 6327,
    name: "Greenwich"
  },
  {
    id: 6247,
    name: "Guildford"
  },
  {
    id: 6394,
    name: "Gwynedd"
  },
  {
    id: 6328,
    name: "Hackney"
  },
  {
    id: 6046,
    name: "Halton"
  },
  {
    id: 6213,
    name: "Hambleton"
  },
  {
    id: 6329,
    name: "Hammersmith and Fulham"
  },
  {
    id: 6193,
    name: "Harborough"
  },
  {
    id: 6330,
    name: "Haringey"
  },
  {
    id: 6139,
    name: "Harlow"
  },
  {
    id: 6214,
    name: "Harrogate"
  },
  {
    id: 6331,
    name: "Harrow"
  },
  {
    id: 6155,
    name: "Hart"
  },
  {
    id: 6041,
    name: "Hartlepool"
  },
  {
    id: 6128,
    name: "Hastings"
  },
  {
    id: 6156,
    name: "Havant"
  },
  {
    id: 6332,
    name: "Havering"
  },
  {
    id: 6059,
    name: "Herefordshire, County of"
  },
  {
    id: 6163,
    name: "Hertsmere"
  },
  {
    id: 6116,
    name: "High Peak"
  },
  {
    id: 6368,
    name: "Highland"
  },
  {
    id: 6333,
    name: "Hillingdon"
  },
  {
    id: 6194,
    name: "Hinckley and Bosworth"
  },
  {
    id: 6265,
    name: "Horsham"
  },
  {
    id: 6334,
    name: "Hounslow"
  },
  {
    id: 6103,
    name: "Huntingdonshire"
  },
  {
    id: 6182,
    name: "Hyndburn"
  },
  {
    id: 6386,
    name: "India"
  },
  {
    id: 6467,
    name: "Individual County"
  },
  {
    id: 6369,
    name: "Inverclyde"
  },
  {
    id: 6243,
    name: "Ipswich"
  },
  {
    id: 6393,
    name: "Isle of Anglesey"
  },
  {
    id: 6084,
    name: "Isle of Wight"
  },
  {
    id: 6090,
    name: "Isles of Scilly"
  },
  {
    id: 6335,
    name: "Islington"
  },
  {
    id: 6336,
    name: "Kensington and Chelsea"
  },
  {
    id: 6208,
    name: "King's Lynn and West Norfolk"
  },
  {
    id: 6050,
    name: "Kingston upon Hull, City of"
  },
  {
    id: 6337,
    name: "Kingston upon Thames"
  },
  {
    id: 6313,
    name: "Kirklees"
  },
  {
    id: 6291,
    name: "Knowsley"
  },
  {
    id: 6338,
    name: "Lambeth"
  },
  {
    id: 6183,
    name: "Lancaster"
  },
  {
    id: 6314,
    name: "Leeds"
  },
  {
    id: 6056,
    name: "Leicester"
  },
  {
    id: 6129,
    name: "Lewes"
  },
  {
    id: 6339,
    name: "Lewisham"
  },
  {
    id: 6236,
    name: "Lichfield"
  },
  {
    id: 6200,
    name: "Lincoln"
  },
  {
    id: 6356,
    name: "Lisburn and Castlereagh"
  },
  {
    id: 6292,
    name: "Liverpool"
  },
  {
    id: 6070,
    name: "Luton"
  },
  {
    id: 6172,
    name: "Maidstone"
  },
  {
    id: 6140,
    name: "Maldon"
  },
  {
    id: 6269,
    name: "Malvern Hills"
  },
  {
    id: 6283,
    name: "Manchester"
  },
  {
    id: 6223,
    name: "Mansfield"
  },
  {
    id: 6073,
    name: "Medway"
  },
  {
    id: 6195,
    name: "Melton"
  },
  {
    id: 6231,
    name: "Mendip"
  },
  {
    id: 6414,
    name: "Merthyr Tydfil"
  },
  {
    id: 6340,
    name: "Merton"
  },
  {
    id: 6121,
    name: "Mid Devon"
  },
  {
    id: 6244,
    name: "Mid Suffolk"
  },
  {
    id: 6266,
    name: "Mid Sussex"
  },
  {
    id: 6358,
    name: "Mid Ulster"
  },
  {
    id: 6357,
    name: "Mid and East Antrim"
  },
  {
    id: 6042,
    name: "Middlesbrough"
  },
  {
    id: 6370,
    name: "Midlothian"
  },
  {
    id: 6080,
    name: "Milton Keynes"
  },
  {
    id: 6248,
    name: "Mole Valley"
  },
  {
    id: 6411,
    name: "Monmouthshire"
  },
  {
    id: 6371,
    name: "Moray"
  },
  {
    id: 6366,
    name: "Na h-Eileanan Siar"
  },
  {
    id: 6403,
    name: "Neath Port Talbot"
  },
  {
    id: 6157,
    name: "New Forest"
  },
  {
    id: 6224,
    name: "Newark and Sherwood"
  },
  {
    id: 6300,
    name: "Newcastle upon Tyne"
  },
  {
    id: 6237,
    name: "Newcastle-under-Lyme"
  },
  {
    id: 6341,
    name: "Newham"
  },
  {
    id: 6412,
    name: "Newport"
  },
  {
    id: 6359,
    name: "Newry, Mourne and Down"
  },
  {
    id: 6372,
    name: "North Ayrshire"
  },
  {
    id: 6122,
    name: "North Devon"
  },
  {
    id: 6117,
    name: "North East Derbyshire"
  },
  {
    id: 6052,
    name: "North East Lincolnshire"
  },
  {
    id: 6164,
    name: "North Hertfordshire"
  },
  {
    id: 6201,
    name: "North Kesteven"
  },
  {
    id: 6392,
    name: "North Lanarkshire"
  },
  {
    id: 6053,
    name: "North Lincolnshire"
  },
  {
    id: 6209,
    name: "North Norfolk"
  },
  {
    id: 6098,
    name: "North Northamptonshire"
  },
  {
    id: 6064,
    name: "North Somerset"
  },
  {
    id: 6301,
    name: "North Tyneside"
  },
  {
    id: 6256,
    name: "North Warwickshire"
  },
  {
    id: 6196,
    name: "North West Leicestershire"
  },
  {
    id: 6094,
    name: "Northumberland"
  },
  {
    id: 6210,
    name: "Norwich"
  },
  {
    id: 6058,
    name: "Nottingham"
  },
  {
    id: 6257,
    name: "Nuneaton and Bedworth"
  },
  {
    id: 6197,
    name: "Oadby and Wigston"
  },
  {
    id: 6284,
    name: "Oldham"
  },
  {
    id: 6373,
    name: "Orkney Islands"
  },
  {
    id: 6227,
    name: "Oxford"
  },
  {
    id: 6400,
    name: "Pembrokeshire"
  },
  {
    id: 6184,
    name: "Pendle"
  },
  {
    id: 6390,
    name: "Perth and Kinross"
  },
  {
    id: 6069,
    name: "Peterborough"
  },
  {
    id: 6066,
    name: "Plymouth"
  },
  {
    id: 6082,
    name: "Portsmouth"
  },
  {
    id: 6413,
    name: "Powys 123"
  },
  {
    id: 6185,
    name: "Preston"
  },
  {
    id: 6076,
    name: "Reading"
  },
  {
    id: 6342,
    name: "Redbridge"
  },
  {
    id: 6043,
    name: "Redcar and Cleveland"
  },
  {
    id: 6270,
    name: "Redditch"
  },
  {
    id: 6249,
    name: "Reigate and Banstead"
  },
  {
    id: 6383,
    name: "Renfrewshire"
  },
  {
    id: 6407,
    name: "Rhondda Cynon Taf"
  },
  {
    id: 6186,
    name: "Ribble Valley"
  },
  {
    id: 6343,
    name: "Richmond upon Thames"
  },
  {
    id: 6215,
    name: "Richmondshire"
  },
  {
    id: 6285,
    name: "Rochdale"
  },
  {
    id: 6141,
    name: "Rochford"
  },
  {
    id: 6187,
    name: "Rossendale"
  },
  {
    id: 6130,
    name: "Rother"
  },
  {
    id: 6298,
    name: "Rotherham"
  },
  {
    id: 6258,
    name: "Rugby"
  },
  {
    id: 6250,
    name: "Runnymede"
  },
  {
    id: 6225,
    name: "Rushcliffe"
  },
  {
    id: 6158,
    name: "Rushmoor"
  },
  {
    id: 6057,
    name: "Rutland"
  },
  {
    id: 6216,
    name: "Ryedale"
  },
  {
    id: 6286,
    name: "Salford"
  },
  {
    id: 6307,
    name: "Sandwell"
  },
  {
    id: 6217,
    name: "Scarborough"
  },
  {
    id: 6374,
    name: "Scottish Borders"
  },
  {
    id: 6232,
    name: "Sedgemoor"
  },
  {
    id: 6294,
    name: "Sefton"
  },
  {
    id: 6218,
    name: "Selby"
  },
  {
    id: 6173,
    name: "Sevenoaks"
  },
  {
    id: 6299,
    name: "Sheffield"
  },
  {
    id: 6375,
    name: "Shetland Islands"
  },
  {
    id: 6088,
    name: "Shropshire"
  },
  {
    id: 6077,
    name: "Slough"
  },
  {
    id: 6308,
    name: "Solihull"
  },
  {
    id: 6280,
    name: "Somerset West and Taunton"
  },
  {
    id: 6376,
    name: "South Ayrshire"
  },
  {
    id: 6104,
    name: "South Cambridgeshire"
  },
  {
    id: 6118,
    name: "South Derbyshire"
  },
  {
    id: 6065,
    name: "South Gloucestershire"
  },
  {
    id: 6123,
    name: "South Hams"
  },
  {
    id: 6202,
    name: "South Holland"
  },
  {
    id: 6203,
    name: "South Kesteven"
  },
  {
    id: 6110,
    name: "South Lakeland"
  },
  {
    id: 6377,
    name: "South Lanarkshire"
  },
  {
    id: 6211,
    name: "South Norfolk"
  },
  {
    id: 6228,
    name: "South Oxfordshire"
  },
  {
    id: 6188,
    name: "South Ribble"
  },
  {
    id: 6233,
    name: "South Somerset"
  },
  {
    id: 6238,
    name: "South Staffordshire"
  },
  {
    id: 6302,
    name: "South Tyneside"
  },
  {
    id: 6083,
    name: "Southampton"
  },
  {
    id: 6071,
    name: "Southend-on-Sea"
  },
  {
    id: 6344,
    name: "Southwark"
  },
  {
    id: 6251,
    name: "Spelthorne"
  },
  {
    id: 6274,
    name: "St Albans"
  },
  {
    id: 6293,
    name: "St. Helens"
  },
  {
    id: 6239,
    name: "Stafford"
  },
  {
    id: 6240,
    name: "Staffordshire Moorlands"
  },
  {
    id: 6277,
    name: "Stevenage"
  },
  {
    id: 6378,
    name: "Stirling"
  },
  {
    id: 6287,
    name: "Stockport"
  },
  {
    id: 6044,
    name: "Stockton-on-Tees"
  },
  {
    id: 6061,
    name: "Stoke-on-Trent"
  },
  {
    id: 6259,
    name: "Stratford-on-Avon"
  },
  {
    id: 6148,
    name: "Stroud"
  },
  {
    id: 6303,
    name: "Sunderland"
  },
  {
    id: 6252,
    name: "Surrey Heath"
  },
  {
    id: 6345,
    name: "Sutton"
  },
  {
    id: 6175,
    name: "Swale"
  },
  {
    id: 6402,
    name: "Swansea"
  },
  {
    id: 6068,
    name: "Swindon"
  },
  {
    id: 6288,
    name: "Tameside"
  },
  {
    id: 6241,
    name: "Tamworth"
  },
  {
    id: 6253,
    name: "Tandridge"
  },
  {
    id: 6124,
    name: "Teignbridge"
  },
  {
    id: 6060,
    name: "Telford and Wrekin"
  },
  {
    id: 6142,
    name: "Tendring"
  },
  {
    id: 6159,
    name: "Test Valley"
  },
  {
    id: 6149,
    name: "Tewkesbury"
  },
  {
    id: 6176,
    name: "Thanet"
  },
  {
    id: 6165,
    name: "Three Rivers"
  },
  {
    id: 6072,
    name: "Thurrock"
  },
  {
    id: 6177,
    name: "Tonbridge and Malling"
  },
  {
    id: 6067,
    name: "Torbay"
  },
  {
    id: 6410,
    name: "Torfaen"
  },
  {
    id: 6125,
    name: "Torridge"
  },
  {
    id: 6346,
    name: "Tower Hamlets"
  },
  {
    id: 6289,
    name: "Trafford"
  },
  {
    id: 6178,
    name: "Tunbridge Wells"
  },
  {
    id: 6143,
    name: "Uttlesford"
  },
  {
    id: 6405,
    name: "Vale of Glamorgan"
  },
  {
    id: 6229,
    name: "Vale of White Horse"
  },
  {
    id: 6315,
    name: "Wakefield"
  },
  {
    id: 6309,
    name: "Walsall"
  },
  {
    id: 6347,
    name: "Waltham Forest"
  },
  {
    id: 6348,
    name: "Wandsworth"
  },
  {
    id: 6047,
    name: "Warrington"
  },
  {
    id: 6260,
    name: "Warwick"
  },
  {
    id: 6166,
    name: "Watford"
  },
  {
    id: 6254,
    name: "Waverley"
  },
  {
    id: 6131,
    name: "Wealden"
  },
  {
    id: 6275,
    name: "Welwyn Hatfield"
  },
  {
    id: 6075,
    name: "West Berkshire"
  },
  {
    id: 6126,
    name: "West Devon"
  },
  {
    id: 6384,
    name: "West Dunbartonshire"
  },
  {
    id: 6189,
    name: "West Lancashire"
  },
  {
    id: 6204,
    name: "West Lindsey"
  },
  {
    id: 6385,
    name: "West Lothian"
  },
  {
    id: 6099,
    name: "West Northamptonshire"
  },
  {
    id: 6230,
    name: "West Oxfordshire"
  },
  {
    id: 6279,
    name: "West Suffolk"
  },
  {
    id: 6349,
    name: "Westminster"
  },
  {
    id: 6290,
    name: "Wigan"
  },
  {
    id: 6091,
    name: "Wiltshire"
  },
  {
    id: 6160,
    name: "Winchester"
  },
  {
    id: 6078,
    name: "Windsor and Maidenhead"
  },
  {
    id: 6295,
    name: "Wirral"
  },
  {
    id: 6255,
    name: "Woking"
  },
  {
    id: 6079,
    name: "Wokingham"
  },
  {
    id: 6310,
    name: "Wolverhampton"
  },
  {
    id: 6271,
    name: "Worcester"
  },
  {
    id: 6267,
    name: "Worthing"
  },
  {
    id: 6398,
    name: "Wrexham"
  },
  {
    id: 6272,
    name: "Wychavon"
  },
  {
    id: 6190,
    name: "Wyre"
  },
  {
    id: 6273,
    name: "Wyre Forest"
  },
  {
    id: 6054,
    name: "York"
  }
];

const industriesOptions = [
  {
    id: 1, 
    name: "Select all"
  },
  {
    id: 841,
    name: "Added By Super"
  },
  {
    id: 803,
    name: "Administrative and Support Service Activities"
  },
  {
    id: 807,
    name: "Arts, Entertainment and Recreation"
  },
  {
    id: 795,
    name: "Construction"
  },
  {
    id: 842,
    name: "Editing"
  },
  {
    id: 805,
    name: "Education"
  },
  {
    id: 793,
    name: "Electricity, Gas, Steam and Air Conditioning Supply"
  },
  {
    id: 800,
    name: "Financial and Insurance Activities"
  },
  {
    id: 806,
    name: "Human Health and Social Work Activities"
  },
  {
    id: 789,
    name: "Industry Sector"
  },
  {
    id: 799,
    name: "Information and Communication"
  },
  {
    id: 792,
    name: "Manufacturing"
  },
  {
    id: 791,
    name: "Mining and Quarrying",
  },
  {
    id: 808,
    name: "Other Service Activities"
  },
  {
    id: 802,
    name: "Professional, Scientific and Technical Activities"
  },
  {
    id: 804,
    name: "Public Administration and Defence; Compulsory Social Security"
  },
  {
    id: 801,
    name: "Real Estate Activities"
  },
  {
    id: 797,
    name: "Transportation and Storage"
  },
  {
    id: 794,
    name: "Water Supply; Sewerage, Waste Management and Remediation Activities",
  },
  {
    id: 796,
    name: "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles"
  }
];
// Customizable Area End