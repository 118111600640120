export type StylesKeys =
  | 'badge'
  | 'controls'
  | 'navigation'
  | 'button'
  | 'backButton'
  | 'arrow'
  | 'dot'
  | 'close'
  | 'svg'

export type StylesObj = {
  [key in StylesKeys]?: StyleFn
}

export type StyleFn = (
  props: { [key: string]: any },
  state?: { [key: string]: any }
) => React.CSSProperties

export type Styles = {
  badge: StyleFn
  controls: StyleFn
  navigation: StyleFn
  button: StyleFn
  backButton: StyleFn
  arrow: StyleFn
  dot: StyleFn
  close: StyleFn
  svg: StyleFn
}

export type StyleKey = keyof Styles

export const defaultStyles: Styles = {
  badge: () => ({
    position: 'absolute',
    fontFamily: 'monospace',
    background: 'var(--cedreactour-accent,#007aff)',
    height: '1.875em',
    lineHeight: 2,
    paddingLeft: '0.8125em',
    paddingRight: '0.8125em',
    fontSize: '1em',
    borderRadius: '1.625em',
    color: 'white',
    textAlign: 'center',
    boxShadow: '0 0.25em 0.5em rgba(0, 0, 0, 0.3)',
    top: '-0.8125em',
    left: '-0.8125em',
  }),
  controls: () => ({
    display: 'flex',
    marginTop: 8,
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  navigation: () => ({
    counterReset: 'dot',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
  button: ({ disabled }) => ({
    display: 'block',
    cursor: disabled ? 'not-allowed' : 'pointer',
    background: disabled ? 'rgb(229, 229, 229)' : 'transparent',
    border: '0.0625rem solid #8c9196',
    borderRadius: '0.25em',
    color: '#202223',
    fontSize: '0.875rem',
    fontWeight: 500,
    left: '100%',
    lineHeight: '1rem',
    margin: '8px',
    marginLeft: 'auto',
    minHeight: '1.75rem',
    minWidth: '2.25rem',
    outline: 'none',
    padding: '0.375rem 0.75rem',
  }),
  backButton: () => ({
    marginRight: 'auto',
    marginLeft: 8
  }),
  arrow: ({ disabled }) => ({
    color: disabled ? '#caccce' : '#646464',
    width: 16,
    height: 12,
    flex: '0 0 16px',
  }),
  dot: ({ current, disabled, showNumber }) => ({
    counterIncrement: 'dot',
    width: 8,
    height: 8,
    border: current ? '0' : '1px solid #caccce',
    borderRadius: '100%',
    padding: 0,
    display: 'block',
    margin: 4,
    transition: 'opacity 0.3s, transform 0.3s',
    cursor: disabled ? 'not-allowed' : 'pointer',
    transform: `scale(${current ? 1.25 : 1})`,
    color: current ? 'var(--cedreactour-accent, #007aff)' : '#caccce',
    background: current ? 'var(--cedreactour-accent, #007aff)' : 'none',
  }),
  close: ({ disabled }) => ({
    position: 'absolute',
    top: -9,
    right: -9,
    width: 9,
    height: 9,
    border: 'none',
    background: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    outline: 'none',
    padding: '9px',
    '--rt-close-btn': disabled ? '#caccce' : '#5e5e5e',
    '--rt-close-btn-disabled': disabled ? '#caccce' : '#000',
    '&:hover': {
      backgroundColor: 'red',
      color: 'black'
    },
  }),
  svg: () => ({
    display: 'block',
  }),
}

export type getStylesType = (key: StylesKeys, extra?: any) => {}

export function stylesMatcher(styles: StylesObj) {
  return (key: StyleKey, state: {}): {} => {
    const base = defaultStyles[key](state)
    const custom = styles[key]
    return custom ? custom(base, state) : base
  }
}
