import React from "react";
// Customizable Area Start
import { Box, Button, Container, Dialog, Divider, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, styled, TextField, Typography } from "@material-ui/core";
import { amaxLogo, fluent, masterCardLogo, visaLogo } from "./assets";
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Card,
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInput = (
    label: string,
    name: string,
    placeholder: string,
    value: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    error: string
  ) => {
    const { checkPayClick } = this.state;
    return (
      <Box style={webStyle.inputMainBox}>
        <Typography style={webStyle.inputLabelText}>{label}</Typography>
        <CustomTextField
          data-test-id={`${name}InputTestId`}
          type="text"
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          error={checkPayClick && error.length !== 0}
        />
      </Box>
    );
  };

  renderCardInputIcon = () => {
    return (
      <Box style={webStyle.cardLogoMainBox}>
        <Box style={webStyle.cardLogoBox}>
          <img
            style={{ width: "20px" }}
            src={masterCardLogo}
            alt="masterCardLogo"
          />
        </Box>
        <Box style={webStyle.cardLogoBox}>
          <img style={{ height: "44px" }} src={visaLogo} alt="visaLogo" />
        </Box>
        <Box style={webStyle.cardLogoBox}>
          <img style={webStyle.cardLogo} src={amaxLogo} alt="amaxLogo" />
        </Box>
      </Box>
    );
  };

  renderAddPaymentMethod = () => {
    const {
      selectedPlan,
      cardHolderName,
      formatedCardNumber,
      expDate,
      maskedCvc,
      strtAddOne,
      strtAddTwo,
      aptUnit,
      city,
      postCode,
      countryAlt,
      errCardHolderName,
      errCardNumber,
      errExpDate,
      errCvc,
      errStrtAddOne,
      errCity,
      errSelectCountry,
      errPostCode,
      errCountryAlt,
      checkPayClick
    } = this.state;
    return (
      <Box style={webStyle.mainAddPayMethoBox}>
        <Grid container style={webStyle.mainAddPayMethoWrapper}>
          <Grid item sm={12}>
            <Box style={webStyle.addPaymentLeftBox}>
              <Typography style={webStyle.enterPaymentDetailText}>
                Enter your payment details
              </Typography>
              <Divider style={{ margin: "15px 0" }} />
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  {this.renderInput(
                    "Cardholder Name",
                    "cardHolderName",
                    "Enter your name",
                    cardHolderName,
                    this.handleInputChange("cardHolderName"),
                    errCardHolderName
                  )}
                </Grid>
                <Grid item sm={6}>
                  <Box style={webStyle.inputMainBox}>
                    <Typography style={webStyle.inputLabelText}>
                      Card Number
                    </Typography>
                    <CustomTextField
                      data-test-id="cardNumberInputTestId"
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter your card number"
                      value={formatedCardNumber}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleCardNumberChange(event)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {this.renderCardInputIcon()}
                          </InputAdornment>
                        )
                      }}
                      error={checkPayClick && errCardNumber.length !== 0}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  {this.renderInput(
                    "Expiry Date",
                    "expDate",
                    "Enter expiry date",
                    expDate,
                    this.handleExpDateChange,
                    errExpDate
                  )}
                </Grid>
                <Grid item sm={6}>
                  {this.renderInput(
                    "CVC",
                    "maskedCvc",
                    "Enter CVC (Mastercard) /CVV (Visa)",
                    maskedCvc,
                    this.handleCvcChange,
                    errCvc
                  )}
                </Grid>
              </Grid>
              <Typography
                style={{
                  ...webStyle.enterPaymentDetailText,
                  marginTop: "35px"
                }}
              >
                Billing Address
              </Typography>
              <Divider style={{ margin: "15px 0" }} />
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  {this.renderInput(
                    "Street Address 1",
                    "strtAddOne",
                    "Enter street name",
                    strtAddOne,
                    this.handleInputChange("strtAddOne"),
                    errStrtAddOne
                  )}
                </Grid>
                <Grid item sm={6}>
                  {this.renderInput(
                    "Street Address 2 (optional)",
                    "strtAddTwo",
                    "Enter street name",
                    strtAddTwo,
                    this.handleInputChange("strtAddTwo"),
                    ""
                  )}
                </Grid>
                <Grid item sm={12}>
                  {this.renderInput(
                    "Apt, unit, suit, etc.(optional)",
                    "aptUnit",
                    "",
                    aptUnit,
                    this.handleInputChange("aptUnit"),
                    ""
                  )}
                </Grid>
                <Grid item sm={6}>
                  {this.renderInput(
                    "City",
                    "city",
                    "Enter city",
                    city,
                    this.handleInputChange("city"),
                    errCity
                  )}
                </Grid>
                <Grid item sm={6}>
                  <Box style={webStyle.inputMainBox}>
                    <Typography style={webStyle.inputLabelText}>
                      Country
                    </Typography>
                    <FormControl fullWidth>
                      <CustomInputLabel
                        shrink={false}
                        style={{
                          lineHeight: "0.5",
                          display: this.state.selectCountry ? "none" : "block",
                        }}
                      >
                        Select country
                      </CustomInputLabel>
                      <CustomSelect
                        data-test-id="selectCountryInputTestId"
                        fullWidth
                        variant="outlined"
                        value={this.state.selectCountry}
                        name={"selectCountry"}
                        onChange={this.handleSelectChange}
                        error={checkPayClick && errSelectCountry.length !== 0}
                      >
                        {[
                          "USA",
                          "India",
                          "Japan",
                          "China",
                          "United Kingdom"
                        ].map((item: string, index: number) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  {this.renderInput(
                    "Postcode",
                    "postCode",
                    "Enter your postcode",
                    postCode,
                    this.handlePostCodeChange,
                    errPostCode
                  )}
                </Grid>
                <Grid item sm={6}>
                  {this.renderInput(
                    "Country",
                    "countryAlt",
                    "United Kingdom",
                    countryAlt,
                    this.handleInputChange("countryAlt"),
                    errCountryAlt
                  )}
                </Grid>
              </Grid>
              <OkButton
                data-test-id="backButtonTestId"
                style={webStyle.backButton}
                onClick={this.handleBackClick}
              >
                {configJSON.back}
              </OkButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { billing_details } = this.state.currentPlanSubscription
    const card = [
      {
        title: "Advance",
        body: "Lorem ipsum dolor sit amet, conse ctetur adipiscing Features include Lorem ipsum dolor si ",
        price: "$42/month",
        subscribed: false
      },
      {
        title: "Advance",
        body: "Lorem ipsum dolor sit amet, conse ctetur adipiscing Features include Lorem ipsum dolor si ",
        price: "$32/month",
        subscribed: true
      },
      {
        title: "Advance",
        body: "Lorem ipsum dolor sit amet, conse ctetur adipiscing Features include Lorem ipsum dolor si ",
        price: "$42/month",
        subscribed: false
      },
    ]
    return (
      <MainContainer>
        <Box style={{ display: (this.state.changePayment == 1) ? "" : "none" }}>
          <Box style={webStyle.mainBox}>
            <Box style={webStyle.breadcrumbBox}>
              {/* <img src={dashArrow} /> */}
              <BreadcrumbActiveText>Change Subscription Type</BreadcrumbActiveText>
            </Box>
            <Box style={webStyle.payDetailBox}>
              <Box style={webStyle.currentPlanDetailBox}>
                <Box>
                  <Box style={webStyle.currentPlanTextBox}>
                    <CurrentPlanText>Current Plan</CurrentPlanText>
                  </Box>
                  <YearlySubText>Yearly Subscription</YearlySubText>
                  <Typography style={webStyle.planDescText}>
                    Ideal for businesses who need access to survey tool £3,000 + VAT
                    Billed Annually
                  </Typography>
                  <Typography>
                    Next Payment of £3,000 (+VAT 20%) on 11th May 2025
                  </Typography>
                </Box>
                <CancelButton data-test-id="cancel-btn" style={{ width: "250px", paddingTop: "23px" }} onClick={() => { this.setState({ isOpen: true }) }}>
                  <GradientText>Cancel</GradientText>
                </CancelButton>
              </Box>
              <Box style={webStyle.mainPaymentModeBox}>
                <Box style={{ flexGrow: 1 }}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      marginBottom: "8px",
                    }}
                  >
                    Your Billing Details
                  </Typography>
                  <Box style={{
                    display: (this.state.currentPlanSubscription?.billing_details?.card_number) ? "" : "none"
                  }}>
                    <Typography
                      style={{ color: "#333", marginBottom: "5px", fontWeight: 600 }}
                    >
                      Credit Card Number
                    </Typography>
                    <CardNumberInput
                      type="text"
                      variant="outlined"
                      value="xxxx xxxx xxxx 8087"
                    />
                  </Box>
                  <GradientText style={webStyle.changePayMethText} onClick={() => { this.setState({ changePayment: 2 }) }}>
                    Change Payment Method
                  </GradientText>
                </Box>
                <CancelButton style={{ width: "200px", paddingTop: "23px" }} onClick={() => { this.setState({ isOpenCardCancel: true }) }}>
                  <GradientText>Delete</GradientText>
                </CancelButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: 2,
            display: (this.state.changePayment == 2) ? "" : "none"
          }}
        >
          <Box className="card-title">
            <Typography variant="h6" gutterBottom>
              Billing →
            </Typography>
            <Typography
              variant="h6"
              style={{
                background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Change Subscription Type
            </Typography>
          </Box>

          <Box style={{ padding: "10px", display: "flex", gap: "5px" }} >
            {card.map((val: Card, index: number) => {
              return <Box key={index} className="profile">
                <Box className="subscriptionHeader">
                  <img src={fluent} alt="Logo" className="logo" />
                </Box>
                <Box className="subscriptionDetails">
                  <Typography variant="h6">
                    {val.title}
                  </Typography>
                  <Typography variant="body1">
                    {val.body}
                  </Typography>
                  <Typography variant="body1">
                    {val.price}
                  </Typography>
                  <Box className="buttonsContainer">
                    <button className="secondaryButton" data-test-id="curr-sub-btn" style={{ display: (val.subscribed) ? "" : "none" }} onClick={() => { this.setState({ isOpen: true }) }}>Current Subscription</button>
                    <button style={{ display: (val.subscribed) ? "none" : "" }} data-test-id="sub-btn" className="primaryButton" onClick={() => this.setState({ changePayment: 3 })}>Select</button>
                  </Box>
                </Box>
              </Box>
            })}
          </Box>
        </Box>

        <Box
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: 8,
            padding: 20,
            margin: "20px auto",
            display: (this.state.changePayment == 3) ? "" : "none"
          }}
        >
          <Box className="card-title">
            <Typography variant="h6" gutterBottom>
              Billing →
            </Typography>
            <Typography
              variant="h6"
              style={{
                background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Change Subscription Type
            </Typography>
          </Box>

          {this.renderAddPaymentMethod()}
        </Box>

        <Modaldialog className="modal" open={this.state.isOpen}>
          <div className="modalBody1">
            <div>
              <Typography style={{ marginBottom: "20px", fontWeight: 800 }} className="modalHeader">Are you sure you want to cancel?</Typography>
            </div>
            <div>
              <Typography style={{ fontWeight: 600 }} className="question">
                You will lose access to your subscription at the end of the current billing period.
              </Typography>
            </div>
            <div className="buttons">
              <Button className="primaryButton" data-test-id="cancelSubscription1" onClick={() => { this.canncelSubscriptionPlan() }}>Yes</Button>
              <Button data-test-id="modal-btn" onClick={() => { this.setState({ isOpen: false }) }} className="secondaryButton">No</Button>
            </div>
          </div>
        </Modaldialog>
        <Modaldialog className="modal" open={this.state.isOpenCardCancel}>
          <div className="modalBody">
            <div>
              <Typography style={{ marginBottom: "20px", fontWeight: "bold" }} className="modalHeader">Are you sure you want to delete this card?</Typography>
            </div>
            <div className="buttons">
              <Button className="primaryButton" data-test-id="cancelSubscription2" onClick={() => { this.canncelCardDetail() }}>Yes</Button>
              <Button data-test-id="modal-btn" onClick={() => { this.setState({ isOpenCardCancel: false }) }} className="secondaryButton">No</Button>
            </div>
          </div>
        </Modaldialog>
        <Box className="bottom-btn-box">
          <Button data-test-id="pay-btn" className="primaryButtonSave" style={{ display: (this.state.changePayment > 2) ? "" : "none" }} onClick={() => this.handlePayClick()}>Save Changes</Button>
        </Box>
      </MainContainer >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const GradientText = styled(Typography)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});
const BreadcrumbActiveText = styled(Typography)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 700,
  fontSize: "18px",
});

const OkButton = styled(Button)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  borderRadius: "10px",
  color: "#fff",
  fontWeight: 700,
  textTransform: "none",
});

const CustomInputLabel = styled(InputLabel)({
  fontSize: "16px",
  color: "#9da2a8",
  opacity: 1,
  paddingLeft: "14px",

  "&.Mui-focused": {
    display: "none",
    color: "#9da2a8",
  },
  "&.Mui-disabled": {
    WebkitTextFillColor: "#0F172A",
  },
});

const CancelButton = styled(Button)({
  border: "1px solid #1C386D",
  textTransform: "none",
  borderRadius: "10px",
  height: "44px",
  background: "white",
  "& .MuiTypography-root": {
    fontWeight: "bold",
  },
});

const CustomSelect = styled(Select)({
  color: "#0F172A",
  fontSize: "14px",
  fontWeight: 400,
  "& .MuiOutlinedInput-notchedOutline": {
    height: "61px",
    borderRadius: "8px",
    border: "1px solid #E0E0E0"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E0E0E0"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E0E0E0"
  },
  "&.Mui-disabled": {
    WebkitTextFillColor: "#0F172A",
    borderColor: "#E0E0E0",
    "& fieldset": {
      borderColor: "#E0E0E0 !important"
    }
  },
  "& .MuiSelect-outlined": {
    background: "transparent !important"
  },
  "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #f44336 !important"
  }
});

const CardNumberInput = styled(TextField)({
  height: "44px",
  width: "100%",
  maxWidth: "450px",
  "& .MuiOutlinedInput-root": {
    height: "40px",
    borderRadius: "10px",
    width: "100%",
    "& fieldset": {
      border: "1px solid #E0E0E0",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E0E0E0",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#E0E0E0",
    },
  },
  "& .MuiInputBase-input": {
    fontFamily: "Arial",
    fontSize: "16px",
  },
  "& input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset",
  },
});

const CurrentPlanText = styled(Typography)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  WebkitBackgroundClip: "text",
  display: "flex",
  alignContent: "center",
  WebkitTextFillColor: "transparent",
  fontSize: "18px",
  width: "100%",
});

const YearlySubText = styled(Typography)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: 700,
  fontSize: "22px",
  marginTop: "8px",
});

const MainContainer = styled(Container)({
  width: "100%",
  color: "#000000",
  padding: "20px",
  height: "unset",

  "& .current-profile": {
    borderRadius: "12px",
    border: "1px solid #E7E7E7",
    background: "#FFFFFF",
    width: "90%",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "center",
    marginBottom: "16px",
  },

  "& .pricing-profile": {
    borderRadius: "12px",
    border: "1px solid #E7E7E7",
    background: "#FFFFFF",
    width: "90%",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "center",
    marginBottom: "16px",
  },

  "& .profile": {
    borderRadius: "12px",
    border: "1px solid #E7E7E7",
    background: "#FFFFFF",
    width: "100%",
    maxWidth: "400px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "center",
    marginBottom: "16px",
  },

  "& .subscriptionHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },

  "& .logo": {
    width: "30px",
    height: "30px",
  },

  "& .currentPlan": {
    fontWeight: "bold",
    color: "#1C386D",
    fontSize: "14px",
  },

  "& h2": {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "8px",
  },

  "& p": {
    fontSize: "14px",
    color: "#666666",
    marginBottom: "16px",
  },

  "& .price": {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333333",
    marginBottom: "16px",
  },

  "& .buttonsContainer": {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "16px",
  },

  "& .secondaryButton": {
    backgroundColor: "#FFFFFF",
    color: "#1C386D",
    border: "1px solid #1C386D",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "120px",
  },

  "& .primaryButton": {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "120px",
  },
  "& .primaryButtonPayment": {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  "& .primaryButtonSave": {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "10px"
  },
  "& .cancelButton": {
    backgroundColor: "#F5F5F5",
    color: "#555",
    border: "1px solid #E7E7E7",
    borderRadius: "4px",
    padding: "8px 16px",
    cursor: "pointer",
  },

  "& .updateButton": {
    backgroundColor: "#202020",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "8px 16px",
    cursor: "pointer",
  },

  "& .billingDetails": {
    width: "100%",
    maxWidth: "400px",
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    border: "1px solid #E7E7E7",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },

  "& .inputContainer": {
    marginBottom: "16px",
  },

  "& .creditCardInput": {
    width: "100%",
    padding: "12px",
    border: "1px solid #E7E7E7",
    borderRadius: "4px",
    marginTop: "8px",
    boxSizing: "border-box",
  },
  "& .bottom-btn-box": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .card-title": {
    borderBottom: "1px solid #E7E7E7",
    padding: "10px",
    display: "flex"
  },
  "& .changePaymentButton": {
    width: "100%",
    backgroundColor: "#202020",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "12px 16px",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "16px",
  },
});

const Modaldialog = styled(Dialog)({
  borderRadius: '50px',
  color: "#000000",
  height: "unset",
  "& .MuiPaper-root": {
    borderRadius: "15px"
  },
  "& .modalBody": {
    borderRadius: '100px',
    background: '#FFF',
    padding: '20px',
    color: "#000000",
    width: "240px"
  },

  "& .MuiDialog-container": {
    height: "unset"
  },
  "& .modalBody1": {
    borderRadius: '100px',
    background: '#FFF',
    padding: '20px',
    color: "#000000",
    width: "500px",
  },
  "& .buttons": {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center'
  },
  "& .primaryButton": {
    padding: '18px 32px',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none' as const,
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    borderRadius: '10px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    flex: '1 1 0px',
    marginTop: '30px',
    fontFamily: 'aeonik-regular',
  },
  "& .secondaryButton": {
    alignItems: 'center',
    textTransform: 'none' as const,
    padding: '18px 32px',
    justifyContent: 'center',
    border: 'none',
    fontSize: '16px',
    fontWeight: 700,
    background: 'none',
    color: '#333',
    fontStyle: 'normal',
    lineHeight: 'normal',
    flex: '1 1 0px',
    fontFamily: 'aeonik-regular',
  },
})

const CustomTextField = styled(TextField)({
  height: "57px",
  width: "100%",
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#f44336 !important"
  },
  "& .MuiOutlinedInput-root": {
    height: "57px",
    borderRadius: "10px",
    width: "100%",
    color: "#202020",
    "& fieldset": {
      border: `1px solid #E0E0E0`,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E0E0E0",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#E0E0E0",
    },
  },
  "& .MuiInputBase-input": {
    fontFamily: "Arial",
    fontSize: "16px",
  },
  "& input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset",
  },
});

const webStyle = {
  mainBox: {
    width: "100%"
  },
  mainPaymentModeBox: {
    border: "1px solid #E7E7E7",
    borderRadius: "12px",
    padding: "20px 15px",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
  },
  modHeadingText: {
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center"
  } as React.CSSProperties,
  modalButtonBox: {
    marginTop: "22px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px"
  } as React.CSSProperties,
  changePayMethText: {
    textDecoration: "underline #1C386D",
    cursor: "pointer",
    width: "fit-content",
    marginTop: "12px",
    fontWeight: 600,
    fontSize: "14px",
  },
  modMainBox: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
  },
  modConfirmDeleteMainBox: {
    marginTop: "55px",
    padding: "33px",
    background: "white",
    borderRadius: "16px",
    height: "160px",
    width: "230px"
  },
  breadcrumbBox: {
    display: "flex",
    gap: "8px",
    padding: "10px",
    borderBottom: "1px solid #E7E7E7",
  },
  payDetailBox: {
    padding: "10px",
  },
  currentPlanDetailBox: {
    padding: "20px 15px",
    border: "1px solid #1C386D",
    borderRadius: "12px",
    background: "#EBECF1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  planDescText: {
    fontSize: "14px",
    color: "#626467",
    marginBottom: "6px",
  },
  currentPlanTextBox: {
    background: "white",
    width: "fit-content",
    padding: "5px",
    borderRadius: "6px",
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainTabBox: {
    display: "flex",
    margin: "40px auto",
    width: "fit-content",
    gap: "18px"
  },
  addPaymentLeftBox: {
    padding: "28px",
    borderRight: "1px solid #E1E1E1",
  },
  enterPaymentDetailText: {
    fontWeight: 700,
    fontSize: "20px",
  },
  mainAddPayMethoBox: {
    marginTop: "30px",
    padding: "0 30px",
  },
  inputMainBox: {
    marginTop: "10px",
  },
  retryButton: {
    marginTop: "20px",
    height: "48px"
  },
  goToLoginButton: {
    marginTop: "20px",
    height: "38px"
  },
  inputLabelText: {
    color: "#212529",
    fontWeight: 600,
    marginBottom: "8px",
  },
  cardLogoMainBox: {
    display: "flex",
    gap: "5px",
  },
  backButton: {
    marginTop: "23px",
    height: "54px",
    width: "180px"
  },
  succCheckRoundIcon: {
    fontSize: "30px",
    color: "#fff",
  },
  succMainBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  succMainInnerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "30px",
    padding: "26px",
    background: "white",
    borderRadius: "12px",
    width: "fit-content",
    boxSizing: "border-box",
  } as React.CSSProperties,
  selectedPlanName: {
    fontWeight: 700,
    fontSize: "25px",
    marginBottom: "8px",
  } as React.CSSProperties,
  selectedPlanDesc: {
    color: "#797979",
    lineHeight: "20px",
    fontSize: "16px",
    marginBottom: "20px",
  },
  selPlanText: {
    lineHeight: "18px",
    fontSize: "16px",
    fontWeight: 700,
  },
  selPlanPriceBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  selPlanVatBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  selPlanSubTotalBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  selPlanDivider: {
    margin: "12px 0 15px",
  },
  payButton: {
    marginTop: "15px",
    height: "48px",
    width: "100%",
  },
  cardLogoBox: {
    border: "1px solid #E1E1E1",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    width: "50px",
    height: "30px",
    overflow: "hidden",
    alignItems: "center",
  },
  cardLogo: {
    width: "24px",
    height: "20px",
  },
  mainAddPayMethoWrapper: {
    borderRadius: "12px",
    background: "white",
  },
  tabItemBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  tabText: {
    fontWeight: 700,
  },
  tabCountBox: {
    borderRadius: "25px",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  planMainBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    gap: "20px"
  },
  benefitTickBox: {
    background: "#ECF2FF",
    borderRadius: "25px",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0
  } as React.CSSProperties,
  startButton: {
    marginBottom: "25px",
  },
  checkRoundIcon: {
    fontSize: "16px",
    color: "#1C386D",
  },

};
// Customizable Area End
