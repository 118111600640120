import { Box, Typography, Popover } from "@material-ui/core";
import React, { Component, createRef } from "react";

const barTip = require("./barTip.svg");

const data = [
  { label: "Cum fugit a sunt, o.", users: 102 },
  { label: "Sapiente in aut vow.", users: 9000 },
  { label: "Esse labore laboree.", users: 900 },
  { label: "Magnam quia quos fi.", users: 100 },
  { label: "Exercitationem nont.", users: 600 },
  { label: "Ullam consequunturw.", users: 1999 },
  { label: "Quas commodi unde h.", users: 12000 },
  { label: "Quas commodi unde h.", users: 127243000 },
  { label: "Ullam consequunturw.", users: 1999 },
  { label: "Magnam quia quos fi.", users: 100 },
  { label: "Cum fugit a sunt, o.", users: 54655431 },
  { label: "Sapiente in aut vow.", users: 9000 },
  { label: "Esse labore laboree.", users: 900 },
  { label: "Magnam quia quos fi.", users: 100 },
  { label: "Exercitationem nont.", users: 600 },
  { label: "Ullam consequunturw.", users: 1999 }
];

type Props = {
  data: { 
    label: string,
    users: number
  }[]
};

interface States {
  leftBox: { width: number; height: number };
  rightBox: { width: number; height: number };
  showToolTip: number;
}

class VerticalBarChart extends Component<Props, States> {
  leftBoxRef = createRef<HTMLDivElement>();
  rightBoxRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      leftBox: {
        width: 0,
        height: 0,
      },
      rightBox: {
        width: 0,
        height: 0,
      },
      showToolTip: 0
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateBoxWidth);
    this.updateBoxWidth();
  }

  componentDidUpdate(_: Props, prevState: States): void {
    if (
      this.leftBoxRef.current &&
      (prevState.leftBox.width !==
        this.leftBoxRef.current.getBoundingClientRect().width ||
        prevState.leftBox.height !==
          this.leftBoxRef.current.getBoundingClientRect().height)
    ) {
      this.updateBoxWidth();
    }

    if (
      this.rightBoxRef.current &&
      (prevState.rightBox.width !==
        this.rightBoxRef.current.getBoundingClientRect().width ||
        prevState.rightBox.height !==
          this.rightBoxRef.current.getBoundingClientRect().height)
    ) {
      this.updateBoxWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateBoxWidth);
  }

  updateBoxWidth = () => {
    if (this.leftBoxRef.current) {
      const getWidth = this.leftBoxRef.current.getBoundingClientRect().width;
      const getHeight = this.leftBoxRef.current.getBoundingClientRect().height;
      this.setState({
        leftBox: { width: getWidth, height: getHeight },
      });
    }
    if (this.rightBoxRef.current) {
      const getRightWidth =
        this.rightBoxRef.current.getBoundingClientRect().width;
      const getRightHeight =
        this.rightBoxRef.current.getBoundingClientRect().height;
      this.setState({
        rightBox: { width: getRightWidth, height: getRightHeight },
      });
    }
  };

  getBarWidth = (getValue: number) => {
    const boxWidth = Math.floor(this.state.rightBox.width);
    const boxSegmentWidth = Math.floor((this.state.rightBox.width - 44) / 4);

    let width = 0;
    if (getValue <= 10) {
      width = 0;
    }
    if (getValue > 10 && getValue <= 100) {
      width = (boxSegmentWidth / 90) * (getValue - 10);
    }
    if (getValue > 100 && getValue <= 1000) {
      width = boxSegmentWidth + (boxSegmentWidth / 900) * (getValue - 100);
    }
    if (getValue > 1000 && getValue <= 10000) {
      width =
        boxSegmentWidth * 2 + (boxSegmentWidth / 9000) * (getValue - 1000);
    }
    if (getValue > 10000 && getValue <= 100000) {
      width =
        boxSegmentWidth * 3 + (boxSegmentWidth / 90000) * (getValue - 10000);
    }
    if (getValue > 1000000) {
      width = boxWidth - 10;
    }
    return width;
  };

  handleTooltipOpen = (index: number) => {
    this.setState({
      showToolTip: index + 1,
    });
  };

  handleTooltipClose = () => {
    this.setState({
      showToolTip: 0,
    });
  };

  render() {
    return (
      <Box style={webStyle.mainContainer}>
        <div ref={this.leftBoxRef} style={webStyle.leftBox}>
          {this.props.data.map((item, index) => (
            <Typography key={index} style={webStyle.yaxisLabelText}>
              {item.label}
            </Typography>
          ))}
        </div>
        <Box
          style={{
            ...webStyle.barBoxContainer,
            marginLeft: this.state.leftBox.width
          }}
        >
          {this.props.data.map((item, index) => (
            <Box
              style={webStyle.upperBarBox}
              onMouseEnter={() => this.handleTooltipOpen(index)}
              onMouseLeave={() => this.handleTooltipClose()}
            >
              <Box
                style={{
                  ...webStyle.barWrapper,
                  width: this.getBarWidth(item.users),
                }}
              >
                <Box key={index} style={webStyle.barBox}></Box>
                <Box style={webStyle.tipBox}>
                  <img src={barTip} alt="bar_tip" style={{ width: "29px" }} />
                </Box>
              </Box>
              {this.state.showToolTip === index + 1 && (
                <Typography style={webStyle.tooltipText}>
                  {item.users}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <div
          ref={this.rightBoxRef}
          style={{
            ...webStyle.rightBox,
            marginLeft: this.state.leftBox.width,
            height: this.state.leftBox.height
          }}
        >
          {["10+", "100+", "1k+", "10k+", "100k+"].map((item, index) => (
            <Box key={index}>
              <Box style={{ ...webStyle.lineBox }}></Box>
              <Typography style={webStyle.xaxisLabel}>{item}</Typography>
            </Box>
          ))}
        </div>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
        </style>
      </Box>
    );
  }
}

export default VerticalBarChart;

const webStyle: { [key: string]: React.CSSProperties } = {
  rightBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: "40px",
    fontFamily: "Raleway"
  },
  tipBox: {
    width: "0px",
    height: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "3px",
    boxSizing: "border-box"
  },
  barWrapper: {
    width: "40px",
    display: "flex",
    alignItems: "center",
    height: "10px"
  },
  upperBarBox: {
    height: "24px",
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
    width: "fit-content"
  },
  leftBox: {
    position: "absolute"
  },
  tooltipText: {
    marginLeft: "20px",
    color: "#202020",
    borderRadius: "6px",
    background: "#fff",
    fontSize: "14px",
    padding: "1px 6px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    maxWidth: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Raleway"
  },
  barBoxContainer: {
    position: "absolute"
  },
  barBox: {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    height: "10px",
    width: "100%",
  },
  lineBox: {
    minWidth: "40px",
    height: "100%",
    borderLeft: "1px dashed #B5B5B5"
  },
  mainContainer: {
    position: "relative",
    display: "flex",
    background: "#E4E8F2"
  },
  yaxisLabelText: {
    color: "#797979",
    fontWeight: 500,
    textAlign: "right",
    paddingRight: "20px",
    marginBottom: "18px",
    maxWidth: "180px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Raleway"
  },
  xaxisLabel: {
    color: "#797979",
    fontWeight: 500,
    marginTop: "20px",
    fontFamily: "Raleway"
  }
};