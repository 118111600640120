import React, { FunctionComponent } from 'react';

import Dialog from './Dialog.web'
import Input from './CustomInput.web';
import TextArea from './CustomTextArea.web';

import {
    Button,
    Typography,
    makeStyles,
    styled
} from "@material-ui/core";

interface Props {
    visible: boolean;
    surveyName: string;
    surveyDescription: string;
    surveyId: number | null;
    surveyNameChange: (e: any) => void;
    surveyDescriptionChange: (e: any) => void;
    createSurveyClick: () => void;
    editSurveyClick: () => void;
    closeDialog: () => void;
    errors: any;  
    loading: boolean;  
}
const useStyles = makeStyles({
    mainBox: {
        fontFamily: 'aeonik-regular',
        "& ::placeholder":{
            fontFamily: 'aeonik-regular',
        },
    }
  });
const SurveysTemplateDialog:FunctionComponent<Props> = ({ 
    visible, 
    surveyName, 
    surveyNameChange, 
    surveyDescription,
    surveyId,
    surveyDescriptionChange,
    createSurveyClick,
    editSurveyClick,
    closeDialog, 
    errors,
    loading
}) => {
    const classes = useStyles();
  return (
    <Dialog
        style={webStyle.dialog}
        visible={visible}
    >
        <DialogBody id='create-survey'>
            <div style={webStyle.headContainer}>
                <Typography style={webStyle.dialogHeader}>
                    {surveyId ? "Edit survey" : "Tell us about your survey"}
                </Typography>
            </div>
            <div style={webStyle.dialogContent} className={classes.mainBox}>
                <Typography style={webStyle.formTitle}>Survey name</Typography>
                <Input
                    value={surveyName}
                    style={webStyle.formInput}
                    onChange={surveyNameChange}
                    type="text"
                    error={errors.surveyName}
                    maxLength={64}
                    placeholder='Example: New customer registration'
                />
                <div style={webStyle.formArea}>
                    <Typography style={webStyle.formTitle}>Survey description</Typography>
                    <TextArea
                        value={surveyDescription}
                        onChange={surveyDescriptionChange}
                        error={errors.surveyDescription}
                        maxLength={512}
                        placeholder='Type something'
                    />
                </div>
                <div style={webStyle.buttonContainer}>
                    {
                        surveyId ? 
                        <Button style={webStyle.btn} onClick={editSurveyClick} disabled={loading}>Update</Button> :
                        <Button style={webStyle.btn} onClick={createSurveyClick} disabled={loading}>Create</Button>
                    }
                    <Button style={webStyle.btn2} onClick={closeDialog}>Cancel</Button>
                </div>
            </div>
        </DialogBody>
    </Dialog>
  )
}
const DialogBody = styled('div')({
    width: '540px',
    borderRadius: '16px',
    background: '#FFF',
    '@media (min-width:520px) and (max-width:630px)':{
        // width:'100%',
        width:'440px',
    },
    '@media(max-width:519px)':{
        width:'100%',
    },
})
const webStyle = {
    dialog: {
        borderRadius: '16px',
    },
    dialogBody: {
        width: '540px',
        borderRadius: '16px',
        background: '#FFF'
    },
    headContainer: {
        padding: '24px 0px 24px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom:'1px solid #ededed'
    },
    dialogHeader: {
        color: ' var(--text, #202020)',
        fontSize: '24px',
        fontStyle: 'normal',
        fontFamily: 'aeonik-bold',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    dialogContent: {
        padding: '16px 50px 32px 50px'
    },
    formTitle: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        color: 'rgba(51, 51, 51, 1)',
        fontFamily: 'aeonik-regular',
    },
    formInput: {
        marginBottom: '24px',
        fontFamily: 'aeonik-regular',
    },
    formArea: {
        display: 'flex',
        flexDirection: 'column' as const,
        width: '100%',
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    btn: {
        padding: '18px 32px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px',
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        fontSize: '16px',
        fontWeight: 700,
        color: 'rgba(255, 255, 255, 1)',
        lineHeight: 'normal',
        fontStyle: 'normal',
        flex: '1 1 0px',
        textTransform: 'unset' as const,
        minWidth: '190px',
        fontFamily: "aeonik-regular",
    },
    btn2: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        color: '#3D5685',
        background: 'none',
        marginTop: '20px',
        textTransform: 'unset' as const,
        minWidth: '190px',
        fontFamily: "aeonik-regular",
    }
};

export default SurveysTemplateDialog;