import React from 'react'
import { useTour } from './Context'
import { TourProps } from './type.d'

export default function withTour<P extends TourProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof TourProps>> {
  const ComponentWithTour = (props: Omit<P, keyof TourProps>) => {
    const tourProps = useTour();
    return <WrappedComponent {...(props as P)} {...tourProps} />
  }
  return ComponentWithTour
}