import React, { useEffect } from 'react'
import { StepType, useTour } from './Tour'
import { Box, Typography } from '@material-ui/core';
import { getCookie, saveToCookie, saveToCookieDashboard } from './ManageCookies';
import ProductTourDialog from './ProductTourDialoug';
import { setStorageData } from '../../../framework/src/Utilities';

export const sampleData = [
    { "x_axis_data": "Jan", "submission_count": 1800 },
    { "x_axis_data": "Feb", "submission_count": 1500 },
    { "x_axis_data": "Mar", "submission_count": 2000 },
    { "x_axis_data": "Apr", "submission_count": 2102 },
    { "x_axis_data": "May", "submission_count": 5000 },
    { "x_axis_data": "Jun", "submission_count": 4000 },
    { "x_axis_data": "Jul", "submission_count": 6000 },
    { "x_axis_data": "Aug", "submission_count": 12000 },
    { "x_axis_data": "Sept", "submission_count": 1900 },
    { "x_axis_data": "Oct", "submission_count": 4000 },
    { "x_axis_data": "Nov", "submission_count": 1300 },
    { "x_axis_data": "Dec", "submission_count": 1200 ,}
];

export const shortQuestion = {
    "responses": [
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna al",
            "timeStamp": "2025-03-13T12:19:41.042Z",
            "user": "anjali@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "timeStamp": "2025-03-13T12:20:36.792Z",
            "user": "apple@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            "timeStamp": "2025-03-13T12:21:39.270Z",
            "user": "taruna@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "timeStamp": "2025-03-13T12:22:56.044Z",
            "user": "book@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            "timeStamp": "2025-03-13T12:24:22.095Z",
            "user": "fan@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            "timeStamp": "2025-03-18T15:21:52.104Z",
            "user": "dia@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            "timeStamp": "2025-03-18T15:26:14.335Z",
            "user": "alex@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
            "timeStamp": "2025-03-18T15:30:15.798Z",
            "user": "bottle@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "timeStamp": "2025-03-18T15:34:14.075Z",
            "user": "john@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "timeStamp": "2025-03-18T15:41:36.023Z",
            "user": "aditya@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "timeStamp": "2025-03-18T15:43:47.255Z",
            "user": "artika@yopmail.com"
        },
        {
            "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "timeStamp": "2025-03-18T15:46:34.385Z",
            "user": "bulbul@yopmail.com"
        }
    ],
    "total_count": 12
}

export const sqampleSurveyName = {
    "message": "Please fill your form",
    "surveys": {
        "data": [
            {
                "id": "2028",
                "type": "survey",
                "attributes": {
                    "id": 2028,
                    "name": "Home",
                    "description": "Description for Survey 1",
                    "respondents": 0,
                    "start_date": "2025-02-25T00:00:00.000Z",
                    "end_date": "2025-03-31T05:00:00.000Z",
                    "status": "live",
                    "individual_user_group": 1476,
                    "draft": null,
                    "start_time": "00:00",
                    "end_time": "05:00",
                    "start": "new Date(2025, 1, 25, 00, 00)",
                    "end": "new Date(2025, 2, 31, 05, 00)",
                    "groups": [1421, 1419, 1418, 1406, 1476],
                    "user_avatar": "https://example.com/user1.png",
                    "user_first_name": "Aditya",
                    "user_last_name": "Singh",
                    "company": "Zelite"
                }
            },
            {
                "id": "2028",
                "type": "survey",
                "attributes": {
                    "id": 2028,
                    "name": "Survey 1",
                    "description": "Description for Survey 1",
                    "respondents": 0,
                    "start_date": "2025-02-25T00:00:00.000Z",
                    "end_date": "2025-03-31T05:00:00.000Z",
                    "status": "live",
                    "individual_user_group": 1476,
                    "draft": null,
                    "start_time": "00:00",
                    "end_time": "05:00",
                    "start": "new Date(2025, 1, 25, 00, 00)",
                    "end": "new Date(2025, 2, 31, 05, 00)",
                    "groups": [1421, 1419, 1418, 1406, 1476],
                    "user_avatar": "https://example.com/user1.png",
                    "user_first_name": "Aditya",
                    "user_last_name": "Singh",
                    "company": "Zelite"
                }
            },
            {
                "id": "2022",
                "type": "survey",
                "attributes": {
                    "id": 2022,
                    "name": "Survey 2",
                    "description": "Description for Survey 2",
                    "respondents": 0,
                    "start_date": "2025-02-20T23:00:00.000Z",
                    "end_date": "2025-03-28T17:30:00.000Z",
                    "status": "live",
                    "individual_user_group": 1470,
                    "draft": null,
                    "start_time": "23:00",
                    "end_time": "17:30",
                    "start": "new Date(2025, 1, 20, 23, 00)",
                    "end": "new Date(2025, 2, 28, 17, 30)",
                    "groups": [1421, 1419, 1470],
                    "user_avatar": "https://example.com/user2.png",
                    "user_first_name": "Aditya",
                    "user_last_name": "Singh",
                    "company": "Zelite"
                }
            },
            {
                "id": "2067",
                "type": "survey",
                "attributes": {
                    "id": 2067,
                    "name": "Survey 3",
                    "description": "Description for Survey 3",
                    "respondents": 0,
                    "start_date": "2025-03-07T00:00:00.000Z",
                    "end_date": "2025-03-07T00:00:00.000Z",
                    "status": "past",
                    "individual_user_group": 1515,
                    "draft": null,
                    "start_time": "02:00",
                    "end_time": "13:00",
                    "start": "new Date(2025, 2, 7, 02, 00)",
                    "end": "new Date(2025, 2, 7, 13, 00)",
                    "groups": [1421, 1515],
                    "user_avatar": "https://example.com/user3.png",
                    "user_first_name": "Aditya",
                    "user_last_name": "Singh",
                    "company": "Zelite"
                }
            },
            {
                "id": "2061",
                "type": "survey",
                "attributes": {
                    "id": 2061,
                    "name": "Survey 4",
                    "description": "Description for Survey 4",
                    "respondents": 0,
                    "start_date": "2025-02-28T00:00:00.000Z",
                    "end_date": "2025-02-28T12:00:00.000Z",
                    "status": "past",
                    "individual_user_group": 1509,
                    "draft": null,
                    "start_time": "00:00",
                    "end_time": "12:00",
                    "start": "new Date(2025, 1, 28, 00, 00)",
                    "end": "new Date(2025, 1, 28, 12, 00)",
                    "groups": [1421, 1509],
                    "user_avatar": "https://example.com/user4.png",
                    "user_first_name": "Aditya",
                    "user_last_name": "Singh",
                    "company": "Zelite"
                }
            },
            {
                "id": "2070",
                "type": "survey",
                "attributes": {
                    "id": 2070,
                    "name": "Survey 5",
                    "description": "Description for Survey 5",
                    "respondents": 0,
                    "start_date": "2025-03-15T00:00:00.000Z",
                    "end_date": "2025-04-10T12:00:00.000Z",
                    "status": "upcoming",
                    "individual_user_group": 1520,
                    "draft": null,
                    "start_time": "10:00",
                    "end_time": "18:00",
                    "start": "new Date(2025, 2, 15, 10, 00)",
                    "end": "new Date(2025, 3, 10, 18, 00)",
                    "groups": [1421, 1520],
                    "user_avatar": "https://example.com/user5.png",
                    "user_first_name": "Aditya",
                    "user_last_name": "Singh",
                    "company": "Zelite"
                }
            }
        ]
    }
}

export const sampleRes = {
    "overview": {
        "title": "Notification Survey",
        "duration": 36,
        "respondents": 5,
        "group_names": [],
        "graph_details": [
            {
                "x_axis_data": "13 Mar",
                "user_count": 5
            }
        ]
    },
    "sections_list": [
        {
            "questions": [
                {
                    "id": 11914,
                    "title": "Question Title",
                    "data": {
                        "type": "checkbox",
                        "analytics": [
                            {
                                "optionId": 20020,
                                "optionName": "Option 1",
                                "percentage": "20.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 20021,
                                "optionName": "Option 2",
                                "percentage": "40.0",
                                "number_of_respondents": 2
                            },
                            {
                                "optionId": 20022,
                                "optionName": "Option 3",
                                "percentage": "20.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 20023,
                                "optionName": "Option 4",
                                "percentage": "60.0",
                                "number_of_respondents": 3
                            },
                            {
                                "optionId": 20024,
                                "optionName": "Option 5",
                                "percentage": "40.0",
                                "number_of_respondents": 2
                            },
                            {
                                "optionId": 20025,
                                "optionName": "Option 6",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 20026,
                                "optionName": "Option 7",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 20027,
                                "optionName": "Option 8",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 5
                    }
                },
                {
                    "id": 11915,
                    "title": "Question Title",
                    "data": {
                        "type": "radio",
                        "analytics": [
                            {
                                "optionId": 20028,
                                "optionName": "Option 1",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 20029,
                                "optionName": "Option 2",
                                "percentage": "40.0",
                                "number_of_respondents": 2
                            },
                            {
                                "optionId": 20030,
                                "optionName": "Option 3",
                                "percentage": "40.0",
                                "number_of_respondents": 2
                            },
                            {
                                "optionId": 20031,
                                "optionName": "Option 4",
                                "percentage": "20.0",
                                "number_of_respondents": 1
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 5
                    }
                },
                {
                    "id": 11916,
                    "title": "Question Title",
                    "data": {
                        "type": "short-text",
                        "responses": [
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna al",
                                "timeStamp": "2025-03-13T12:19:41.042Z",
                                "user": "anjali@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                                "timeStamp": "2025-03-13T12:20:36.792Z",
                                "user": "apple@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
                                "timeStamp": "2025-03-13T12:21:39.270Z",
                                "user": "taruna@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                                "timeStamp": "2025-03-13T12:22:56.044Z",
                                "user": "book@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
                                "timeStamp": "2025-03-13T12:24:22.095Z",
                                "user": "fan@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
                                "timeStamp": "2025-03-18T15:21:52.104Z",
                                "user": "dia@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
                                "timeStamp": "2025-03-18T15:26:14.335Z",
                                "user": "alex@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
                                "timeStamp": "2025-03-18T15:30:15.798Z",
                                "user": "bottle@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                                "timeStamp": "2025-03-18T15:34:14.075Z",
                                "user": "john@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                                "timeStamp": "2025-03-18T15:41:36.023Z",
                                "user": "aditya@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                                "timeStamp": "2025-03-18T15:43:47.255Z",
                                "user": "artika@yopmail.com"
                            },
                            {
                                "data": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                                "timeStamp": "2025-03-18T15:46:34.385Z",
                                "user": "bulbul@yopmail.com"
                            }
                        ],
                        "analytics": [],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 5
                    }
                },
                {
                    "id": 11917,
                    "title": "Question Title",
                    "data": {
                        "type": "slider",
                        "analytics": {
                            "max_label": "Very Excited",
                            "max_value": 10,
                            "min_label": "Not Excited",
                            "min_value": 1,
                            "avg_value": 7.4,
                            "avgPercent": 71.11
                        },
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 5
                    }
                },
                {
                    "id": 11918,
                    "title": "Question Title",
                    "data": {
                        "type": "ranking",
                        "analytics": [
                            {
                                "option": {
                                    "id": 20032,
                                    "name": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                },
                                "score": 2.0,
                                "rank": 1,
                                "user_rank": 1
                            },
                            {
                                "option": {
                                    "id": 20034,
                                    "name": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                },
                                "score": 2.2,
                                "rank": 2,
                                "user_rank": 1
                            },
                            {
                                "option": {
                                    "id": 20035,
                                    "name": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                },
                                "score": 2.8,
                                "rank": 3,
                                "user_rank": 1
                            },
                            {
                                "option": {
                                    "id": 20033,
                                    "name": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                },
                                "score": 3.0,
                                "rank": 4,
                                "user_rank": 1
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 5
                    }
                },
                {
                    "id": 11919,
                    "title": "Question Title",
                    "data": {
                        "type": "date",
                        "analytics": [],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 5
                    }
                }
            ]
        }
    ]
}

const DashboardTour = ({ navigate }: { navigate: any }) => {
    const { setSteps, setIsOpen, setTourLoading, setCurrentStep, currentStep } = useTour();

    const steps: StepType[] = [
        {
            selector: "#main-dashboard",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        Here is all the analytics of a survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 2,
            disableScroll: true,
        },
        {
            selector: "#survey-tabs",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>From here you can switch between all the surveys</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "right",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#surver-questions",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Here are all the questions in a survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "right",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#overview-dashboard",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Here you can see basic details of the survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                setStorageData("dashboard", "1");
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For checkbox questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("2")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>

                        For multiple choice questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("4")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For text box and comment box questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("5")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For slider type questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("6")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For ranking type questions, you will see analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("7")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "400px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For date type questions, you will view analytics like this,
                        the graph shows result of each date</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("8")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "400px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For date type questions, you will view analytics like this, the graph shows results on monthly or daily basis</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookieDashboard("9");
                saveToCookie("Part eight completed")
                setIsOpen(false)
                location.pathname = '/AdminSurvey'
            }
        },

    ];

    useEffect(() => {
        const part = getCookie("productTour");
        if (part === "Part six completed") {
            setSteps?.(steps);
            setCurrentStep(0);
        }
    }, [])

    return <ProductTourDialog />
}

export default DashboardTour