import React, { Component } from "react";
import {
    Box,
    styled,
    Typography,
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider
} from "@material-ui/core";
import {
    Elements,
    ElementsConsumer,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import {
    loadStripe,
    Stripe,
    StripeElements,
    StripeElementChangeEvent
} from "@stripe/stripe-js";

export const configJSON = require("./config");

const stripePromise = loadStripe(configJSON.stripePublicKey);

interface SubscriptionPlan {
    id: number;
    title: string;
    sub_title: string;
    description: string[];
    duration: string;
    price: string;
    stripe_product_id: string | null;
    stripe_price_id: string | null;
    created_at: string;
    updated_at: string;
    billed: string | null;
};

interface PaymentBodyData {
    cardHolderName: string;
    strtAddOne: string;
    strtAddTwo: string;
    aptUnit: string;
    city: string;
    county: string;
    postCode: string;
    country: string;
    tokenId: string;
};

type Props = {
    selectedPlan: SubscriptionPlan;
    handleMakePayment: (bodyData: PaymentBodyData)=>void;
    handleBackClick: () => void;
};

interface State {
    selectCounty: string;
    cardHolderName: string;
    strtAddOne: string;
    strtAddTwo: string;
    aptUnit: string;
    city: string;
    postCode: string;
    country: string;
    errCardHolderName: string;
    errCardNumber: string;
    errExpDate: string;
    errCvc: string;
    errStrtAddOne: string;
    errCity: string;
    errSelectCounty: string;
    errPostCode: string;
    errCountryAlt: string;
    checkPayClick: boolean;
    apiError: string;
};

export default class SubscriptionPlanForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectCounty: "",
            cardHolderName: "",
            strtAddOne: "",
            strtAddTwo: "",
            aptUnit: "",
            city: "",
            postCode: "",
            country: "United Kingdom",
            errCardHolderName: "",
            errCardNumber: "Invalid Card Number",
            errExpDate: "Invalid Expiry Date",
            errCvc: "Invalid CVV",
            errStrtAddOne: "",
            errCity: "",
            errSelectCounty: "",
            errPostCode: "",
            errCountryAlt: "",
            checkPayClick: false,
            apiError: ""
        }
    }

    handleErrCheck = () => {
        const {
            selectCounty,
            cardHolderName,
            strtAddOne,
            city,
            postCode,
            country,
            errCardNumber,
            errExpDate,
            errCvc
        } = this.state;
    
        let errCardHolderName = "",
            errStrtAddOne = "",
            errCity = "",
            errSelectCounty = "",
            errPostCode = "",
            errCountryAlt = "";
        if (cardHolderName.length === 0) {
          errCardHolderName = "Invalid Card Holder Name";
        }
        if (strtAddOne.length === 0) {
            errStrtAddOne = "Invalid Street Address 1";
        }
        if (city.length === 0) {
            errCity = "Invalid City";
        }
        if (selectCounty.length === 0) {
            errSelectCounty = "Invalid Selected County";
        }
        if (postCode.length === 0 || postCode.length < 6) {
            errPostCode = "Invalid Postcode";
        }
        if (country.length === 0) {
            errCountryAlt = "Invalid Country";
        }
        let isError = {
            errCardHolderName,
            errCardNumber,
            errExpDate,
            errCvc,
            errStrtAddOne,
            errCity,
            errSelectCounty,
            errPostCode,
            errCountryAlt
        };
        this.setState({
            errCardHolderName,
            errStrtAddOne,
            errCity,
            errSelectCounty,
            errPostCode,
            errCountryAlt
        });
    
        return Object.values(isError).some((value) => value.length !== 0);
    };

    handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = event.target.value;
        let newValue = rawValue.replace(/\D/g, "");
        if (newValue.length > 7) {
            newValue = newValue.slice(0, 7);
        }
        this.setState(
            {
                postCode: newValue
            },
            () => this.handleErrCheck()
        );
      };

    handleInputChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [prop]: event.target.value } as unknown as Pick<
            State,
            keyof State
        >, () => this.handleErrCheck());
    };

    handleCardNumberInputChange = (
        event: StripeElementChangeEvent,
        inputField: string
    ) => {
        let errorMessage = "";
        const error = event.empty || !event.complete;
        if (inputField === "errCardNumber" && error) {
            errorMessage = "Invalid Card Number";
        } else if (inputField === "errExpDate" && error) {
            errorMessage = "Invalid Expiry Date";
        } else if (inputField === "errCvc" && error) {
            errorMessage = "Invalid CVV";
        }
        this.setState({
            [inputField]: errorMessage,
        } as unknown as Pick<State, keyof State>);
    };

    handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let { value } = event.target;
        if (typeof value === "string") {
          this.setState({
            ["selectCounty"]: value,
          } as unknown as Pick<State, keyof State>, () => this.handleErrCheck());
        }
    };

    handlePayClick = async(stripe: Stripe | null, elements: StripeElements | null) => {
        const { cardHolderName, city, strtAddOne, strtAddTwo, postCode } = this.state;
        this.setState({ checkPayClick: true });
        const isError = this.handleErrCheck();
        if (isError === false) {
            if(stripe && elements){
                const cardElement = elements.getElement(CardNumberElement);
                if( cardElement ){
                    const { paymentMethod } = await stripe.createPaymentMethod({
                        type: "card",
                        card: cardElement,
                        billing_details: {
                            name: cardHolderName,
                            address: {
                                city: city,
                                line1: strtAddOne,
                                line2: strtAddTwo,
                                postal_code: postCode
                            }
                        }
                    });
                    if(paymentMethod) {
                        const { cardHolderName, strtAddOne, strtAddTwo, aptUnit, city, selectCounty, postCode, country} = this.state;
                        const bodyData = {
                            cardHolderName,
                            strtAddOne,
                            strtAddTwo,
                            aptUnit,
                            city,
                            county: selectCounty,
                            postCode,
                            country,
                            tokenId: paymentMethod.id
                        }
                        this.props.handleMakePayment(bodyData);
                    }
              }
            }
        }
    };

    renderInput = (
        label: string,
        name: string,
        placeholder: string,
        value: string,
        handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
        error: string
    ) => {
        const { checkPayClick } = this.state;
        return (
            <Box style={webStyle.inputMainBox}>
                <Typography style={webStyle.inputLabelText}>{label}</Typography>
                <CustomTextField
                    data-test-id={`${name}InputTestId`}
                    type="text"
                    variant="outlined"
                    fullWidth
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    error={checkPayClick && error.length !== 0}
                />
            </Box>
        );
    };

    renderStripInput = (name: string, label: string, error: string) => {
        const { checkPayClick } = this.state;
        return (
            <Box style={webStyle.inputMainBox}>
            <Typography style={webStyle.inputLabelText}>{label}</Typography>
            <Box
                style={{
                ...webStyle.stripeInputBox,
                borderColor: checkPayClick && error ? "#f44336" : "#E0E0E0",
                }}
            >
                {name === "cardNumber" && (
                <CardNumberElement
                    options={{
                    style: webStyle.stripeInputText,
                    showIcon: true,
                    disableLink: true,
                    placeholder: "Enter your card number",
                    }}
                    onChange={(event) =>
                    this.handleCardNumberInputChange(event, "errCardNumber")
                    }
                />
                )}
                {name === "cvcNumber" && (
                <CardCvcElement
                    options={{
                    style: webStyle.stripeInputText,
                    placeholder: "Enter CVC (Mastercard) /CVV (Visa)",
                    }}
                    onChange={(event) =>
                    this.handleCardNumberInputChange(event, "errCvc")
                    }
                />
                )}
                {name === "expiryDate" && (
                <CardExpiryElement
                    options={{
                    style: webStyle.stripeInputText,
                    placeholder: "Enter expiry date",
                    }}
                    onChange={(event) =>
                    this.handleCardNumberInputChange(event, "errExpDate")
                    }
                />
                )}
            </Box>
            </Box>
        );
    };

    render() {
        const {
            strtAddOne,
            strtAddTwo,
            aptUnit,
            city,
            postCode,
            country,
            errCardHolderName,
            errCardNumber,
            errExpDate,
            errCvc,
            errStrtAddOne,
            errCity,
            errSelectCounty,
            errPostCode,
            errCountryAlt,
            checkPayClick,
            cardHolderName
        } = this.state;
        const { selectedPlan, handleBackClick } = this.props;
        return (
            <Elements stripe={stripePromise}>
                <ElementsConsumer
                    data-test-id="elementConsumerTestId"
                >
                    {({ stripe, elements }) => (
                        <Box style={webStyle.mainAddPayMethoBox}>
                            <Grid container style={webStyle.mainAddPayMethoWrapper}>
                                <Grid item sm={8}>
                                    <Box style={webStyle.addPaymentLeftBox}>
                                        <Typography style={webStyle.enterPaymentDetailText}>
                                            Enter your payment details
                                        </Typography>
                                        <Divider style={{ margin: "15px 0" }} />
                                        <Grid container spacing={2}>
                                            <Grid item sm={6}>
                                                {this.renderInput(
                                                    "Cardholder Name",
                                                    "cardHolderName",
                                                    "Enter your name",
                                                    cardHolderName,
                                                    this.handleInputChange("cardHolderName"),
                                                    errCardHolderName
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderStripInput(
                                                    "cardNumber",
                                                    "Card Number",
                                                    errCardNumber
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderStripInput(
                                                    "expiryDate",
                                                    "Expiry Date",
                                                    errExpDate
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderStripInput("cvcNumber", "CVC", errCvc)}
                                            </Grid>
                                        </Grid>
                                        <Typography
                                            style={{
                                                ...webStyle.enterPaymentDetailText,
                                                marginTop: "35px"
                                            }}
                                        >
                                            Billing Address
                                        </Typography>
                                        <Divider style={{ margin: "15px 0" }} />
                                        <Grid container spacing={2}>
                                            <Grid item sm={6}>
                                                {this.renderInput(
                                                    "Street Address 1",
                                                    "strtAddOne",
                                                    "Enter street name",
                                                    strtAddOne,
                                                    this.handleInputChange("strtAddOne"),
                                                    errStrtAddOne
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderInput(
                                                    "Street Address 2 (optional)",
                                                    "strtAddTwo",
                                                    "Enter street name",
                                                    strtAddTwo,
                                                    this.handleInputChange("strtAddTwo"),
                                                    ""
                                                )}
                                            </Grid>
                                            <Grid item sm={12}>
                                                {this.renderInput(
                                                    "Apt, unit, suit, etc.(optional)",
                                                    "aptUnit",
                                                    "",
                                                    aptUnit,
                                                    this.handleInputChange("aptUnit"),
                                                    ""
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderInput(
                                                    "City",
                                                    "city",
                                                    "Enter city",
                                                    city,
                                                    this.handleInputChange("city"),
                                                    errCity
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Box style={webStyle.inputMainBox}>
                                                    <Typography style={webStyle.inputLabelText}>
                                                        County
                                                    </Typography>
                                                    <FormControl fullWidth>
                                                        <CustomInputLabel
                                                            shrink={false}
                                                            style={{
                                                                lineHeight: "0.5",
                                                                display: this.state.selectCounty ? "none" : "block",
                                                            }}
                                                        >
                                                            Select county
                                                        </CustomInputLabel>
                                                        <CustomSelect
                                                            data-test-id="selectCountyInputTestId"
                                                            fullWidth
                                                            variant="outlined"
                                                            value={this.state.selectCounty}
                                                            name={"selectCounty"}
                                                            onChange={this.handleSelectChange}
                                                            error={checkPayClick && errSelectCounty.length !== 0}
                                                        >
                                                            {[
                                                                "1",
                                                                "2",
                                                                "3",
                                                                "4",
                                                                "5"
                                                            ].map((item: string, index: number) => (
                                                                <MenuItem key={index} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomSelect>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderInput(
                                                    "Postcode",
                                                    "postCode",
                                                    "Enter your postcode",
                                                    postCode,
                                                    this.handlePostCodeChange,
                                                    errPostCode
                                                )}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {this.renderInput(
                                                    "Country",
                                                    "country",
                                                    "Enter country",
                                                    country,
                                                    this.handleInputChange("country"),
                                                    errCountryAlt
                                                )}
                                            </Grid>
                                        </Grid>
                                        <OkButton
                                            data-test-id="backButtonTestId"
                                            style={webStyle.backButton}
                                            onClick={()=>handleBackClick()}
                                        >
                                            Back
                                        </OkButton>
                                    </Box>
                                </Grid>
                                <Grid item sm={4}>
                                    <Box style={{ padding: "28px" }}>
                                        <Typography style={webStyle.selectedPlanName}>
                                            {selectedPlan.title}
                                        </Typography>
                                        <Typography style={webStyle.selectedPlanDesc}>
                                            {selectedPlan.sub_title}
                                        </Typography>
                                        <Box style={webStyle.selPlanPriceBox}>
                                            <Typography style={webStyle.selPlanText}>
                                                Price for your plan
                                            </Typography>
                                            <Typography style={webStyle.selPlanText}>
                                                {`£${selectedPlan.price} /${selectedPlan.duration}`}
                                            </Typography>
                                        </Box>
                                        <Box style={webStyle.selPlanVatBox}>
                                            <Typography style={webStyle.selPlanText}>{`VAT (${0}%)`}</Typography>
                                            <Typography style={webStyle.selPlanText}>{`£${0}`}</Typography>
                                        </Box>
                                        <Divider style={webStyle.selPlanDivider} />
                                        <Box style={webStyle.selPlanSubTotalBox}>
                                            <GradientText style={{ fontSize: "20px" }}>
                                                Subtotal
                                            </GradientText>
                                            <GradientText style={{ fontWeight: 700, fontSize: "20px" }}>
                                                £{Number(selectedPlan.price)}
                                            </GradientText>
                                        </Box>
                                        <OkButton
                                            data-test-id="payButtonTestId"
                                            style={webStyle.payButton}
                                            onClick={() => this.handlePayClick(stripe, elements)}
                                        >
                                            Proceed to pay
                                        </OkButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>)
                    }
                </ElementsConsumer>
            </Elements>
        );
    }
}

const GradientText = styled(Typography)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
});

const CustomSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
        height: "61px",
        borderRadius: "8px",
        border: "1px solid #E0E0E0"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E0E0E0"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E0E0E0"
    },
    "&.Mui-disabled": {
      WebkitTextFillColor: "#0F172A",
      borderColor: "#E0E0E0",
      "& fieldset": {
        borderColor: "#E0E0E0 !important"
      }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    },
        "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f44336 !important"
    }
});

const CustomInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#9da2a8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
        display: "none",
        color: "#9da2a8"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const CustomTextField = styled(TextField)({
    height: "57px",
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#f44336 !important"
    },
    "& .MuiOutlinedInput-root": {
        height: "57px",
        borderRadius: "10px",
        width: "100%",
        color: "#202020",
        "& fieldset": {
            border: `1px solid #E0E0E0`
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E0E0E0",
            borderWidth: "1px"
        },
        "&:hover fieldset": {
            borderColor: "#E0E0E0"
        }
    },
    "& .MuiInputBase-input": {
        fontFamily: "Arial",
        fontSize: "16px"
    },
    "& input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
    }
});

const OkButton = styled(Button)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none"
});

const webStyle = {
    addPaymentLeftBox: {
        padding: "28px",
        borderRight: "1px solid #E1E1E1"
    },
    payButton: {
        marginTop: "15px",
        height: "48px",
        width: "100%"
    },
    enterPaymentDetailText: {
        fontWeight: 700,
        fontSize: "20px"
    },
    mainAddPayMethoBox: {
        marginTop: "30px",
        padding: "0 30px"
    },
    selectedPlanName: {
        fontWeight: 700,
        fontSize: "25px",
        marginBottom: "8px"
    } as React.CSSProperties,
    selPlanVatBox: {
        display: "flex",
        justifyContent: "space-between"
    },
    selPlanSubTotalBox: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px"
    },
    selPlanDivider: {
        margin: "12px 0 15px"
    },
    selectedPlanDesc: {
        color: "#797979",
        lineHeight: "20px",
        fontSize: "16px",
        marginBottom: "20px"
    },
    selPlanText: {
        lineHeight: "18px",
        fontSize: "16px",
        fontWeight: 700
    },
    selPlanPriceBox: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px"
    },
    stripeInputBox: {
        border: `1px solid #E0E0E0`,
        height: "57px",
        borderRadius: "10px",
        alignContent: "center",
        padding: "0 14px"
    },
    stripeInputText: {
        base: {
          fontSize: "16px",
          color: "#000",
          "::placeholder": { color: "#9da2a8" }
        }
    },
    mainAddPayMethoWrapper: {
        borderRadius: "12px",
        background: "white"
    },
    backButton: {
        marginTop: "23px",
        height: "54px",
        width: "180px"
    },
    inputMainBox: {
        marginTop: "10px"
    },
    inputLabelText: {
        color: "#212529",
        fontWeight: 600,
        marginBottom: "8px"
    },
}