Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Startexports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContextTagging";
exports.labelBodyText = "ContextTagging Body";
exports.selectUserTagsApiEndPoint="bx_block_tags/tags/"
exports.selectGroupsTagsApiEndPoint="bx_block_groups/groups"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End