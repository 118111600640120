import React, { FunctionComponent } from 'react';

import {
    Typography,
    Button,
} from "@material-ui/core";

import {
    HeaderSVG,
    SignOutSVG,
    QuestionMark,
    SubscriptionSVG
} from "./Checkbox";
import Dialog from '../../components/src/Dialog.web';

interface Props {
    activeItem: number | null | undefined;
    selectedId: number;
    signOutConfirmationVisible: boolean;
    onItemHover: (data: any) => void;
    onMouseLeave: () => void;
    onItemClick: (data: any) => void;
    onClose?: () => void;
    onSignOutClick?: () => void;
}

const UserSettingsSider: FunctionComponent<Props> = ({ activeItem, selectedId, onItemHover, onMouseLeave, onItemClick, onSignOutClick, signOutConfirmationVisible, onClose }) => {

    const items = [
        { id: 1, title: 'Settings', icon: HeaderSVG },
        { id: 2, title: 'Change password', icon: QuestionMark },
        { id: 3, title: 'Log out', icon: SignOutSVG },
    ];

    const handleClose = () => {
        onClose && onClose();
    };
    return (
        <>
            {items?.map((item: any, ind: number) => {
                const active = activeItem === item.id;
                const isSelected = item.id === 3 ? false : selectedId === item.id;
                const Icon = item.icon;
                

                return (
                    <div key={ind} style={{ ...webStyle.separator, ...(ind === items.length - 1 ? { border: 'none' } : {}) }}>
                        <div
                            style={webStyle.item}
                            key={ind}
                            onMouseEnter={(e) => onItemHover(item.id)}
                            onMouseLeave={() => onMouseLeave()}
                            onClick={() => onItemClick(item.id)}
                        >
                            <div style={{
                                ...webStyle.imageContainer,
                                ...(((active || isSelected) && item.id ===selectedId) ?
                                    { background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)' } : {})
                            }}>
                                <Icon
                                    key={item.id}
                                    selected={active || isSelected}
                                />
                            </div>
                            <Typography style={{
                                ...webStyle.text, ...((active || isSelected) ? {
                                    fontWeight: 700,
                                    color: '#0F172A'
                                } : {})
                            }}>{item?.title}</Typography>
                            <div style={webStyle.spacer} />
                            <div
                                style={{
                                    ...webStyle.active,
                                    ...(((active || isSelected) && item.id ===selectedId ) ?
                                        { background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))' } : {}
                                    )
                                }}
                            />
                        </div>
                    </div>
                )
            })}
            <Dialog style={webStyle.modal} visible={signOutConfirmationVisible}>
                <div style={webStyle.modalBody}>
                    <div>
                        <p style={webStyle.modalHeader}>Log out</p>
                    </div>
                    <div>
                        <p style={webStyle.question}>
                            Are you sure you want to quit the application?
                        </p>
                    </div>
                    <div style={webStyle.buttons}>
                        <Button onClick={onSignOutClick} style={webStyle.primaryButton}>Log out</Button>
                        <Button onClick={() => handleClose()} style={webStyle.secondaryButton}>Close</Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
};

const webStyle = {
    separator: {
        padding: '16px 0px 16px 8px',
        borderBottom: '1px solid #DEDEDE'
    },
    item: {
        display: 'flex',
        padding: '8px 0px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    imageContainer: {
        borderRadius: '8px',
        background: 'rgba(191, 202, 228, 0.30)',
        display: 'flex',
        width: '56px',
        height: '56px',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px'
    },
    text: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontFamily: 'aeonik-bold',
        fontWeight: 700,
        lineHeight: 'normal',
        color: 'rgba(121, 121, 121, 1)'
    },
    spacer: {
        display: 'flex',
        flex: '1 1 0px'
    },
    active: {
        width: '4px',
        borderRadius: '5px 0px 0px 5px',
        height: '56px',
    },
    modal: {
        borderRadius: '16px',
    },
    modalBody: {
        borderRadius: '16px',
        background: '#FFF',
        padding: '40px'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    primaryButton: {
        padding: '18px 32px',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none' as const,
        background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
        borderRadius: '10px',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: 'normal',
        fontWeight: 700,
        color: 'rgba(255, 255, 255, 1)',
        flex: '1 1 0px',
        marginTop: '30px',
        fontFamily: 'aeonik-regular',
    },
    secondaryButton: {
        alignItems: 'center',
        textTransform: 'none' as const,
        padding: '18px 32px',
        justifyContent: 'center',
        border: 'none',
        fontSize: '16px',
        fontWeight: 700,
        background: 'none',
        color: '#333',
        fontStyle: 'normal',
        lineHeight: 'normal',
        flex: '1 1 0px',
        fontFamily: 'aeonik-regular',
    },
    question: {
        textAlign: 'center' as const,
        fontStyle: 'normal',
        color: '#202020',
        fontWeight: 600,
        lineHeight: '20px',
        fontSize: '16px',
        fontFamily: 'aeonik-bold'
    },
    modalHeader: {
        color: 'black',
        textAlign: 'center' as const,
        fontSize: '20px',
        fontFamily: 'aeonik-bold',
        fontWeight: 700,
        lineHeight: '20px'
    }
};

export default UserSettingsSider;
