// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  test: string;
  personName: any;
  email: string;
  createUserModal: boolean;
  selectFields: [];
  getPastSurveysMessageId: any;
  rowsPerPage: number;
  page: number;
  group: [];
  userId: number;
  userGroups: number[];
  errorMessage: string;
  error: boolean;
  continueModal: boolean;
  uploadModal: boolean;
  uploadSuccessModal: boolean;
  uploadFailModal: boolean;
  particalUploadModal: boolean;
  uploadFailError: string;
  visible: boolean;
  surveys: any;
  surveyName: string;
  groupList: any;
  groupError: boolean;
  userList: any;
  userCount: number;
  adminDetails: any;
  displayedUser: [];
  createUserSuccess: boolean;
  groupId: number;
  bulkUploadResponseArray: string[];
  fileSizeErrorModal: boolean,
  successMessage: string,
  uploadCancelState: boolean,
  selectedFilter: string;
}
interface SS {
  id: any;
}

export default class TagsUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserListDetailsCallId: string = "";
  getGroupListDetailsCallId: string = "";
  selectUserCallId = "";
  removeUserCallId = "";
  groupAddremoveUserCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      test: "",
      createUserModal: false,
      selectFields: [],
      getPastSurveysMessageId: "",
      personName: ["Group Name 1"],
      rowsPerPage: 20,
      page: 1,
      email: "",
      group: [],
      userId: 0,
      userGroups: [],
      errorMessage: "",
      error: false,
      continueModal: false,
      uploadModal: false,
      uploadSuccessModal: false,
      particalUploadModal: false,
      uploadFailModal: false,
      uploadFailError: "",
      visible: false,
      surveys: [],
      surveyName: "",
      groupList: [],
      groupError: false,
      userList: [],
      userCount: 0,
      adminDetails: JSON.parse(localStorage.getItem("user") || '{}'),
      displayedUser: [],
      createUserSuccess: false,
      groupId: 0,
      bulkUploadResponseArray: [],
      fileSizeErrorModal: false,
      successMessage: '',
      uploadCancelState: false,
      selectedFilter: "show_users"
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (
      apiRequestCallId === this.getUserListDetailsCallId &&
      responseJson
    ) {
      this.getUserDetailsReveive(responseJson);
    } else if (apiRequestCallId === this.selectUserCallId && responseJson) {
      this.handleSelectUserReceive(responseJson);
    } else if (apiRequestCallId === this.removeUserCallId && responseJson) {
      this.handleSelectUserReceive(responseJson);
    } else if (apiRequestCallId === this.getGroupListDetailsCallId && responseJson) {
      this.getUserDetailsReveive(responseJson);
    } else if (apiRequestCallId === this.groupAddremoveUserCallId && responseJson) {
      this.handleSelectGroupReceive(responseJson);
    }

    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    this.getUserDetails()
  }
  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.selectedFilter !== this.state.selectedFilter && this.state.selectedFilter === "show_users") {
      this.getUserDetails()
    }
    else if (prevState.selectedFilter !== this.state.selectedFilter && this.state.selectedFilter === "show_groups") {
      this.getGroupDetails()
    }
  }

  getUserDetails = () => {
    const token = localStorage.getItem("token");

    const urls = window.location.href;
    const tagId = urls.split('/').pop();
    const numerictagId = Number(tagId);
    this.setState({ groupId: numerictagId });

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_user/users/group_users_list?id=${numerictagId}&per_page=${this.state.rowsPerPage}&page=${this.state.page}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getUserListDetailsCallId = requestMessages.messageId;
    runEngine.sendMessage(requestMessages.id, requestMessages);
  };

  getGroupDetails = () => {

    const authTokens = localStorage.getItem("token");
    const topurl = window.location.href;
    const groupId = topurl.split('/').pop();

    const numericGroupId = Number(groupId);
    this.setState({ groupId: numericGroupId });

    const head = {
      "Content-Type": configJSON.validationApiContentType,
      token: authTokens,
    };
    const requestMessageapi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageapi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(head)
    );
    requestMessageapi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.selectGroupsTagsApiEndPoint}`
    );
    requestMessageapi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getGroupListDetailsCallId = requestMessageapi.messageId;
    runEngine.sendMessage(requestMessageapi.id, requestMessageapi);
  };

  handleFilterChange = (event: { target: { value: any; }; }) => {
    this.setState({ selectedFilter: event.target.value });
  };

  getUserDetailsReveive = (responseJson: any) => {
    if (responseJson?.users) {
      this.setState({
        userList: responseJson.users,
        userCount: responseJson.total_count,
      });
    } else if (responseJson?.groups) {
      this.setState({
        userList: responseJson.groups,
        userCount: responseJson.total_count,
      });
    }

  };

  handleUserPagination = (e: any, val: any) => {
    this.setState({ page: val }, this.getUserDetails);
  };

  handleSnackbarClose =
    () => {
      this.setState({ error: false, errorMessage: "" });
    };

  handleSelectUsersForGroup =
    (groupId: number) => {
      this.props.navigation.navigate("GroupUsers", { groupId });
    };


  selectUserForTag =
    (userId: number) => {
      const authToken = localStorage.getItem("token");

      const url = window.location.href;
      const groupId = url.split('/').pop();
      const numericGroupId = Number(groupId);
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken,
      };

      const jsonBody = {
        "user_ids": [userId]
      };

      const endPoint = `${configJSON.selectUserTagsApiEndPoint}${numericGroupId}/add_users`;

      const requestMessagesapi = new Message(getName(MessageEnum.RestAPIRequestMessage));
      requestMessagesapi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessagesapi.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
      requestMessagesapi.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessagesapi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(jsonBody)
      );
      this.selectUserCallId = requestMessagesapi.messageId;

      runEngine.sendMessage(requestMessagesapi.id, requestMessagesapi);
    };

  removeUserForTag = (userId: number) => {
    const authToken = localStorage.getItem("token");

    const url = window.location.href;
    const groupId = url.split('/').pop();
    const numericGroupId = Number(groupId);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    const jsonBody = {
      "user_ids": [userId]
    };

    const endPoint = `${configJSON.selectUserTagsApiEndPoint}${numericGroupId}/remove_users`;

    const requestforMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestforMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestforMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestforMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestforMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(jsonBody)
    );
    this.selectUserCallId = requestforMessage.messageId;

    runEngine.sendMessage(requestforMessage.id, requestforMessage);
  };

  handleSelectUserReceive = (responseJson: any) => {
    if (responseJson.errors) {
      return this.setState({
        error: true,
        errorMessage: responseJson.errors[0],
      });
    }
    this.getUserDetails();
  };

  handleSelectGroupReceive = (responseJson: any) => {
    if (responseJson.errors) {
      return this.setState({
        error: true,
        errorMessage: responseJson.errors[0],
      });
    }
    this.getGroupDetails();
  };

  selectGroupForTag = (userId: number) => {
    const authToken = localStorage.getItem("token");

    const url = window.location.href;
    const groupId = url.split('/').pop();
    const numericGroupId = Number(groupId);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    const jsonBody = {
      "group_ids": [userId]
    };

    const endPoint = `${configJSON.selectUserTagsApiEndPoint}${numericGroupId}/add_groups`;

    const requestforMessages = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestforMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestforMessages.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestforMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestforMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(jsonBody)
    );
    this.groupAddremoveUserCallId = requestforMessages.messageId;

    runEngine.sendMessage(requestforMessages.id, requestforMessages);
  };

  removeGroupFromTag = (userId: number) => {
    const authToken = localStorage.getItem("token");

    const url = window.location.href;
    const groupId = url.split('/').pop();
    const numericGroupId = Number(groupId);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    const jsonBody = {
      "group_ids": [userId]
    };

    const endPoint = `${configJSON.selectUserTagsApiEndPoint}${numericGroupId}/remove_groups`;

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(jsonBody)
    );
    this.groupAddremoveUserCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

// Customizable Area End
