import React from 'react'
import { StylesObj, stylesMatcher } from '../styles'

const Close: React.FC<CloseProps> = ({
  styles = {},
  onClick,
  hideActions,
  ...props
}) => {
  const getStyles = stylesMatcher(styles)
  if (hideActions) return <></>
  return (
    <button
      className="cedreactour__close-button"
      style={{
        ...getStyles('button', {}),
        ...getStyles('close', { hideActions }),
      }}
      onClick={onClick}
      {...props}
    >
      <svg viewBox="0 0 20 20" fill='#5c5f62' style={{
        ...getStyles('svg', {}),
      }} focusable="false" aria-hidden="true"><path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path></svg>
    </button>
  )
}

export type CloseProps = {
  styles?: StylesObj
  onClick?: () => void
  hideActions?: boolean
}

export default Close
