import React, { Dispatch, useEffect } from 'react'
import { KeyboardParts, ClickProps, KeyboardHandler } from './type.d'

const Keyboard: React.FC<KeyboardProps> = ({
  disableKeyboardNavigation,
  setCurrentStep,
  currentStep,
  setIsOpen,
  setLoading,
  stepsLength,
  hideActions,
  rtl,
  clickProps,
  keyboardHandler,
  onNext,
  onBack,
  onClose
}) => {
  function keyDownHandler(e: any) {
    e.stopPropagation()

    let isEscDisabled, isRightDisabled, isLeftDisabled
    if (disableKeyboardNavigation === true) {
      return
    }
    isEscDisabled = (disableKeyboardNavigation && disableKeyboardNavigation.includes('esc')) || hideActions?.close
    isRightDisabled = (disableKeyboardNavigation && disableKeyboardNavigation.includes('right')) || hideActions?.next
    isLeftDisabled = (disableKeyboardNavigation && disableKeyboardNavigation.includes('left')) || hideActions?.back

    function next() {
      clickProps?.steps?.[currentStep]?.OnNext?.()
      onNext?.({ currentStep: Math.min(currentStep + 1, stepsLength - 1) })
      setCurrentStep(Math.min(currentStep + 1, stepsLength - 1))
    }

    function prev() {
      clickProps?.steps?.[currentStep]?.OnBack?.()
      onBack?.({ currentStep: Math.max(currentStep - 1, 0) })
      setCurrentStep(Math.max(currentStep - 1, 0))
    }
    if (keyboardHandler && typeof keyboardHandler === 'function') {
      keyboardHandler(e, clickProps, {
        isEscDisabled,
        isRightDisabled,
        isLeftDisabled,
      })
    } else {
      if (e.keyCode === 27 && !isEscDisabled) {
        e.preventDefault()
        clickProps?.steps?.[currentStep]?.OnClose?.()
        setIsOpen(false)
      }
      if (e.keyCode === 39 && !isRightDisabled) {
        e.preventDefault()
        if (rtl) {
          prev()
        } else {
          next()
        }
      }
      if (e.keyCode === 37 && !isLeftDisabled) {
        e.preventDefault()
        if (rtl) {
          next()
        } else {
          prev()
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler, false)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [hideActions, setCurrentStep, currentStep])

  return null
}

export type KeyboardProps = KeyboardHandler & {
  disableKeyboardNavigation?: boolean | KeyboardParts[]
  setCurrentStep: Dispatch<React.SetStateAction<number>>
  currentStep: number
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  setLoading: Dispatch<React.SetStateAction<boolean>>
  stepsLength: number
  hideActions?: {
    next?: boolean;
    back?: boolean;
    close?: boolean
  }
  rtl?: boolean
  clickProps?: ClickProps
  onNext?: (data?: any) => void
  onBack?: (data?: any) => void
  onClose?: (data?: any) => void
}

export default Keyboard
