// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { TourProvider } from "../../components/src/Tour"

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import CreditdebitCardPayments from "../../blocks/CreditdebitCardPayments/src/CreditdebitCardPayments";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import SurveyQuestions from "../../blocks/CfSurveyQuestions2/src/CfSurveyQuestions2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OtpInputAuth";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOtp";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContextTagging from "../../blocks/ContextTagging/src/ContextTagging";
import TagsUsers from "../../blocks/ContextTagging/src/TagsUsers";
import Groups from "../../blocks/Groups/src/Groups.web";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import CfSendInvitationForNewUsers from "../../blocks/CfSendInvitationForNewUsers/src/CfSendInvitationForNewUsers";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Location from "../../blocks/location/src/Location";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Surveys from "../../blocks/Surveys/src/Surveys";
import SurveysWeb from "../../blocks/Surveys/src/Surveys.web";
import SurveysTemplate from "../../blocks/SurveysTemplate/src/SurveysTemplate.web";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import SignInUserBlock from "../../blocks/email-account-login/src/SignInUser.web";
import AuthBlock from "../../blocks/email-account-login/src/AdminLoginBlock.web"
import ResetPassword from "../../blocks/email-account-login/src/ResetPassword"
import CfReportSurveyResult from "../../blocks/CfReportSurveyResult/src/CfReportSurveyResult";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Settings from "../../blocks/Settings/src/Settings";
import AdminSettings from "../../blocks/Settings/src/AdminSettings.web";
import UserSettings from "../../blocks/UserSettings/src/UserSettings.web";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Surveys2 from "../../blocks/surveys2/src/Surveys2";
import Dynamiccontent2 from "../../blocks/dynamiccontent2/src/Dynamiccontent2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Scheduling from "../../blocks/scheduling/src/Scheduling.web";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Groups2 from "../../blocks/groups2/src/Groups2";
import Accountscoreranking2 from "../../blocks/accountscoreranking2/src/Accountscoreranking2";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Contexttagging2 from "../../blocks/contexttagging2/src/Contexttagging2";




import ForgotPassword from "../../blocks/email-account-login/src/ForgotPassword";
import AccountCreation from "../../blocks/AccountCreation/src/AccountCreation.web";
import AdminSurvey from "../../blocks/Surveys/src/AdminSurveys.web";
import GroupUsers from "../../blocks/AccountCreation/src/GroupUsers.web";
import UserFillSurvey from '../../blocks/UserFillSurvey/src/UserFillSurvey.web';
import BasicSettings from "../../blocks/Settings/src/BasicSettings.web";
import UserLists from "../../blocks/Groups/src/UserLists.web";
import SurveyReport from "../../blocks/SurveyReport/src/SurveyReport.web";
import DashboardWeb from "../../blocks/dashboard/src/Dashboard.web"
import AnalyticsWeb from "../../blocks/dashboard/src/Analytics.web"
import ExportAnalytics from "../../blocks/dashboard/src/ExportAnalytics.web"
import AdminSurveysWeb from "../../blocks/Surveys/src/AdminSurveys.web";
import AdminCart from "../../blocks/email-account-login/src/AdminCart";
import CreateUserAccount from "../../blocks/AccountCreation/src/CreateUserAccount.web";
import SubscriptionPlan from "../../blocks/customisableusersubscriptions/src/SubscriptionPlan.web";

const routeMapAdmin = {
  Accountscoreranking2: {
    component: Accountscoreranking2,
    path: "/Accountscoreranking2"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Contexttagging2: {
    component: Contexttagging2,
    path: "/Contexttagging2"
  },

  Groups2: {
    component: Groups2,
    path: "/Groups2"
  },

  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },

  Surveys2: {
    component: Surveys2,
    path: "/Surveys2"
  },
  Dynamiccontent2: {
    component: Dynamiccontent2,
    path: "/Dynamiccontent2"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },

  SurveysWeb: {
    component: SurveysWeb,
    path: "/SurveysWeb"
  },
  AdminSurvey: {
    component: AdminSurvey,
    path: "/AdminSurvey/:page",
    auth: true,
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  AdminSettings: {
    component: AdminSettings,
    path: "/AdminSettings",
    auth: true
  },
  BasicSettings: {
    component: BasicSettings,
    path: "/BasicSettings",
    auth: true
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: "/AdminConsole2"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: "/AutomaticCheckoutCalculation2"
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: "/Ordermanagement"
  },
  OrderDetails: {
    component: OrderDetails,
    path: "/OrderDetails"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  CreditdebitCardPayments: {
    component: CreditdebitCardPayments,
    path: "/CreditdebitCardPayments"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  SurveyQuestions: {
    auth: true,
    component: SurveyQuestions,
    path: "/SurveyQuestions/:surveyId"
  },
  SurveyQuestion: {
    auth: true,
    component: SurveyQuestions,
    path: "/SurveyQuestion"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  AccountScoreranking: {
    component: AccountScoreranking,
    path: "/AccountScoreranking"
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: "/PaymentAdmin"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: "/ServiceSpecificSettingsAdmin"
  },
  DynamicContent: {
    component: DynamicContent,
    path: "/DynamicContent"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  ContextTagging: {
    component: ContextTagging,
    path: "/ContextTagging"
  },
  TagsUsers: {
    component: TagsUsers,
    path: "/TagsUsers/:groupId",
    auth: true
  },
  Groups: {
    component: Groups,
    path: "/Groups"
  },
  UserLists: {
    component: UserLists,
    path: "/UserLists/:id"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  CfSendInvitationForNewUsers: {
    component: CfSendInvitationForNewUsers,
    path: "/CfSendInvitationForNewUsers"
  },
  UserProfileAdvance: {
    component: UserProfileAdvance,
    path: "/UserProfileAdvance"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  MultipageForms2: {
    component: MultipageForms2,
    path: "/MultipageForms2"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Surveys: {
    component: Surveys,
    path: "/Surveys"
  },
  SurveysTemplate: {
    auth: true,
    component: SurveysTemplate,
    path: "/SurveysTemplate"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },
  CfReportSurveyResult: {
    component: CfReportSurveyResult,
    path: "/CfReportSurveyResult"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  AccountCreation: {
    component: AccountCreation,
    path: "/AccountCreation/:page",
    auth: true,
  },
  AccountCreation: {
    component: AccountCreation,
    path: "/AccountCreation",
    auth: true,
  },
  GroupUser: {
    component: GroupUsers,
    path: "/GroupUser",
    auth: true,
  },
  GroupUsers: {
    component: GroupUsers,
    path: "/GroupUsers/:groupId",
    auth: true,
  },
  Dashboard: {
    component: DashboardWeb,
    path: "/DashboardWeb",
    auth: true,
  },
  Analytics: {
    component: AnalyticsWeb,
    path: "/AnalyticsWeb",
    auth: true,
  },
  ExportAnalytics: {
    component: ExportAnalytics,
    path: "/ExportAnalytics",
    auth: true,
  },
  AdminSurveys: {
    component: AdminSurveysWeb,
    path: "/AdminSurvey",
    auth: true,
  },
  calendar: {
    component: Scheduling,
    path: "/calendar",
    auth: true,
  },
  SubscriptionPlan: {
    component: SubscriptionPlan,
    path: "/SubscriptionPlan",
    auth: true
  }
};

const routeMapUser = {
  SurveyReport: {
    component: SurveyReport,
    path: "/SurveyReport/:surveyId",
    auth: true,
  },
  SurveysWeb: {
    component: SurveysWeb,
    path: "/SurveysWeb"
  },
  UserFillSurvey: {
    component: UserFillSurvey,
    path: '/UserFillSurvey/:surveyId'
  },
  UserSettings: {
    component: UserSettings,
    path: "/UserSettings"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide",
    auth: true,
  },
};

const commonRoutes = {
  LandingPageWeb: {
    component: LandingPageWeb,
    path: "/",
    exact: true
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  SignInUserBlock: {
    component: SignInUserBlock,
    path: "/SignInUserBlock"
  },
  AuthBlock: {
    component: AdminCart,
    path: "/AuthBlock"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword"
  },
  AdminAuth: {
    component: AuthBlock,
    path: "/AdminAuth"
  },
  CreateUserAccount: {
    component: CreateUserAccount,
    path: '/CreateUserAccount'
  }
};


const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <TourProvider steps={[]} disableInteraction disableKeyboardNavigation onClickClose={() => { }} OnNext={(data) => { }} OnBack={(data) => { }} >
        <View>
          <TopNav />
          {WebRoutesGenerator({ routeMapAdmin, routeMapUser, commonRoutes })}
          <ModalContainer />
        </View>
      </TourProvider>
    );
  }
}

export default App;