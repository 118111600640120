import React, {
  ReactNode,
  Dispatch,
  ComponentType,
  PropsWithChildren,
} from 'react'
import { StylesObj, stylesMatcher } from '../styles'
import { StepType, BtnFnProps, NavButtonProps } from '../type.d'

const Navigation: React.FC<NavigationProps> = ({
  styles = {},
  steps,
  setCurrentStep,
  currentStep,
  setIsOpen,
  setTourLoading,
  nextButton,
  prevButton,
  hideActions,
  rtl,
  onNext,
  onBack,
  closeClickHandler
}) => {
  const stepsLength = steps.length
  const getStyles = stylesMatcher(styles)

  const nextClick = () => {
    if (steps?.[currentStep]?.nextIsClose) {
      setCurrentStep(-1)
      setIsOpen(false)
      setTourLoading(false)
      steps?.[currentStep]?.OnNext?.()
      onNext?.({ currentStep: Math.min(currentStep + 1, stepsLength - 1) })
    } else {
      setCurrentStep(Math.min(currentStep + 1, stepsLength - 1))
      steps?.[currentStep]?.OnNext?.()
      onNext?.({ currentStep: Math.min(currentStep + 1, stepsLength - 1) })
    }
  }

  const backClick = () => {
    setCurrentStep(Math.max(currentStep - 1, 0))
    steps?.[currentStep]?.OnBack?.()
    onBack?.({ currentStep: Math.max(currentStep - 1, 0) })
  }

  const Button: React.FC<PropsWithChildren<NavButtonProps>> = ({
    onClick,
    kind = 'next',
    children,
  }) => {
    function clickHandler() {
      if (onClick && typeof onClick === 'function') {
        onClick()
      } else {
        if (kind === 'next') {
          nextClick()
        } else {
          backClick()
        }
      }
    }

    return (
      <button
        style={{
          ...getStyles('button', {
            kind,
            disabled: hideActions?.[kind]
              ? hideActions
              : kind !== 'next' && currentStep === 0,
          }),
          ...kind === 'back' ? getStyles('backButton', {
            kind
          }) : {}
        }}
        onClick={clickHandler}
        aria-label={`Go to ${kind} step`}
      >
        {(kind === 'next' && stepsLength - 1 === currentStep) && steps?.[currentStep]?.nextIsClose ? 'Close' : children}
      </button>
    )
  }

  return (
    steps?.[currentStep]?.footer && typeof steps?.[currentStep]?.footer === 'function' ?
      steps?.[currentStep]?.footer?.({
        Button,
        nextClick,
        backClick,
        closeClickHandler,
        setCurrentStep,
        currentStep,
        stepsLength,
        setIsOpen,
        setTourLoading,
        steps,
      })
      :
      <div style={getStyles('controls', {})} dir={rtl ? 'rtl' : 'ltr'}>
        {!hideActions?.back ? (
          prevButton && typeof prevButton === 'function' ? (
            prevButton({
              Button,
              setCurrentStep,
              currentStep,
              stepsLength,
              setIsOpen,
              setTourLoading,
              steps,
            })
          ) : (
            <Button kind="back">Back</Button>
          )
        ) : null}
        {!hideActions?.next ? (
          nextButton && typeof nextButton === 'function' ? (
            nextButton({
              Button,
              setCurrentStep,
              currentStep,
              stepsLength,
              setIsOpen,
              setTourLoading,
              steps,
            })
          ) : (
            <Button>Next</Button>
          )
        ) : null}
      </div>
  )
}

type BaseProps = {
  styles?: StylesObj
}

export type NavigationProps = BaseProps & {
  setCurrentStep: Dispatch<React.SetStateAction<number>>
  steps: StepType[]
  currentStep: number
  nextButton?: (props: BtnFnProps) => ReactNode | null
  prevButton?: (props: BtnFnProps) => ReactNode | null
  setIsOpen: Dispatch<React.SetStateAction<boolean>>
  setTourLoading: Dispatch<React.SetStateAction<boolean>>
  hideActions?: {
    next?: boolean;
    back?: boolean;
  }
  rtl?: boolean
  onNext?: (data?: any) => void
  onBack?: (data?: any) => void
  closeClickHandler?: (data?: any) => any
}

export default Navigation
