import React, {
  FunctionComponent,
  // Customizable Area Start
  // Customizable Area End
} from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SurveyChartWeb from "./SurveyChart.web";
// Customizable Area End

interface Props {
  // Customizable Area Start
  overviewData: any;
  classes: any;
  surveyNumber:any;
  // Customizable Area End
}

const SurveyOverview: FunctionComponent<Props> = ({
  // Customizable Area Start
  overviewData,
  classes,
  surveyNumber,
  // Customizable Area End
}) => {
  // Customizable Area Start
  const convertDuration = (durationInSeconds: number) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
  
    if (minutes === 0) {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  
    if (seconds === 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }
  
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
  };
  
  const formattedDuration = convertDuration(overviewData.duration);
    // Customizable Area End
  return (
    // Customizable Area Start
    <Box className={classes.outerContainer} id="overview-dashboard">
      <Box className={classes.header}>
        <Typography variant="h6" className={classes.headingSurvey}>
          {overviewData.title} &gt;&gt; 
        </Typography>
        <Typography variant="h6" className={classes.headingOverview}>
          Overview
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.detail}>
          <Typography
            component="span"
            display="inline"
            className={classes.detail}
            style={{ fontSize: "14px" }}
          >
            Title of Survey - &nbsp;
          </Typography>
          {overviewData.title}
        </Typography>
        <Typography className={classes.detail}>
          <Typography
            component="span"
            display="inline"
            className={classes.detail}
            style={{ fontSize: "14px" }}
          >
            Duration of Survey - &nbsp;
          </Typography>
          {formattedDuration}
        </Typography>
        <Typography className={classes.detail}>
          <Typography
            component="span"
            display="inline"
            className={classes.detail}
            style={{ fontSize: "14px" }}
          >
            Total number of respondents - &nbsp;
          </Typography>
          {overviewData.respondents} Respondents
        </Typography>
        <Typography className={classes.detail}>
          <Typography
            component="span"
            display="inline"
            className={classes.detail}
            style={{ fontSize: "14px" }}
          >
            Assigned Group - &nbsp;
          </Typography>
          {overviewData.group_names.join(" , ")}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
          <Box className={classes.chartContainer}>
          <Typography
            component="h4"
            display="inline"
            className={classes.detail}
            style={{ fontSize: "18px" }}
          >
            Number of Respondents by Date
          </Typography><br/>

          <Typography
            component="h4"
            display="inline"
            className={classes.detail}
            style={{ fontSize: "14px" ,paddingLeft:'62px'}}
          >
            <span className={classes.circle}></span>
           Survey {surveyNumber}
          </Typography>
            <SurveyChartWeb
             graphData={overviewData.graph_details} 
              />
          </Box>
        </div>
      </Box>
    </Box>
    // Customizable Area End
  );
};

// Customizable Area Start
const styles = {
  outerContainer: {
    width: "100%",
    height:"80vh"
  },
  header: {
    borderBottom: "1px solid #DEDEDE",
    display: "flex",
    alignItems: "center",
    padding: "16px",
  },
  headingSurvey: {
    fontFamily: "aeonik-regular",
    fontWeight: 700,
    fontSize: "18px",
    color: "#9FA0A1",
  },
  content: {
    padding: "16px",
    paddingTop:"8px",
    width: "calc(100%)",
    height: "calc(100% - 98px)",
    overflow: "hidden" as const,
    boxSizing: "border-box" as const,
    overflowY: "auto" as const,
  },
  headingOverview: {
    fontFamily: "aeonik-regular",
    fontWeight: 700,
    fontSize: "18px",
    color: "#1C386D",
    marginLeft: "8px",
  },
  detail: {
    fontFamily: "aeonik-regular",
    fontWeight: 600,
    color: "#202020",
    fontSize: "14px",
    display:"block ruby"
  },
  chartContainer: {
    marginTop: "10px",
    border: '1px solid #eee4e4ed',
    borderRadius: '9.12px',
    padding: '16px',
    boxShadow:'0px 3.04px 39.5px 0px #0000000D',
    marginBottom: '20px',
    maxWidth:"100%",
  },
  circle :{
    display: "block",
    width: "10px",
    height: "10px",
    borderRadius:" 50%",
    backgroundColor: "#4B6EB0",
    marginRight:" 5px",
  }
};

export default withStyles(styles)(SurveyOverview);
// Customizable Area End
