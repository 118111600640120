import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Divider,
    styled,
    TextField,
    InputAdornment,
    FormControl,
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Modal,
    IconButton,
    Snackbar
} from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import DatePicker, { DateObject } from "react-multi-date-picker";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import { groupIcon, groupIconActive, listIcon, tagIcon, uploadIcon } from "./assets";
import GoogleChart from "../../../components/src/VisualAnalyticsGoogleChart";
import VerticalChart from "../../../components/src/VisualAnalyticsVerticalChart";
import AdHocSurveyReportView from "../../../components/src/AdHocSurveyReportView";

// Customizable Area End

import AdHocReportingController, {
    Props,
    configJSON,
} from "./AdHocReportingController";

// Customizable Area Start

const tagOptions = [
    {id: 1, name: "Select all"},
    {id: 2, name: "A sapiente reo"},
    {id: 3, name: "BlanditiIs hii"},
    {id: 4, name: "Eos fugit at d"},
    {id: 5, name: "Asperiores esd1"},
    {id: 6, name: "Asperiores esd2"},
    {id: 7, name: "Asperiores esd3"}
];

const tabelHeadingArray = ["User Email", "First Name", "Last Name", "Postcode"];

interface OptionsType {
    id: number;
    name: string;
};

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// Customizable Area End

export default class AdHocReporting extends AdHocReportingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderSelectInput = (name: string, value: number | number[], options: OptionsType[], icon: string | null) => {
        const { openDropdown } = this.state;
        return (
            <FormControl>
                <CustomSelect
                    data-test-id={`${name}SelectInputTestId`}
                    variant="outlined"
                    value={value}
                    displayEmpty
                    multiple={name === "survey" ? false : true}
                    name={name}
                    renderValue={(selected) => this.handleSelectRenderValue(selected, name, options)}
                    IconComponent={() => {
                        return (
                            <ExpandMoreRoundedIcon
                                style={{
                                    color: "#202020",
                                    transform:
                                        openDropdown === name ? "rotate(180deg)" : "rotate(0deg)",
                                    transition: "transform 0.3s ease",
                                    marginRight: "10px",
                                    marginLeft: "-25px"
                                }}
                            />
                        );
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            {icon ? <img src={icon} alt="start_icon" /> : <></>}
                        </InputAdornment>
                    }
                    onChange={(event) => this.handleSelectChange(event, name, options)}
                >
                    {options.map((item, index) =>
                        name === "survey" ? (
                            <MenuItem key={index} value={item.id} style={{fontFamily: "Raleway"}}>
                                {item.name}
                            </MenuItem>
                        ) : (
                            <MenuItem key={index} value={item.id}>
                                <Checkbox checked={(value as number[]).includes(item.id)} color="primary" />
                                <ListItemText primary={item.name} style={{fontFamily: "Raleway"}}/>
                            </MenuItem>
                        )
                    )}
                </CustomSelect>
            </FormControl>
        );
    };

    renderTable = () => {
        const { activeItem, groups, adhocTable, page, totalPages, totalCount } = this.state;
        let group = groups.find((item) => activeItem === item.id);
        let groupName = group ? group.name : "";
        return (
            <>
                <Box style={webStyle.groupNameMainBox}>
                    <Typography style={webStyle.groupNameText}>{groupName}</Typography>
                    <Box>
                        <CustomButton
                            data-test-id="visualButtonTestId"
                            variant="outlined"
                            onClick={this.handleVisualAnalyticsClick}
                        >
                            Visual Analytics
                        </CustomButton>
                        <CustomButton
                            data-test-id="exportButtonTestId"
                            variant="outlined"
                            style={{ marginLeft: "12px" }}
                            onClick={()=> this.handleExportClick()}
                        >
                            <Box>
                                <img src={uploadIcon} alt="uploadIcon" />
                                <Typography style={{fontFamily: "Raleway"}}>{configJSON.export}</Typography>
                            </Box>
                        </CustomButton>
                    </Box>
                </Box>
                <TableContainer>
                    <Table aria-label="simple table">
                        <CustomTableHead>
                            <TableRow>
                                {tabelHeadingArray.map((item, index) => (
                                    <TableCell key={index} style={{fontFamily: "Raleway"}}>{item}</TableCell>
                                ))}
                                <TableCell
                                    style={{ ...webStyle.lastCell, background: "#ecf2ff" }}
                                ></TableCell>
                            </TableRow>
                        </CustomTableHead>
                        <TableBody>
                            {adhocTable.length === 0 ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={5}
                                        style={{
                                            textAlign: "center",
                                            borderBottom: "none",
                                            fontSize: "14px",
                                            color: "#202020",
                                            paddingTop: "25px",
                                            fontFamily: "Raleway"
                                        }}
                                    >
                                        No results found for the selected parameters. Please adjust
                                        your filters and try again.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {adhocTable.map((rowData, index) => (
                                        <CustomTableBodyRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {rowData.user.email}
                                            </TableCell>
                                            <TableCell>{rowData.user.first_name}</TableCell>
                                            <TableCell>{rowData.user.last_name}</TableCell>
                                            <TableCell>{rowData.user.postcode}</TableCell>
                                            <TableCell style={webStyle.lastCell}>
                                                <Typography
                                                    data-test-id={`viewAnswersButtonTestId${index}`}
                                                    style={webStyle.lastCellText}
                                                    onClick={() => this.getAnswersApi(rowData.user.id)}
                                                >
                                                    View Answers
                                                </Typography>
                                            </TableCell>
                                        </CustomTableBodyRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {adhocTable.length !== 0 && (
                    <Box style={webStyle.paginationBox}>
                        <Typography style={{ color: "#000000" }}>
                            {`${((page - 1)*20)+1} to ${((page-1)*20)+adhocTable.length} of ${totalCount} Items`}
                        </Typography>
                        <StyledPagination
                            data-test-id="paginationTestId"
                            page={page}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            onChange={this.handlePageChange}
                        />
                    </Box>
                )}
            </>
        );
    };

    renderHeadingSubDetail = () => {
        const { userAnswersReport } = this.state;
        const surveyDetail = userAnswersReport.surveys[0];
        const formattedDate = this.formateSurveyDate(surveyDetail.date_filled);
        return (
            <Box style={webStyle.headingLowerBox}>
                <Box style={webStyle.headingTextBox}>
                    <Typography style={webStyle.headingLowerBoxText}>
                        {formattedDate}
                    </Typography>
                </Box>
                <Box style={webStyle.headingTextBox}>
                    <Typography
                        style={{ ...webStyle.headingLowerBoxText, color: "#6F6F6F" }}
                    >
                        Postcode:&nbsp;
                    </Typography>
                    <Typography style={webStyle.headingLowerBoxText}>
                        {surveyDetail.postcode}
                    </Typography>
                </Box>
                <Box style={webStyle.headingTextBox}>
                    <Typography
                        style={{ ...webStyle.headingLowerBoxText, color: "#6F6F6F" }}
                    >
                        Latitude:&nbsp;
                    </Typography>
                    <Typography style={webStyle.headingLowerBoxText}>
                        {surveyDetail.latitude}
                    </Typography>
                </Box>
                <Box style={webStyle.headingTextBox}>
                    <Typography
                        style={{ ...webStyle.headingLowerBoxText, color: "#6F6F6F" }}
                    >
                        Longitude:&nbsp;
                    </Typography>
                    <Typography style={webStyle.headingLowerBoxText}>
                        {surveyDetail.longitude}
                    </Typography>
                </Box>
            </Box>
        );
    }

    renderModalHeadingBox = (heading: string) => {
        
        return (
            <Box style={webStyle.mainModalHeadingBox}>
                <Box>
                    <Typography style={webStyle.modalHeadingBox}>{heading}</Typography>
                    {heading !== "Visual Analytics" && (
                        this.renderHeadingSubDetail()
                    )}
                </Box>
                <IconButton
                    data-test-id="modalCloseButtonTestId"
                    style={webStyle.modalCloseButton}
                    onClick={() => this.handleModalClose()}
                >
                    <CloseRoundedIcon style={{ color: "#000" }} />
                </IconButton>
            </Box>
        );
    };

    renderModal = () => {
        const { modalOpenFor, userAnswersReport } = this.state;
        const formattedQuestionsArray = this.formateQuestionResponse(userAnswersReport.surveys);
        
        return (
            <Modal
                data-test-id="modalTestId"
                open={Boolean(modalOpenFor)}
                onClose={() => this.handleModalClose()}
            >
                <Box style={webStyle.mainModalBox}>
                    <Box style={webStyle.mainModalInnerBox}>
                        {modalOpenFor === "visualAnalytics" && (
                            <Box>
                                {this.renderModalHeadingBox("Visual Analytics")}
                                {this.renderAnalytics()}
                            </Box>
                        )}
                        {modalOpenFor === "viewReport" && (
                            <Box>
                                {this.renderModalHeadingBox("Xiang Avraham")}
                                <AdHocSurveyReportView questionsArray={formattedQuestionsArray} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Modal>
        );
    };

    renderAnalytics = () => {
        const { 
            group_name,
            data,
            overall_avg_time_taken,
            industry_sector,
            local_authority
        } = this.state;
        const updateData = data.map(item=> {return {xlabel: item.date, ylabel: item.users_filled}});
        const getIndustryChartData = industry_sector.map(item => {return{label: item.industry_sector, users: item.total_surveys}});
        const getAuthorityChartData = local_authority.map(item => {return{label: item.local_authority, users: item.total_surveys}});
        const { startDate, endDate } = this.getStartAndEndDate();
        const newStartDate = this.formatDateWithYear(startDate);
        const newEndDate = this.formatDateWithYear(endDate);
        return (
            <Box style={webStyle.visBodyBox}>
                <Typography style={webStyle.visDateRangeText}>
                    Users over the selected Date Range:
                </Typography>
                <Box style={webStyle.visBorderBox}>
                    <Box style={webStyle.visBodyGrpHeadingBox}>
                        <Typography style={webStyle.visBodyGrpHeadingText}>
                            {group_name}
                        </Typography>
                        {
                            ( startDate && endDate ) && 
                            <Typography style={webStyle.visBodyGrpHeadingDate}>
                                {`${newStartDate}- ${newEndDate}`}
                            </Typography>
                        }
                        
                    </Box>
                    {
                        updateData.length !==0 && <GoogleChart data={updateData}/>
                    }
                    
                </Box>
                <Box
                    style={{
                        ...webStyle.visBorderBox,
                        display: "flex",
                        gap: "10px",
                        alignItems: "center"
                    }}
                >
                    <QueryBuilderRoundedIcon />{" "}
                    <Typography style={webStyle.avgTimeText}>
                        Average time to complete survey:
                    </Typography>{" "}
                    <Typography
                        style={{ ...webStyle.avgTimeText, fontWeight: 500 }}
                    >
                        {`${overall_avg_time_taken} Hours`}
                    </Typography>
                </Box>
                <Box style={webStyle.visBorderBox}>
                    <Typography style={webStyle.avgTimeText}>Number of respondents by Industry</Typography>
                    <Box style={webStyle.verGraphContainer}>
                        <VerticalChart data={getIndustryChartData}/>
                    </Box>
                </Box>
                <Box style={webStyle.visBorderBox}>
                    <Typography style={webStyle.avgTimeText}>Number of respondents by Local Authority</Typography>
                    <Box style={webStyle.verGraphContainer}>
                        <VerticalChart data={getAuthorityChartData}/>
                    </Box>
                </Box>
            </Box>
        )
    }

    renderBody = () => {
        const { selectedDates, surveys, survey, tag, authority, industry, industryOptions, localAuthorityOptions } = this.state;
        const formattedValue = this.handleDateRender(selectedDates);
        const today = new DateObject();
        const surveyOptions = surveys.map(item => {return {id: item[0], name: item[1]}});
        return (
            <Box style={webStyle.MainBodyBox}>
                <Box style={webStyle.filterMainBox}>
                    <Box>
                        <Typography style={webStyle.dateRangeText}>
                            Select Date Range:
                        </Typography>
                        <DatePicker
                            data-test-id="datePickerTestId"
                            value={selectedDates}
                            onChange={this.handleDateChange}
                            range
                            format="MM/DD/YY"
                            showOtherDays
                            maxDate={today}
                            render={(_value, openCalendar) => (
                                <CustomTextField
                                    value={formattedValue}
                                    placeholder="DD/MM/YYYY"
                                    onClick={openCalendar}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalendarTodayRoundedIcon
                                                    style={webStyle.calendarIcon}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                        />
                        <Typography style={webStyle.dateRangeBelowText}>
                            By default option is all the dates selected
                        </Typography>
                    </Box>
                    <Box style={webStyle.selectBox}>
                        <Box style={webStyle.selectWrapper}>
                            {this.renderSelectInput("survey", survey as number, surveyOptions as OptionsType[], listIcon)}
                            {this.renderSelectInput("tag", tag, tagOptions, tagIcon)}
                            {this.renderSelectInput("authority", authority, localAuthorityOptions, null)}
                            {this.renderSelectInput("industry", industry, industryOptions, null)}
                        </Box>
                        <Typography
                            style={{ ...webStyle.dateRangeText, margin: "10px 0 0" }}
                        >
                            Showing results for {this.getNameFromId(survey as number, surveyOptions as OptionsType[])}
                        </Typography>
                    </Box>
                </Box>
                {this.renderTable()}
            </Box>
        );
    };

    renderGroupsTabs = () => {
        const { activeItem, hoverItem, groups } = this.state;
        return (
            <>
                {
                    groups.map((item, index) => {
                        const highlight = activeItem === item.id || hoverItem === item.id;
                        return (
                            <React.Fragment key={index}>
                                <Box
                                    data-test-id={`sideTabButtonTestId${index}`}
                                    style={webStyle.mainItemBox}
                                    onClick={() => this.handleActiveTab(item.id)}
                                    onMouseEnter={() => this.handleHoverEnter(item.id)}
                                    onMouseLeave={this.handleHoverLeave}
                                >
                                    <Box
                                        style={{
                                            ...webStyle.imageBox,
                                            background: highlight
                                                ? "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)"
                                                : "#ECF0F7"
                                        }}
                                    >
                                        <img
                                            src={highlight ? groupIcon : groupIconActive}
                                            alt="group_icon"
                                        />
                                    </Box>
                                    <Typography
                                        style={{
                                            ...webStyle.tabText,
                                            ...(highlight ? webStyle.gradientText : {})
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                    <Box
                                        style={{
                                            ...webStyle.borderBoxx,
                                            visibility: highlight ? "visible" : "hidden",
                                        }}
                                    ></Box>
                                </Box>
                                {groups.length !== index + 1 && <Divider />}
                            </React.Fragment>
                        );
                    })
                }
            </>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { groups, openSnackbar } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                <Box
                    style={{
                        ...webStyle.sideBox,
                        height: groups.length === 0? "400px": "fit-content",
                        display: groups.length === 0? "flex": "block",
                    }}
                >
                    {   groups.length === 0?
                        <Typography style={webStyle.noGroupText}>
                            No group available please create a group
                        </Typography>:
                        this.renderGroupsTabs()
                    }
                </Box>
                {this.renderBody()}
                {this.renderModal()}
                <Snackbar
                    data-test-id="snackbarTestId"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={this.handleSnackbarClose}
                >
                    <Alert onClose={this.handleSnackbarClose} severity="error">
                        No results found!
                    </Alert>
                </Snackbar>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
                </style>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    sideBox: {
        border: "1px solid #DEDEDE",
        borderRadius: "12px",
        width: "272px",
        boxShadow: "0px 8px 32px 0px #0000000F",
        height: "fit-content",
        maxHeight: "950px",
        overflowY: "auto",
        alignItems: "center"
    } as React.CSSProperties,
    noGroupText: {
        fontSize: "18px",
        padding: "10px",
        textAlign: "center"
    } as React.CSSProperties,
    headingLowerBox: {
        display: "flex",
        gap: "10px",
        marginTop: "10px"
    },
    headingLowerBoxText: {
        fontSize: "14px",
        color: "#202020",
        fontWeight: 500,
        fontFamily: "Raleway"
    },
    headingTextBox: {
        display: "flex"
    },
    visBorderBox: {
        padding: "16px",
        border: "1px solid #DEDEDE",
        borderRadius: "8px"
    },
    avgTimeText: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#202020",
        fontFamily: "Raleway"
    },
    visBodyBox: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "24px"
    } as React.CSSProperties,
    visBodyGrpHeadingBox: {
        display: "flex",
        justifyContent: "space-between"
    },
    mainModalBox: {
        height: "100%",
        padding: "10px",
        overflowY: "scroll"
    } as React.CSSProperties,
    mainModalInnerBox: {
        borderRadius: "18px",
        background: "#fff",
        border: "none",
    },
    verGraphContainer: {
        borderRadius: "12px",
        background: "#E4E8F2",
        padding: "40px 100px 40px 60px",
        width: "80%",
        marginTop: "24px"
    },
    visBodyGrpHeadingText: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#202020",
        fontFamily: "Raleway"
    },
    visBodyGrpHeadingDate: {
        fontSize: "10px",
        fontWeight: 500,
        color: "#202020",
        fontFamily: "Raleway"
    },
    mainModalHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #E7E7E7",
        padding: "30px 24px",
    },
    modalCloseButton: {
        background: "#EEEEEE",
        width: "31px",
        height: "31px"
    },
    modalHeadingBox: {
        fontSize: "24px",
        fontWeight: 700,
        color: "#202020",
        fontFamily: "Raleway"
    },
    visDateRangeText: {
        fontWeight: 600,
        color: "#202020",
        fontFamily: "Raleway"
    },
    MainBodyBox: {
        flexGrow: 1
    },
    groupNameMainBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "25px 0"
    },
    groupNameText: {
        fontSize: "20px",
        color: "#121212",
        fontWeight: 600,
        fontFamily: "Raleway"
    },
    lastCell: {
        textAlign: "center",
        borderBottom: "1px solid #B2B2B2",
        background: "#fff",
        boxShadow: "0px 6px 10px 0px #DEDEDE"
    } as React.CSSProperties,
    lastCellText: {
        textDecoration: "underline",
        textDecorationColor: "#1C386D",
        textUnderlineOffset: "3px",
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: "Raleway",
        fontSize: "14px",
        fontWeight: 600,
        cursor: "pointer",
        width: "fit-content",
        margin: "auto"
    },
    paginationBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        marginTop: "18px"
    },
    dateRangeText: {
        fontSize: "14px",
        color: "#202020",
        marginBottom: "5px",
        fontFamily: "Raleway"
    },
    filterMainBox: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px"
    },
    selectWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        gap: "10px"
    },
    selectBox: {
        flexGrow: 1,
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        marginTop: "27px"
    } as React.CSSProperties,
    dateRangeBelowText: {
        fontSize: "12px",
        color: "#202020",
        marginTop: "10px",
        fontFamily: "Raleway"
    },
    mainBox: {
        padding: "0 24px 24px",
        display: "flex",
        gap: "35px",
        fontFamily: "Raleway"
    } as React.CSSProperties,
    mainItemBox: {
        display: "flex",
        padding: "28px 0 28px 16px",
        alignItems: "center",
        gap: "20px",
        cursor: "pointer"
    },
    calendarIcon: {
        fontSize: "24px",
        color: "#202020",
        cursor: "pointer"
    },
    imageBox: {
        width: "56px",
        height: "56px",
        background: "#ECF0F7",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    borderBoxx: {
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        width: "4px",
        height: "72px",
        borderRadius: "5px 0 0 5px"
    },
    tabText: {
        color: "#797979",
        fontWeight: 700,
        flexGrow: 1,
        fontFamily: "Raleway"
    },
    gradientText: {
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    }
};

const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        color: "#000",
        border: "1px solid #F1F1F1",
        borderRadius: "8px",
        fontSize: "13px",
        fontWeight: 600
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#BFCAE4",
        border: "1px solid #BFCAE4"
    }
});

const CustomTableBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        color: "#202020",
        borderBottom: "1px solid #626262",
        fontSize: "14px",
        fontWeight: 600,
        background: "#fff",
        textAlign: "center",
        fontFamily: "Raleway"
    }
});

const CustomTableHead = styled(TableHead)({
    "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
            color: "#202020",
            fontSize: "14px",
            fontWeight: 600,
            background: "#ebf1fe",
            textAlign: "center",
            borderBottom: "1px solid #626262"
        }
    }
});

const CustomButton = styled(Button)({
    border: "1px solid #1C386D",
    height: "48px",
    textTransform: "none",
    fontSize: "14px",
    color: "#202020",
    fontWeight: 500,
    borderRadius: "6px",
    fontFamily: "Raleway",
    "& .MuiBox-root": {
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    "& .MuiTypography-root": {
        fontSize: "14px",
        color: "#202020",
        fontWeight: 500
    }
});

const CustomSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Raleway",
    "& .MuiOutlinedInput-notchedOutline": {
        height: "58px",
        borderRadius: "8px",
        border: "1px solid #1C386D"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1C386D"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #1C386D"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#1C386D",
        "& fieldset": {
            borderColor: "#1C386D",
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    },
    "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f44336 !important"
    },
    "&.MuiInputBase-root": {
        width: "fit-content",
        maxWidth: "200px"
    }
});

const CustomTextField = styled(TextField)({
    height: "56px",
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#f44336 !important"
    },
    "& .MuiOutlinedInput-root": {
        height: "55px",
        borderRadius: "10px",
        width: "100%",
        color: "#202020",
        "& fieldset": {
            border: `1px solid #E0E0E0`
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E0E0E0",
            borderWidth: "1px"
        },
        "&:hover fieldset": {
            borderColor: "#E0E0E0"
        }
    },
    "& .MuiInputBase-input": {
        fontFamily: "Raleway",
        fontSize: "16px",
        "&::placeholder": {
            color: "#202020",
            opacity: 1,
            fontFamily: "Raleway"
        }
    },
    "& input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
    }
});

// Customizable Area End
