import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, CardContent, Card } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import AdminLoginController, { Props } from './AdminLoginController'
import { Vector, VectorAdmin, VectorUser, VectorWhite } from "./assets"
// Customizable Area End

// Customizable Area Start

const styles = {
  container: {
    width: '100%',
  },
  box: {
    height: '-webkit-fill-available',
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
  },
  cardHeader: {
    borderBottom: '1px solid #E7E7E7',
    padding: '0 40px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:500px)':{
       justifyContent:'center',
    }
  },
  cardTitle: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: ' 23px',
    textAlign: 'left',
    fontFamily: 'aeonik-bold',
    marginBottom: 0,
    '@media (max-width:500px)':{
      textAlign:'center',
    }
  },
  cardBody: {
    padding: '114px 86px',
    '@media (max-width:500px)':{
      padding:'20px 10px',
    },
    '@media (min-width:501px) and (max-width:600px)':{
      padding:'75px 35px',
    },
  },
  gridWrapper: {
    gap: '50px',
    flexWrap: 'nowrap',
    '@media (max-width:960px)':{
      flexWrap:'wrap'
    },

  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 77px',
  },
  defaultImage: {
    display: "block",
  },
  cardInactive: {
    cursor: "pointer",
    textAlign: "center",
    minHeight: "195px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DEDEDE",
    boxShadow: "0px 3px 10px rgba(160, 159, 159, 0.2)",
    borderRadius: "10px",
    background: "#FFFFFF",
    padding: "30px",
    '@media (max-width:500px)':{
      padding:"10px",
    },
  },
  cardActive: {
    cursor: "pointer",
    textAlign: "center",
    minHeight: "195px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DEDEDE",
    boxShadow: "0px 3px 10px rgba(160, 159, 159, 0.2)",
    borderRadius: "10px",
    background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
    padding: "30px",
    '@media (max-width:500px)':{
      padding:"10px",
    }

  },
  inactiveText: {
    color: 'var(--text, #202020)',
    fontFamily: 'aeonik-bold',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginLeft: '24px',
  },
  activeText: {
    color: 'var(--background, #FBFBFB)',
    fontSize: '24px',
    fontFamily: 'aeonik-bold',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginLeft: '24px',
  },
  cardInfo: {
    fontFamily: 'aeonik-bold',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FBFBFB',
    marginLeft: '25px',
    textAlign: 'left',
  },
  inActiveCardInfo: {
    fontFamily: 'aeonik-bold',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: 'var(--text, #202020)',
    marginLeft: '25px',
    textAlign: 'left',
  },
  btn: {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    color: "white",
    minWidth: '200px',
    right: "12px",
  },
  buttonBox: {
    display: 'flex', justifyContent: 'flex-end',
    padding: '0 40px 40px'
  },
  mainBox: {
    // Define your custom styles here
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    display: 'flex',
    flex: '1 1 0px',
    '@media (max-width:500px)':{
      margin:'40px 25px'
    }
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'column' as const,
    flex: '1 1 0px',
    '@media (max-width:500px)':{
      width:'100%',
    },
    '@media (min-width:501px) and (max-width:1245px)':{
      width:'100%',
    }
  },
};
// Customizable Area End
interface MyData {
  classes: any;
  isAdmin: boolean;
  onAdminCardClick: () => void;
  onUserCardClick: () => void;
}
  // Customizable Area Start

export class AdminCart extends AdminLoginController {

  constructor(props: Props) {
    super(props);
  }
  render(){
    const { classes } = this.props;
    const {isAdmin} = this.state;
  return (
    // Required for all blocks
    
    <div className={classes.containerMain}>
    <div className={classes.mainBox}>
    <Box className={classes.container}>
      <Box className={classes.cardHeader}>
        <Typography variant="h4" align="center" gutterBottom className={classes.cardTitle}>
          Select user type
        </Typography>
      </Box>
      <Box className={classes.cardBody}>
        <Grid container className={classes.gridWrapper}>
          <Grid item xs={12} md={6}>
            <Card data-test-id="Admin-cart" className={classes.cardActive} onClick={this.navigateTo}>
              <CardContent className={classes.cardContent}>
                <img src={VectorAdmin} alt="Hover Logo" className={classes.hoverImage} />
                <Typography className={isAdmin ? classes.activeText : classes.inactiveText}>Admin</Typography>
                <Typography className={isAdmin ? classes.cardInfo : classes.inActiveCardInfo}>If you are an administrator, please register/login to access your admin account and manage the system.</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card data-test-id="User-cart" className={classes.cardInactive} onClick={this.handleUserCardClick}>
              <CardContent className={classes.cardContent}>
                <img src={VectorUser} alt="User Logo" className={classes.defaultImage} />
                <Typography className={!isAdmin ? classes.activeText : classes.inactiveText}>User</Typography>
                <Typography className={!isAdmin ? classes.cardInfo : classes.inActiveCardInfo}>If you have been invited, please log in with your credentials to access your account.</Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>
    </Box >
    </div>
    </div>
    // Customizable Area End
  );}

}

//@ts-ignore
export default withStyles(styles)(AdminCart);