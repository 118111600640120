import React, { Dispatch } from 'react'

const Content: React.FC<ContentProps> = ({
  content,
  setCurrentStep,
  transition,
  currentStep,
  setIsOpen,
  setLoading
}) => {
  return typeof content === 'function'
    ? content({
      setCurrentStep,
      transition,
      currentStep,
      setIsOpen,
      setLoading
    })
    : content
}

export type ContentProps = {
  content: any
  setCurrentStep: Dispatch<React.SetStateAction<number>>
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>
  setLoading?: Dispatch<React.SetStateAction<boolean>>
  currentStep: number
  transition?: boolean
}

export default Content
