import React, { Component } from "react";
import { Chart } from "react-google-charts";


const options = {
    chartArea: { width: "95%", height: "70%", top: 10, bottom: 30 },
    legend: { position: "none" },
    bar: { groupWidth: "0%" },
    focusTarget: "none",
    vAxis: {
        textStyle: { fontSize: 11, color: "#202020" },
        gridlines: { color: "#EEEEEE"},
        minorGridlines: { count: 0 },
        baselineColor: "transparent",
        viewWindow: { min: 0 },
    },
    hAxis: {
        textStyle: { fontSize: 11, color: "#202020" },
        slantedText: false,
    },
};

type Props = {
    data: {
        xlabel: string;
        ylabel: number;
    }[]
}

class GoogleChartClass extends Component<Props> {
    render() {
        const updateData = this.props.data.map((item) => [
            item.xlabel,
            item.ylabel,
            "fill-opacity: 1; stroke-width: 0",
            `users: ${item.ylabel}`
        ]);
        const chartData = [["Date", "Users", { role: "style" }, { role: "tooltip" }], ...updateData];
        return (
            <div>
                <style>
                    {`
                        .google-chart-container rect[fill="#3366cc"] {
                            fill: #4B6EB0 !important;
                            width: 9px !important;
                            rx: 4 !important;
                            ry: 4 !important;
                            stroke-linejoin: round !important;
                            stroke-width: 4 !important;
                            clip-path: inset(0 round 4px) !important;
                            // transform: translateY(4px);
                        }
                        .google-chart-container rect[fill="#3366cc"]:hover {
                            fill: #4B6EB0 !important;
                        }
                    `}
                </style>
                <div className="google-chart-container">
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={chartData}
                        options={options}
                    />
                </div>
            </div>
        );
    }
}

export default GoogleChartClass;
