import { Box, Typography, Slider, styled, TooltipProps, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import { ValueLabelProps } from "@material-ui/core/Slider";

const radioSelected = require("./radioSelected.svg");
const nonSelectCheckBox = require("./nonSelectCheckBox.svg");
const selectCheckBox = require("./selectedCheckBox.svg");

interface TextQuestion {
    question_type: string;
    question: string;
    answer: string;
    options: (string | number)[][];
}

interface RankQuestion {
    question_type: string;
    question: string;
    answer: any;
    options: (string | number)[][];
}

interface RangeQuestion {
    question_type: string;
    question: string;
    answer: number;
    min_value: number | null;
    max_value: number| null;
    min_label: string;
    max_label: string;
}

interface RadioQuestion {
    question_type: string;
    question: string;
    answer: string;
    options: string[];
}

interface CheckBoxQuestion {
    question_type: string;
    question: string;
    answer: string;
    options: (string | number)[][];
}

type QuestionTypes =
    | TextQuestion
    | RankQuestion
    | RangeQuestion
    | RadioQuestion
    | CheckBoxQuestion;

type Props = {
    questionsArray: QuestionTypes[];
};

interface S { }

export default class ReportView extends Component<Props, S> {
    static defaultProps: Partial<Props> = {
        questionsArray: []
    };

    isTextQuestion = (item: QuestionTypes): item is TextQuestion =>
        item.question_type === "short-text";

    isDateQuestion = (item: QuestionTypes): item is TextQuestion =>
        item.question_type === "date";

    isRankQuestion = (item: QuestionTypes): item is RankQuestion =>
        item.question_type === "ranking";

    isRangeQuestion = (item: QuestionTypes): item is RangeQuestion =>
        item.question_type === "slider";

    isRadioQuestion = (item: QuestionTypes): item is RadioQuestion =>
        item.question_type === "radio";

    isCheckBoxQuestion = (item: QuestionTypes): item is CheckBoxQuestion =>
        item.question_type === "checkbox";

    CustomValueLabelComponent(props: ValueLabelProps) {
        const { children, open, value } = props;
        return (
          <LightTooltip
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={<span style={webStyle.tooltipSpan}>{`Score: ${value}`}</span>}
          >
            {children}
          </LightTooltip>
        );
    }

    arrangeRankingOptions = (options: (string | number)[][], answer: number[]) => {
        const newArray: string[] = [];
        answer.forEach(item => {
            options.forEach(value => {
                if(Number(value[1]) === Number(item)) {
                    newArray.push(value[0] as string);
                }
            })
        });

        return newArray;
    };

    renderRankOptions = (options: (string | number)[][], answer: string) => {
        const answerArray = JSON.parse(answer);
        const arrangedOptions = this.arrangeRankingOptions(options, answerArray);
        return (
            <Box style={webStyle.rankMainOptionBox}>
                {arrangedOptions.map((value, index) => (
                    <Box key={index} style={webStyle.rankOptionBox}>
                        <Typography
                            style={{ ...webStyle.textAnswerText, color: "#333333" }}
                        >
                            {value}
                        </Typography>
                        <Box style={webStyle.optionRankTextBox}>
                            <Typography
                                style={webStyle.optionRankText}
                            >
                                {`Rank ${index + 1}`}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        )
    }

    render() {
        const { questionsArray } = this.props;
        return (
            <Box style={webStyle.mainBox}>
                {questionsArray.map((item, index) => (
                    <Box key={index} style={webStyle.mainQuestionBox}>
                        <Box>
                            <Typography
                                style={webStyle.questionText}
                            >
                                {`Q${index+1} ${item.question}`}
                            </Typography>
                            {this.isTextQuestion(item) && (
                                <Typography style={webStyle.textAnswerText}>
                                    {item.answer}
                                </Typography>
                            )}
                            {this.isRankQuestion(item) && (
                                this.renderRankOptions(item.options, item.answer)
                            )}
                            {this.isDateQuestion(item) && (
                                <Typography style={webStyle.textAnswerText}>
                                    {item.answer}
                                </Typography>
                            )}
                            {this.isRangeQuestion(item) && (
                                <Box style={{ marginTop: "60px" }}>
                                    <PrettoSlider
                                        value={Number(item.answer)}
                                        min={Number(item.min_value)}
                                        max={item.max_value? Number(item.max_value): 100}
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                        ValueLabelComponent={this.CustomValueLabelComponent}
                                    />
                                    <Box style={webStyle.rangeMainMinMaxBox}>
                                        <Box style={webStyle.rangeInnerMinMaxBox}>
                                            <Typography style={webStyle.rangeMinMaxLabel}>
                                                {item.min_label}
                                            </Typography>
                                            <Typography style={webStyle.rangeMinMaxValue}>
                                                {item.min_value}
                                            </Typography>
                                        </Box>
                                        <Box style={webStyle.rangeInnerMinMaxBox}>
                                            <Typography style={webStyle.rangeMinMaxLabel}>
                                                {item.max_label}
                                            </Typography>
                                            <Typography style={webStyle.rangeMinMaxValue}>
                                                {item.max_value}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            {this.isRadioQuestion(item) && (
                                <Box style={webStyle.radioMainOptionBox}>
                                    {item.options.map((value, index) => (
                                        <React.Fragment key={index}>
                                            {Number(value[1]) === Number(item.answer) ? (
                                                <Box style={webStyle.radioOptionBox}>
                                                    <img src={radioSelected} alt="radio_selected" />
                                                    <Typography
                                                        style={{
                                                            ...webStyle.radioOptionText,
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {value[0]}
                                                    </Typography>
                                                    <Typography style={webStyle.yourAnswerText}>
                                                        {"(User answer)"}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box style={webStyle.radioOptionBox}>
                                                    <img src={nonSelectCheckBox} alt="non_selected" />
                                                    <Typography style={webStyle.radioOptionText}>
                                                        {value[0]}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Box>
                            )}
                            {this.isCheckBoxQuestion(item) && (
                                <Box style={webStyle.radioMainOptionBox}>
                                    {item.options.map((value, index) => {
                                        const getAnwersArray = item.answer.split(", ").map(Number);
                                        return (
                                        <React.Fragment key={index}>
                                            {getAnwersArray.includes(value[1] as number) ? (
                                                <Box style={webStyle.radioOptionBox}>
                                                    <img src={selectCheckBox} alt="checkBox_selected" />
                                                    <Typography
                                                        style={{
                                                            ...webStyle.radioOptionText,
                                                            fontWeight: 600
                                                        }}
                                                    >
                                                        {value[0]}
                                                    </Typography>
                                                    <Typography style={webStyle.yourAnswerText}>
                                                        {"(User answer)"}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box style={webStyle.radioOptionBox}>
                                                    <img src={nonSelectCheckBox} alt="non_selected" />
                                                    <Typography style={webStyle.radioOptionText}>
                                                        {value[0]}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    )})}
                                </Box>
                            )}
                        </Box>
                    </Box>
                ))}
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
                </style>
            </Box>
        );
    }
}

const webStyle = {
    mainBox: {
        background: "#FFFFFF",
        padding: "24px",
        display: "flex",
        gap: "8px",
        borderRadius: "0px 0px 18px 18px",
        flexDirection: "column"
    } as React.CSSProperties,
    mainQuestionBox: {
        background: "#E4E8F2",
        padding: "16px",
        borderRadius: "4px"
    },
    rangeInnerMinMaxBox: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    } as React.CSSProperties,
    rangeMinMaxLabel: {
        fontSize: "14px",
        color: "#202020",
        fontWeight: 500,
        fontFamily: "Raleway"
    },
    rangeMinMaxValue: {
        fontSize: "20px",
        color: "#000",
        fontWeight: 600,
        marginTop: "5px",
        fontFamily: "Raleway"
    },
    rangeMainMinMaxBox: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "5px"
    },
    tooltipSpan: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#202020",
        fontFamily: "Raleway"
    },
    radioMainOptionBox: {
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "24px"
    } as React.CSSProperties,
    radioOptionBox: {
        display: "flex",
        alignItems: "center",
        gap: "6px"
    },
    radioOptionText: {
        fontSize: "16px",
        color: "#202020",
        fontWeight: 500,
        fontFamily: "Raleway"
    },
    yourAnswerText: {
        fontSize: "12px",
        color: "#202020",
        fontWeight: 400,
        fontFamily: "Raleway"
    },
    questionText: {
        fontSize: "14px",
        color: "#202020",
        fontWeight: 700,
        fontFamily: "Raleway"
    },
    textAnswerText: {
        fontSize: "14px",
        color: "#202020",
        fontWeight: 500,
        marginTop: "8px",
        fontFamily: "Raleway"
    },
    rankMainOptionBox: {
        marginTop: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    } as React.CSSProperties,
    rankOptionBox: {
        padding: "12px",
        background: "#F4F6F8",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
    },
    optionRankTextBox: {
        padding: "8px",
        borderRadius: "6px",
        background: "#E4E8F2",
        height: "fit-content"
    },
    optionRankText: {
        fontSize: "16px",
        fontWeight: 600,
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        whiteSpace: "nowrap",
        fontFamily: "Raleway"
    } as React.CSSProperties
};

const PrettoSlider = styled(Slider)({
    height: 8,
    "& .MuiSlider-thumb": {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "4px solid #1C386D",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "inherit"
        },
    },
    "& .MuiSlider-track": {
        height: 8,
        borderRadius: 4,
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    },
    "& .MuiSlider-rail": {
        height: 8,
        borderRadius: 4,
        background: "#fff",
        opacity: "100"
    },
    "& .MuiSlider-valueLabel": {
        left: "calc(-50% + 4px)"
    }
});

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
  ))({
    "&.MuiTooltip-tooltip": {
      backgroundColor: "white",
      color: "#202020",
      fontSize: "14px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "6px",
      marginBottom: "4px"
    }
});