import React, {
    FunctionComponent,
} from "react";

import { withStyles } from "@material-ui/core/styles";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

interface Props {
    graphData: any;
    classes: any;
}
const DateTypeChart: FunctionComponent<Props> = ({
    graphData,
    classes
}) => {
    const CustomTooltip = ({ active, payload ,coordinate }: any) => {
        const { x, y } = coordinate || {};
    
        if (active && payload && payload.length) {
            const dataPoint = payload[0].payload;
            return (
                <>
                {
                    dataPoint.resp !==0 &&
                <div className={classes.customTooltip} style={{left: x-25,top: y - 30,position: 'absolute',pointerEvents: 'none',transform: 'translateX(-50%)',whiteSpace: 'nowrap',}}>
                    <p>{dataPoint.resp}</p>
                </div>
                }
                </>
            );
        }
        return null
      };
      const CustomizedTick = ({ x, y , angle , payload , axis}: any) => {
        return (
          <text
            x={axis=='Xaxis' ? "45" : x}
            y={axis=='Xaxis' ? y :'265'}
            textAnchor="middle"
            fill="#535557"
            fontFamily="aeonik-regular"
          >
            {payload.value}
          </text>
        );
      };
    return (
        <div style={{ marginTop: "20px" }}>
            <ResponsiveContainer className={classes.chart} width="100%" height={300} >
                <BarChart
                    data={graphData}
                    margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
                >
                    <CartesianGrid vertical={false} stroke="#ccc" strokeDasharray={5} />
                    <XAxis dataKey="name" tick={<CustomizedTick axis="Yaxis"/>} interval={0}/>
                    <YAxis tick={<CustomizedTick  axis="Xaxis"/> }/>
                    <Tooltip content={CustomTooltip} />
                    <Legend wrapperStyle={{ fontWeight: 900 }} />
                    <Bar
                        name="Date Type"
                        dataKey="resp"
                        fill="#304a7c"
                        barSize={12}
                        radius={[10, 10, 0, 0]}
                        shape={<CustomBarShape />}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
const CustomBarShape: React.FunctionComponent<any> = (props) => {
    const { x, y, width, height, value } = props;

    if (x === undefined || y === undefined || width === undefined || height === undefined) {
        return null;
    }
    const shouldRenderCircle = value !== undefined && value !== 0;
    return (
        <g>
            <rect x={x} y={y} width={width} height={height} fill="#304a7c" />
            {shouldRenderCircle && (
                <circle cx={x + width / 2} cy={y} r={8} fill="#304a7c" stroke="#fff" strokeWidth={2} />
            )}
        </g>
    );
};
const styles = {
    chart: {
        backgroundColor: "rgba(189, 201, 226, 0.57)",
        borderRadius: "10px",
        '& ..QuestionAnalysis-contentData-52': {
            padding: "10px"
        },
        '& .recharts-cartesian-axis-line': {
            display: "none",
        },
        '& .recharts-tooltip-cursor': {
            fill: "transparent",
        },
        '& .recharts-legend-item-text': {
            display: "none"
        },
        '& .recharts-legend-icon': {
            display: "none"
        },
        '& .recharts-cartesian-axis-tick-line':{
            display: "none"
        }
    },
    customTooltip: {
        padding: "0px 11px",
        backgroundColor: "#fff",
        borderRadius: "4px",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.1)",
        fontFamily: "'Poppins', sans-serif",
        fontSize: "14px",
        color: "#333",
        border: "1px solid #ddd",
      }
};

export default withStyles(styles)(DateTypeChart);
