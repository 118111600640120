import React from "react";

import {
  Button,
  Typography,
  // Customizable Area Start
  Snackbar,
  Portal,
  Dialog,
  DialogContent,
  DialogActions,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import Card from "../../../components/src/Card.web";
import SurveyQuestionSidebar from '../../../components/src/SurveyQuestionSidebar.web';
import RenderSurveyQuestion from "../../../components/src/RenderSurveyQuestion.web";
import ScheduleSurveyDialog from "../../../components/src/ScheduleSurveyDialog.web";

import {
  plusSVG
} from "./assets";

// Customizable Area End

import CfSurveyQuestions2Controller, {
  Props
} from "./CfSurveyQuestions2Controller.web";
import SurveyQuestionsTourSteps from "../../../components/src/Tour/SurveyQuestionsTourSteps";
import { withTour } from "../../../components/src/Tour";

export class CfSurveyQuestions2 extends CfSurveyQuestions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { dropBoxes } = this.state;
    return (
      // Customizable Area Start
      <div style={{ flex: 1, width: '100%' }}>
        <SurveyQuestionsTourSteps navigate={this.props.navigation}/>
        <Card title={'Surveys'}>
          <div style={webStyle.container}>
            <SurveyQuestionSidebar 
              isPastorLiveSurvey={this.state.isLiveorPastSurvey}
              data-test-id='sidebar'
              activeItem={this.state.activeItem}
              selectedItem={this.state.selectedItem}
              onItemHover={this.onItemHover}
              onMouseLeave={this.onMouseLeave}
              onItemClick={this.onItemClick}
              onDragStart={this.handleDragStart}
              onDragEnter={this.onDragEnter}
              onDragEnd={this.onDrop}
              addSampleData={this.addSampleData}
              onHeaderClick={this.handleAddBox}
              onStandaloneClick={this.handleAddIndividualSection}
              isIndividualSection={this.state.isIndividualSection}
              openModalForTour={this.openModalForTour}
            />


            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px', width: '100%' }}>
              <RenderSurveyQuestion
                isLiveorPastSurvey={this.state.isLiveorPastSurvey}
                dropBoxes={dropBoxes}
                onDragEnd={this.onDragEnd2}
                handleDeleteBox={this.handleDeleteBox}
                handleDrop={this.handleDrop}
                handleHeaderChange={this.handleHeaderChange}
                handleRemoveItem={this.handleRemoveItem}
                handleDragOver={this.handleDragOver}
                handleItemChange={this.handleItemChange}
                handleDeleteClick={this.handleDeleteClick}
                handleInputChange={this.handleInputChange}
              />

              {!this.state.isLiveorPastSurvey &&
                (
                  <div id="survey-render" data-test-id="add-new-section" onClick={this.handleAddBox} style={webStyle.addNewField} >
                    <Typography style={webStyle.addNewText} >Add new section</Typography>
                    <div style={webStyle.plusIcon}>
                      <img src={plusSVG} alt="add" />
                    </div>
                  </div>
                )}
            </div>

          </div>
        </Card>
        <div style={webStyle.btnContainer}>
          <div>
            <Button data-test-id='questionCancelBtn' onClick={this.navigateToAdminSurvey} style={webStyle.secondaryBtn}>Cancel</Button>
          </div>
          <div>
            <Button
              disabled={this.state.isLiveorPastSurvey}
              onClick={() => this.handleSaveChangesClick('save')}
              style={
                this.state.isLiveorPastSurvey 
                  ? { 
                      ...webStyle.secondaryBtn, 
                      backgroundColor: '#f3f3f3', 
                      color: '#a5a7ab', 
                      borderColor: '#a5a7ab', 
                      cursor: 'not-allowed' 
                    } 
                  : webStyle.secondaryBtn
              }
              data-test-id="save-survey-change-btn"
            >
              Save changes
            </Button>
            <Button
            id="share-button"
              disabled={this.state.isLiveorPastSurvey}
              onClick={() => this.handleSaveChangesClick('share')}
              style={
                this.state.isLiveorPastSurvey 
                  ? { 
                      ...webStyle.secondaryBtn, 
                      backgroundColor: '#9da4ae', 
                      color: '#e3e6eb', 
                      borderColor: '#a5a7ab', 
                      cursor: 'not-allowed' 
                    } 
                  : webStyle.primaryBtn
              }
              data-test-id="share-survey-btn"
            >
              Share
            </Button>
          </div>
        </div>
        <ScheduleSurveyDialog
          data-test-id="moment"
          visible={this.state.scheduleDialogVisible}
          onChange={this.handleChange}
          data={this.state.scheduleSurvey}
          onSubmitScheduleSurvey={this.handleSubmitSchduleSurvey}
          onClose={this.handleDialogClose}
          questions={this.state.renderQuestions}
          groupList={this.state.groupList}
          userList = {this.state.userList}
          handleSelectedGroupList = {this.handleSelectedGroupList}
          removeUsers = {this.removeUsers}
          addUsers = {this.addUsers}
        />
        <Portal>
          <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            autoHideDuration={3000}
            open={this.state.snackbar.open}
            onClose={this.handleCloseSnackbar}
          >
            <Alert severity={this.state.snackbar.severity}>
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>
        </Portal>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.showDeleteConfirmDialog}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "15px",
              top: 100,
              position: "absolute",
            },
          }}
        >
          <DialogContent>
            <Typography style={webStyle.Error} gutterBottom>
              Delete section
            </Typography>
            <Typography style={webStyle.confrim} gutterBottom>
              Deleting this section will permanently <br />remove all the questions within it.
              <br />
              <br />
              Are you sure you want to continue?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div style={webStyle.modalbtn}>
                <Button
                  data-test-id="modal-Continue-btn"
                  style={webStyle.edit}
                  onClick={this.handleDeleteBoxConfirmed}
                >
                  Delete
                </Button>

                <Button
                  data-test-id="modal-Cancel-btn"
                  style={webStyle.close}
                onClick={this.handleCancelDeleteModal}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  container: {
    display: 'flex',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column' as const,
    borderRadius: '12px',
    background: 'var(--basic-white, #FFF)',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    width: '275px',
    height: '100%',
  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-bold',
    fontSize: "20px",
    fontWeight: 700,
    textTransform: "none" as const
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "black",
  },
  modalbtn: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "15px",
  },
  edit: {
    padding: "18px 55px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    background: "var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))",
    fontSize: "16px",
    fontWeight: 700,
    color: "rgba(255, 255, 255, 1)",
    lineHeight: "normal",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  confrim: {
    textAlign: "center" as const,
    marginTop: "20px",
    fontFamily: 'aeonik-regular',
    // fontWeight: 600,
  },
  img: {
    width: '24px',
    height: '24px'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
    cursor: 'pointer'
  },
  text: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    color: 'rgba(121, 121, 121, 1)'
  },
  active: {
    width: '4px',
    borderRadius: '5px 0px 0px 5px',
    height: '56px',
  },
  questionBlock: {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '0px 24px',
    flex: '1 1 0px'
  },
  addNewField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0px',
    borderRadius: '28px',
    border: '0.5px dashed #A7A7A7',
    cursor: 'pointer'
  },
  plusIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '42px',
    height: '42px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    marginLeft: '21px'
  },
  addNewText: {
    color: '#212529',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-bold'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 40px 24px 40px'
  },
  secondaryBtn: {
    borderRadius: '10px',
    padding: '18px 32px',
    marginRight: '16px',
    border: '1px solid #1C386D',
    color: '#1C386D',
    fontFamily: 'aeonik-bold',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: 'none' as const
  },
  primaryBtn: {
    borderRadius: '10px',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    padding: '18px 32px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'aeonik-bold',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: 'none' as const
  },
};
export default withTour(CfSurveyQuestions2)
// Customizable Area End
