import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, HeaderProps, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './customCalender.css';
import EditIcon from '@material-ui/icons/Edit';
import { Box, Chip, IconButton } from '@material-ui/core';

function CustomToolbar({ label, setCurrentView, onNavigate, onDateChange }: any) {
    const dateRef = useRef<any>()
    return (
        <div className="toolbar">
            <button onClick={() => onNavigate('TODAY')} className="toolbar-button">
                Today
            </button>
            <button onClick={() => {
                setCurrentView("week")
                onNavigate('PREV')
            }}
                className="toolbar-button">
                ←
            </button>
            <div className='date-box'>
                <DatePicker
                    ref={dateRef}
                    selected={moment(label).toDate()}
                    onChange={(date) => {
                        setCurrentView("week");
                        onDateChange(date);
                    }}
                    dateFormat="dd MMMM yyyy"
                    className="datepicker-input"
                />
            </div>
            <button onClick={() => { setCurrentView("week"); onNavigate('NEXT') }} className="toolbar-button">
                →
            </button>
        </div>
    );
}

function EventComponent(props: any) {
    return (
        <div className={`calendar-event ${props.event.status}`}>
            <Box className='double-event' style={{ backgroundColor: props.event.status === 'live' ? '#E7F2DA' : (props.event.status === 'past') ? '#EBEBEB' : '#E8EBFA' }}>
                <div className='full_event'>
                    <span className=''>{props.title}</span>
                    <Chip
                        label={props.event.status.charAt(0).toUpperCase() + props.event.status.slice(1)}
                        style={{
                            marginTop: '8px',
                            width: 'fit-content',
                            height: 'fit-content',
                            padding: '3px',
                            position: 'relative',
                            backgroundColor: props.event.status === 'live' ? '#BDDA9B' : (props.event.status === 'past') ? '#CFCFCF' : '#BFCAE4',
                            color: props.event.status === 'live' ? '#237B4B' : (props.event.status === 'past') ? '#5A5A5A' : '#4B6EB0'
                        }}
                    />
                    {(props.event.status !== 'past') && <div>
                        <IconButton size='small' style={{ position: 'absolute', top: '25px', right: '8px', color: "#455984" }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>}
                </div>
            </Box>
        </div>
    );
}

function WeekHeader(props: any) {

    const daysMap: { [key: string]: string } = {
        Sun: 'Sunday',
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday'
    };

    const day = props.label.split(" ")
    return (
        <div className="header">
            <span className='header-day'>
                {daysMap[day[1]]}
            </span>
            <span className='header-date'>
                {day[0]}
            </span>
        </div>
    );
}

function CalendarWithDatePicker(props: any) {
    const localizer = momentLocalizer(moment);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentView, setCurrentView] = useState<"month" | "week" | "day">("week");
    const [isEdit, setIsEdit] = useState(false);
    const [eventDetails, setEventDetails] = useState<any>(null);

    const eventPropGetter = (event: any) => {
        const backgroundColor = event.status === "Open" ? "#E7F2DA" : "#e6e6fa";
        return {
            style: {
                backgroundColor,
                color: "#424242",
                borderRadius: "5px",
            },
        };
    };

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    const handleSelectSlot = (slotInfo: any) => {
        setSelectedDate(slotInfo.start);
        setCurrentView("day");
    };

    const handleNavigate = (action: string) => {
        if (action === 'TODAY') {
            const startOfWeek = moment().startOf('week').toDate();
            setSelectedDate(startOfWeek);
        } else if (action === 'PREV') {
            setSelectedDate((prev) => moment(prev).subtract(1, 'week').startOf('week').toDate());
        } else if (action === 'NEXT') {
            setSelectedDate((prev) => moment(prev).add(1, 'week').startOf('week').toDate());
        }
    };


    const handleEventClick = (event: any) => {
        if (event.status === "past")
            return
        props.getCalendarEventData(event)
        setEventDetails(event);
        setIsEdit(true);
        props.handleOpenMoadal()
    };

    const segmentedData = useMemo(() => {
        return {
            live: props.events.filter((event: any) => event.status === 'live'),
            scheduled: props.events.filter((event: any) => event.status === 'scheduled'),
            past: props.events.filter((event: any) => event.status === 'past'),

        }
    }, [props.events])

    const hideEmptyRows = () => {
        document.querySelectorAll(".hide-content").forEach((container, idx) => {
            const hasEvents = container.querySelector(".full_event") !== null;
            if (idx === 0) {
                if (!hasEvents) {
                    container.querySelector(".rbc-time-content")?.classList.add("hidden");
                } else {
                    // container.querySelector(".rbc-time-content")?.classList.remove("hidden");
                }
            } else {
                if (!hasEvents) { container?.classList.add("hidden") }
                else {
                    container?.classList.remove("hidden");
                    container.querySelector(".rbc-time-content")?.classList.add("hidden");
                }
            }
        });
    };

    useEffect(() => {
        hideEmptyRows();
    }, [segmentedData, selectedDate, currentView]);

    return <>{Object.entries(segmentedData).map(([key, data]: [string, any[]], idx: number) => {
        return (
            <div className='hide-content'>
                <Calendar
                    localizer={localizer}
                    events={data}
                    startAccessor="start"
                    endAccessor="end_calendar"
                    view={currentView}
                    date={selectedDate}
                    onNavigate={(date) => setSelectedDate(date)}
                    defaultView="week"
                    eventPropGetter={eventPropGetter}
                    selectable={true}
                    step={180}
                    timeslots={1}
                    onSelectEvent={handleEventClick}
                    components={{
                        ...idx === 0 ? {
                            toolbar: (props) => (
                                <CustomToolbar
                                    {...props}
                                    label={selectedDate}
                                    setCurrentView={setCurrentView}
                                    onNavigate={handleNavigate}
                                    onDateChange={handleDateChange}
                                />
                            ),
                            week: { header: (props: React.PropsWithChildren<HeaderProps>) => <WeekHeader {...props} /> },
                        } : {
                            toolbar: (props) => <></>,
                            week: { header: (props: React.PropsWithChildren<HeaderProps>) => <div className='hidden'></div> },
                        },
                        event: (props) => <EventComponent {...props} />,
                    }}
                    style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                    }}
                />
            </div>)
    })}
    </>;
}

export default CalendarWithDatePicker;