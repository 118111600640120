import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Card {
  title: string;
  body: string;
  price: string;
  subscribed: boolean;
};
type Subscription = {
  id?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  plan?: string;
};

interface Plan {
  id: number;
  name: string;
  desc: string;
  price: string;
  duration: string;
  benefits: string[];
};

type BillingDetails = {
  card_number: string;
  name?: string;
  email?: string;
  address?: {
    line1?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
  };
};

type UserPlan = {
  subscription: Subscription;
  billing_details: BillingDetails;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  elements?: any;
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPlan: Plan;
  currentPlanSubscription: UserPlan;
  token: string;
  subscriptions: any;
  data: any;
  isOpen: boolean;
  isOpenCardCancel: boolean;
  changePayment: number;
  isSubscribed: boolean;
  selectCountry: string;
  cardHolderName: string;
  cardNumber: string;
  formatedCardNumber: string;
  expDate: string;
  cvcNumber: string;
  maskedCvc: string;
  strtAddOne: string;
  strtAddTwo: string;
  aptUnit: string;
  city: string;
  postCode: string;
  countryAlt: string;
  // plansList: Plan[];
  errCardHolderName: string;
  errCardNumber: string;
  errExpDate: string;
  errCvc: string;
  errStrtAddOne: string;
  errCity: string;
  errSelectCountry: string;
  errPostCode: string;
  errCountryAlt: string;
  checkPayClick: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: string="";
  cancelSubscriptionCallId: string="";
  cancelCardSubscriptionCallId: string = "";
  getSubscriptionCallId: string = "";
  renewSubscriptionCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      selectedPlan: {
        id: 0,
        name: "",
        desc: "",
        price: "",
        duration: "",
        benefits: [],
      },
      isSubscribed: false,
      currentPlanSubscription: {
        "subscription": {
          id: "",
          status: "inactive",
          start_date: "",
          end_date: "",
          plan: "",
        },
        "billing_details": {
          name: "",
          email: "",
          card_number: "",
          address: {
            line1: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
          },
        },
      },
      selectCountry: "",
      cardHolderName: "",
      cardNumber: "",
      formatedCardNumber: "",
      expDate: "",
      cvcNumber: "",
      maskedCvc: "",
      strtAddOne: "",
      strtAddTwo: "",
      aptUnit: "",
      city: "",
      postCode: "",
      countryAlt: "",
      errCardHolderName: "",
      errCardNumber: "",
      errExpDate: "",
      errCvc: "",
      errStrtAddOne: "",
      errCity: "",
      errSelectCountry: "",
      errPostCode: "",
      errCountryAlt: "",
      checkPayClick: false,
      token: "",
      subscriptions: [],
      data: null,
      isOpen: false,
      isOpenCardCancel: false,
      changePayment: 1
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.getPlanSubscriptionPlan();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data
          });
        }
        else if (apiRequestCallId === this.cancelSubscriptionCallId) {
          this.cancelSubscription(responseJson);
        }
        else if (apiRequestCallId === this.getSubscriptionCallId) {
          this.getUserSubscription(responseJson);
        }
        else if (apiRequestCallId === this.cancelCardSubscriptionCallId) {
          this.deleteCard(responseJson);
        }
        else if (apiRequestCallId === this.renewSubscriptionCallId) {
          this.handleRenewResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  canncelSubscriptionPlan = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelSubscription}subscription_id=${this.state.currentPlanSubscription?.subscription?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    this.cancelSubscriptionCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  canncelCardDetail = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelCard}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    this.cancelCardSubscriptionCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  getPlanSubscriptionPlan = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscription
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getSubscriptionCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item: any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  cancelSubscription(response: {}) {
    this.setState({ isOpen: false })
  }

  deleteCard(response: {}) {
    this.setState({ isOpenCardCancel: false })
  }

  handleRenewResponse(response: {}) {
    this.setState({ isOpenCardCancel: false })
  }

  handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let { value } = event.target;
    if (typeof value === "string") {
      this.setState({
        ["selectCountry"]: value,
      } as unknown as Pick<S, keyof S>, () => this.handleErrCheck());
    }
  };

  handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rawValue = event.target.value;
    let newValue = rawValue.replace(/\D/g, "");
    if (newValue.length > 7) {
      newValue = newValue.slice(0, 7);
    }
    this.setState(
      {
        postCode: newValue,
      },
      () => this.handleErrCheck()
    );
  };

  handleBackClick = () => {
    this.setState({
      selectedPlan: {
        id: 0,
        name: "",
        desc: "",
        price: "",
        duration: "",
        benefits: [],
      },
      selectCountry: "",
      cardHolderName: "",
      cardNumber: "",
      formatedCardNumber: "",
      expDate: "",
      cvcNumber: "",
      maskedCvc: "",
      strtAddOne: "",
      strtAddTwo: "",
      aptUnit: "",
      city: "",
      postCode: "",
      countryAlt: "",
      errCardHolderName: "",
      errCardNumber: "",
      errExpDate: "",
      errCvc: "",
      errStrtAddOne: "",
      errCity: "",
      errSelectCountry: "",
      errPostCode: "",
      errCountryAlt: "",
      checkPayClick: false,
      changePayment: 2,
    });
  };

  formatMonth = (monthGet: string, formattedYear: string) => {
    let updateMonth = monthGet.replace(/[^\d/]/g, "");
    let month = parseInt(updateMonth, 10);
    if (month < 1 || Number.isNaN(month)) {
      month = 1;
    }
    if (month > 12) {
      month = 12;
    }
    if (formattedYear.length === 4) {
      let year = parseInt(formattedYear, 10);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (year === currentYear && month < currentMonth) {
        month = currentMonth;
      }
    }
    if (month < 10) {
      return `0${month}`;
    } else {
      return `${month}`;
    }
  };

  formatYear = (yearGet: string) => {
    let updateYear = yearGet.replace(/[^\d/]/g, "");
    if (updateYear.length > 3) {
      let year = parseInt(updateYear, 10);

      const currentYear = new Date().getFullYear();
      if (year < currentYear || Number.isNaN(year)) {
        year = currentYear;
      }
      if (year > 2099 || Number.isNaN(year)) {
        year = 2099;
      }
      return `${year}`;
    }
    return `${updateYear}`;
  };

  handleExpDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    let newValue = "";
    if (inputValue.includes("/")) {
      let [month, year] = inputValue.split("/");
      let formattedYear = this.formatYear(year);
      let formattedMonth = this.formatMonth(month, formattedYear);

      newValue = `${formattedMonth}/${formattedYear}`;
    } else {
      if (inputValue.length > 2) {
        let [month, year] = [inputValue.slice(0, 2), inputValue.slice(2)];
        let formattedYear = this.formatYear(year);
        let formattedMonth = this.formatMonth(month, formattedYear);

        newValue = `${formattedMonth}/${formattedYear}`;
      } else {
        newValue = inputValue.replace(/[^\d/]/g, "");
      }
    }
    newValue = newValue.slice(0, 7);
    this.setState(
      {
        expDate: newValue,
      },
      () => this.handleErrCheck()
    );
  };

  handleCvcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { cvcNumber } = this.state;
    let rawValue = event.target.value;
    let value = rawValue.replace(/\D/g, "");
    let cleanMasked = rawValue.replace(/\s/g, "");
    if (cleanMasked.length < 3) {
      cleanMasked = cvcNumber.slice(0, cleanMasked.length);
    } else {
      cleanMasked = cvcNumber.slice(0, 3);
    }
    let newValue = cleanMasked + value;
    if (newValue.length > 3) {
      newValue = newValue.slice(0, 3);
    }
    let maskedValue = "";
    let valueLength = newValue.length;
    for (let i = 0; i < valueLength; i++) {
      maskedValue += "X";
    }
    this.setState(
      {
        cvcNumber: newValue,
        maskedCvc: maskedValue,
      },
      () => this.handleErrCheck()
    );
  };

  handleCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { cardNumber } = this.state;
    let rawValue = event.target.value;
    let value = rawValue.replace(/\D/g, "");
    let cleanMasked = rawValue.replace(/\s/g, "");
    if (cleanMasked.length < 13) {
      cleanMasked = cardNumber.slice(0, cleanMasked.length);
    } else {
      cleanMasked = cardNumber.slice(0, 12);
    }
    let newValue = cleanMasked + value;
    if (newValue.length > 16) {
      newValue = newValue.slice(0, 16);
    }
    let maskedValue = "";
    let valueLength = newValue.length;
    for (let i = 0; i < valueLength; i++) {
      if (i <= 12) {
        if (i === 4 || i === 8) {
          maskedValue += " X";
        } else if (i === 12) {
          maskedValue += " " + newValue[i];
        } else {
          maskedValue += "X";
        }
      } else {
        maskedValue += newValue[i];
      }
    }
    this.setState(
      {
        cardNumber: newValue,
        formatedCardNumber: maskedValue
      },
      () => this.handleErrCheck()
    );
  };

  handlePayClick = async () => {
    const authToken = localStorage.getItem("token");
    this.setState({ checkPayClick: true });

    const isError = this.handleErrCheck();
    if (!isError) return
    this.setState({ changePayment: 3, checkPayClick: false });
    let payload = {
      subscription: {
        subscription_plan_id: 25,
        token_stripe: "Use the generated Stripe token",
        address_address_1: this.state.city,
        address_address_2: this.state.city,
        city: this.state.city,
        postcode: this.state.postCode
      }
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    this.renewSubscriptionCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleErrCheck = () => {
    const {
      selectCountry,
      cardHolderName,
      cardNumber,
      expDate,
      cvcNumber,
      strtAddOne,
      city,
      postCode,
      countryAlt
    } = this.state;

    let errCardHolderName = "",
      errCardNumber = "",
      errExpDate = "",
      errCvc = "",
      errStrtAddOne = "",
      errCity = "",
      errSelectCountry = "",
      errPostCode = "",
      errCountryAlt = "";
    if (cardHolderName.length === 0) {
      errCardHolderName = "Invalid Card Holder Name";
    }
    if (cardNumber.length === 0 || cardNumber.length < 16) {
      errCardNumber = "Invalid Card Number";
    }
    if (expDate.length === 0 || expDate.length < 7) {
      errExpDate = "Invalid Expiry Date";
    }
    if (cvcNumber.length === 0 || cvcNumber.length < 3) {
      errCvc = "Invalid CVV";
    }
    if (strtAddOne.length === 0) {
      errStrtAddOne = "Invalid Street Address 1";
    }
    if (city.length === 0) {
      errCity = "Invalid City";
    }
    if (selectCountry.length === 0) {
      errSelectCountry = "Invalid Selected Country";
    }
    if (postCode.length === 0 || postCode.length < 6) {
      errPostCode = "Invalid Postcode";
    }
    if (countryAlt.length === 0) {
      errCountryAlt = "Invalid Country";
    }
    let isError = {
      errCardHolderName,
      errCardNumber,
      errExpDate,
      errCvc,
      errStrtAddOne,
      errCity,
      errSelectCountry,
      errPostCode,
      errCountryAlt
    };
    this.setState({
      errCardHolderName,
      errCardNumber,
      errExpDate,
      errCvc,
      errStrtAddOne,
      errCity,
      errSelectCountry,
      errPostCode,
      errCountryAlt
    });

    return Object.values(isError).some((value) => value.length !== 0);
  };


  handleInputChange =
    (prop: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [prop]: event.target.value } as unknown as Pick<
        S,
        keyof S
      >, () => this.handleErrCheck());
    };

  getUserSubscription(response: UserPlan) {
    if (response.subscription)
      this.setState({ currentPlanSubscription: response })
    else {
      this.setState({ isSubscribed: true })
    }
  }
  // Customizable Area End
}
