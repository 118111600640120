import { useEffect, useCallback, useState } from 'react'
import { inView, smoothScroll, getWindow, getRect } from '../utils'
import { StepType } from './type.d'

let initialState = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  windowWidth: 0,
  windowHeight: 0,
  x: 0,
  y: 0,
}

type ScrollLogicalPosition = 'center' | 'end' | 'nearest' | 'start'
type ScrollBehavior = 'auto' | 'smooth'

type ScrollIntoViewOptions = {
  behavior?: ScrollBehavior
  block?: ScrollLogicalPosition
  inline?: ScrollLogicalPosition
}

export function useSizes(
  step: StepType,
  scrollOptions: ScrollIntoViewOptions & {
    inViewThreshold?: number | { x?: number; y?: number }
  } = {
      block: 'center',
      behavior: 'smooth',
      inViewThreshold: 0,
    },
  currentStep: number,
  loading: boolean
) {
  const [transition, setTransition] = useState(false)
  const [refresher, setRefresher] = useState(0)
  const [dimensions, setDimensions] = useState(initialState);
  const scrollHeight = useScrollHeight()
  const target = loading ? null :
    step?.selector instanceof Element
      ? step?.selector
      : document.querySelector(step?.selector || ('.ced-tour-step--' + currentStep)) || null

  const handleResize = useCallback(() => {
    const { ...newDimensions }: any = getHighlightedRect(
      target
    )
    if (
      Object.entries(dimensions).some(
        ([key, value]) => newDimensions[key] !== value
      )
    ) {
      if (newDimensions.height >= newDimensions.windowHeight) {
        if (newDimensions?.bottom < newDimensions.windowHeight) {
          newDimensions.height = newDimensions.bottom
        }
        if (newDimensions?.top < 0 && newDimensions.bottom >= newDimensions.windowHeight) {
          newDimensions.height = newDimensions.windowHeight
        }
      }
    }
    setDimensions(JSON.parse(JSON.stringify(newDimensions)));
  }, [target, dimensions, loading, refresher])

  useEffect(() => {
    if (target === null && refresher !== 10) {
      setRefresher((e) => e + 1);
      return
    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [target, refresher, scrollHeight, loading])

  useEffect(() => {
    const isInView = inView({
      ...dimensions,
      threshold: scrollOptions.inViewThreshold,
    })
    if (!isInView && target) {
      setTransition(true)
      smoothScroll(target, scrollOptions)
        .then(() => {
        })
        .finally(() => {
          setTransition(false)
        })
    }
  }, [dimensions])

  return {
    sizes: dimensions,
    transition,
    target,
  }
}

function getHighlightedRect(
  node: Element | null,
  bypassElem = true
) {
  const { w: windowWidth, h: windowHeight } = getWindow()

  let attrs = getRect(node)
  let altAttrs = {
    bottom: 0,
    height: 0,
    left: windowWidth,
    right: 0,
    top: windowHeight,
    width: 0,
  }

  const bypassable =
    bypassElem || !node ? altAttrs.width > 0 && altAttrs.height > 0 : false
  return {
    left: (bypassable ? altAttrs : attrs).left,
    top: (bypassable ? altAttrs : attrs).top,
    right: (bypassable ? altAttrs : attrs).right,
    bottom: (bypassable ? altAttrs : attrs).bottom,
    width: (bypassable ? altAttrs : attrs).width,
    height: (bypassable ? altAttrs : attrs).height,
    windowWidth,
    windowHeight,
    x: attrs.x,
    y: attrs.y,
  }
}


export function useScrollHeight() {
  const [scrollTop, setScrollTop] = useState<number>(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return scrollTop;
}