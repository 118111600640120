Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.postMethod = "POST";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.poundCurrency = "£";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.cancelSubscription = "bx_block_subscriptions/subscriptions/cancel_plan?";
exports.getSubscription = "bx_block_subscriptions/subscriptions/user_subscriptions";
exports.cancelCard = "bx_block_subscriptions/subscriptions/remove_card";
exports.getPlanCard = "bx_block_stripe_integration/subscription_plan";
exports.paymentUrl = "";
exports.back = "Back";
exports.getStartNow = "Get Started Now";
exports.goToLogin = "Go to login";
exports.Retry = "Retry"
exports.getSubscriptionPlansEndPoint = "bx_block_stripe_integration/subscription_plan";
exports.makeSubscriptionPaymentEndPoint = "bx_block_subscriptions/subscriptions"
// Customizable Area End
