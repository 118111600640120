import React from "react";

// Customizable Area Start
import {
    Box,
    styled,
    Typography,
    Button,
    Modal
} from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import SubscriptionPlanForm from "../../../components/src/SubscriptionPlanForm";
// Customizable Area End

import SubscriptionPlanController, {
    Props,
    configJSON,
} from "./SubscriptionPlanController";

// Customizable Area Start
const tabArray = [
    { id: 1, label: "Select Plan" },
    { id: 2, label: "Payment Information" },
    { id: 3, label: "Confirmation" }
];
// Customizable Area End

export default class SubscriptionPlan extends SubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTabs = () => {
        return (
            <Box style={webStyle.mainTabBox}>
                {tabArray.map((item, index) => (
                    <Box key={index} style={webStyle.tabItemBox}>
                        {this.state.activeTab === item.id ? (
                            <>
                                <Box
                                    style={{
                                        ...webStyle.tabCountBox,
                                        border: "1px solid #1C386D",
                                    }}
                                >
                                    <GradientText style={webStyle.tabText}>
                                        {item.id}
                                    </GradientText>
                                </Box>
                                <GradientText style={webStyle.tabText}>
                                    {item.label}
                                </GradientText>
                            </>
                        ) : (
                            <>
                                <Box
                                    style={{
                                        ...webStyle.tabCountBox,
                                        border: "1px solid #797979",
                                    }}
                                >
                                    <Typography style={{ ...webStyle.tabText, color: "#797979" }}>
                                        {item.id}
                                    </Typography>
                                </Box>
                                <Typography style={{ ...webStyle.tabText, color: "#797979" }}>
                                    {item.label}
                                </Typography>
                            </>
                        )}
                    </Box>
                ))}
            </Box>
        );
    };

    renderPlans = () => {
        const { plansList } = this.state;
        return (
            <Box style={webStyle.planMainBox as React.CSSProperties}>
                {plansList.map((item, index) => (
                    <PlanItemBox key={index}>
                        <Typography className={"planName"}>{item.title}</Typography>
                        <Typography className={"planDesc"}>{item.sub_title}</Typography>
                        <Box className={"planPriceDuration"}>
                            <Typography className={"planPrice"}>
                                {configJSON.poundCurrency}{item.price}&nbsp;
                            </Typography>
                            <Typography className={"planDuration"}>
                                /{item.duration}
                            </Typography>
                            {item.billed && (
                                <Typography className="billedText">{item.billed}</Typography>
                            )}
                        </Box>
                        <StartButton
                            data-test-id={`startButtonTestId${index}`}
                            fullWidth
                            style={webStyle.startButton}
                            onClick={() => this.handleStartNowClick(item.id)}
                        >
                            <GradientText>{configJSON.getStartNow}</GradientText>
                        </StartButton>
                        {item.description.map((item, index) => (
                            <Box key={index} className={"planBenefitItem"}>
                                <Box style={webStyle.benefitTickBox}>
                                    <CheckRoundedIcon style={webStyle.checkRoundIcon} />
                                </Box>
                                <Typography className={"planBenefitText"}>{item}</Typography>
                            </Box>
                        ))}
                    </PlanItemBox>
                ))}
            </Box>
        );
    };

    renderPaymentSuccess = () => {
        return (
            <Box data-test-id="succPaymentBoxTestId" style={webStyle.succMainBox}>
                <Box style={webStyle.succMainInnerBox}>
                <SuccessTickBox>
                    <CheckRoundedIcon style={webStyle.succCheckRoundIcon} />
                </SuccessTickBox>
                <Typography style={webStyle.modHeadingText}>
                    Your payment was successfully processed
                </Typography>
                <OkButton
                    data-test-id="loginButtonTest"
                    fullWidth
                    style={webStyle.goToLoginButton}
                    onClick={()=>this.handleNavigations("AdminAuth")}
                >
                    {configJSON.goToLogin}
                </OkButton>
                </Box>
            </Box>
        );
    };

    renderModal = () => {
        return (
            <Modal
                data-test-id="modalTestId"
                open={this.state.openModal}
                onClose={this.handleModalClose}
            >
                <Box style={webStyle.modMainBox}>
                <Box style={webStyle.modConfirmDeleteMainBox}>
                    <Typography style={webStyle.modHeadingText}>
                    Your payment failed
                    </Typography>
                    <OkButton
                        data-test-id="modalRetryButtonTestId"
                        fullWidth
                        style={webStyle.retryButton}
                        onClick={this.handleModalClose}
                    >
                    {configJSON.Retry}
                    </OkButton>
                </Box>
                </Box>
            </Modal>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { activeTab, selectedPlan } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                {this.renderTabs()}
                {activeTab === 1 && this.renderPlans()}
                {activeTab === 2 &&
                    <SubscriptionPlanForm
                      data-test-id="subscriptionPaymentFormTestId"
                      selectedPlan={selectedPlan}
                      handleMakePayment={this.makePaymentApi}
                      handleBackClick={this.handleBackClick}
                    />
                }
                {activeTab === 3 && this.renderPaymentSuccess()}
                {this.renderModal()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PlanItemBox = styled(Box)({
    width: "300px",
    background: "white",
    padding: "30px 20px",
    borderRadius: "12px",
    "& .planName": {
        fontWeight: "700",
        fontSize: "18px",
        marginBottom: "6px",
    },
    "& .planDesc": {
        color: "#797979",
        lineHeight: "18px",
        fontSize: "14px",
        marginBottom: "20px",
    },
    "& .planPriceDuration": {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        "& .planPrice": {
            fontSize: "30px",
        },
        "& .planDuration": {
            fontSize: "16px",
            fontWeight: 400,
        },
    },
    "& .billedText": {
        background: "#E0E0E080",
        borderRadius: "2px",
        color: "black",
        fontSize: "14px",
        padding: "4px 5px",
        marginLeft: "25px",
    },
    "& .planBenefitItem": {
        display: "flex",
        gap: "16px",
        margin: "10px 0",
        "& .planBenefitText": {
            lineHeight: "18px",
            fontSize: "14px",
        },
    },
    "&:hover": {
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        "& .planName": {
            color: "white",
        },
        "& .planDesc": {
            color: "#FBFBFB",
        },
        "& .planPriceDuration": {
            "& .planPrice": {
                color: "white",
            },
            "& .planDuration": {
                color: "#FBFBFB",
            },
        },
        "& .billedText": {
            background: "#384e79",
            color: "#FBFBFB",
        },
        "& .planBenefitItem": {
            "& .planBenefitText": {
                color: "#FBFBFB",
            },
        },
    },
});

const StartButton = styled(Button)({
    border: "1px solid #1C386D",
    textTransform: "none",
    borderRadius: "10px",
    height: "44px",
    background: "white",
    "&:hover": {
        background: "white",
    },
    "& .MuiTypography-root": {
        fontWeight: "bold",
    },
});

const GradientText = styled(Typography)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
});

const SuccessTickBox = styled(Box)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "25px",
    width: "45px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "18px"
});

const OkButton = styled(Button)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none"
});

const webStyle = {
    mainBox: {
        width: "100%"
    },
    mainTabBox: {
        display: "flex",
        margin: "40px auto",
        width: "fit-content",
        gap: "18px"
    },
    modMainBox: {
        display: "flex",
        justifyContent: "center",
        height: "100vh"
    },
    modHeadingText: {
        fontSize: "18px",
        fontWeight: 700,
        textAlign: "center"
    } as React.CSSProperties,
    retryButton: {
        marginTop: "20px",
        height: "48px"
    },
    goToLoginButton: {
        marginTop: "20px",
        height: "38px"
    },
    succCheckRoundIcon: {
        fontSize: "30px",
        color: "#fff",
    },
    succMainBox: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    modConfirmDeleteMainBox: {
        marginTop: "100px",
        padding: "26px 46px",
        background: "white",
        borderRadius: "16px",
        height: "fit-content",
        width: "fit-content",
        boxSizing: "border-box",
    } as React.CSSProperties,
    succMainInnerBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30px",
        padding: "26px",
        background: "white",
        borderRadius: "12px",
        width: "fit-content",
        boxSizing: "border-box"
    } as React.CSSProperties,
    tabItemBox: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    tabText: {
        fontWeight: 700,
    },
    tabCountBox: {
        borderRadius: "25px",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    planMainBox: {
        display: "flex",
        justifyContent: "center",
        marginTop: "30px",
        gap: "20px",
        flexWrap: "wrap"
    },
    benefitTickBox: {
        background: "#ECF2FF",
        borderRadius: "25px",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0
    } as React.CSSProperties,
    startButton: {
        marginBottom: "25px"
    },
    checkRoundIcon: {
        fontSize: "16px",
        color: "#1C386D"
    },
};
// Customizable Area End
