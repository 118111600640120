import React, { useState, useContext, useEffect } from 'react'
import Tour from './Tour'
import { ProviderProps, StepType, TourProps } from './type.d'

const defaultState: TourProps = {
  isOpen: false,
  setIsOpen: () => false,
  tourLoading: false,
  setTourLoading: () => false,
  currentStep: 0,
  setCurrentStep: () => 0,
  updateInStep: () => { },
  steps: [],
  setSteps: () => [],
  hideActions: {
    next: false,
    back: false,
    close: false,
  },
  setHideActions: () => ({
    next: false,
    back: false,
    close: false,
  }),
  components: {},
}

const TourContext = React.createContext<TourProps>(defaultState)

const TourProvider: React.FC<ProviderProps> = ({
  children,
  defaultOpen = false,
  steps: defaultSteps,
  setCurrentStep: customSetCurrentStep,
  currentStep: customCurrentStep,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [tourLoading, setTourLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [steps, setSteps] = useState(defaultSteps)
  const [hideActions, setHideActions] = useState({})
  const updateInStep = (stepNo: number, updatedObj: Partial<StepType>) => {
    setSteps(a => {
      a[stepNo] = {
        ...a[stepNo],
        ...updatedObj
      }
      return a
    })
  }

  const value = {
    isOpen,
    setIsOpen,
    tourLoading,
    updateInStep,
    setTourLoading,
    currentStep: customCurrentStep || currentStep,
    setCurrentStep:
      customSetCurrentStep && typeof customSetCurrentStep === 'function'
        ? customSetCurrentStep
        : setCurrentStep,
    steps,
    setSteps,
    setHideActions,
    hideActions,
    ...props,
  }

  return (
    <TourContext.Provider value={value}>
      {children}
      {isOpen ? <Tour {...value} /> : null}
    </TourContext.Provider >
  )
}

export { TourProvider }

export default TourContext

export function useTour() {
  return useContext(TourContext)
}
