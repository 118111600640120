import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getCookie } from "../../../components/src/Tour/ManageCookies";
import { shortQuestion } from "../../../components/src/Tour/DashboardTour";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  questionIndex: number;
  classes: any;
  // Customizable Area Start
  surveyId: string;
  questionType: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseList: any;
  rowsPerPage: number;
  page: number;
  responseCount: number;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TextualTypeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getResponseListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receiveResponse.bind(this);
    // Customizable Area Start
    this.state = {
      responseList: [],
      rowsPerPage: 10,
      page: 1,
      responseCount: 0,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receiveResponse(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const ApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const jsonResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!ApiRequestCallId || !jsonResponse) {
      return;
    }

    if (ApiRequestCallId === this.getResponseListCallId && jsonResponse) {
      this.responseListReceive(jsonResponse);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    await Promise.all([this.getResponseList()]);
    // Customizable Area End
  }

  getResponseList = () => {
    // Customizable Area Start
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${this.getApiName(this.props.questionType)}?id=${this.props.surveyId}&page=${this.state.page}
      &per_page=${this.state.rowsPerPage}&question_id=${this.props.questionIndex}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getResponsesApiMethod
    );

    this.getResponseListCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  responseListReceive = (jsonResponse: any) => {
    // Customizable Area Start
     const part = getCookie("productTour");
        if (part === "Part six completed") {
          jsonResponse = shortQuestion;
        }
    if (jsonResponse?.responses) {
      this.setState({ responseList: jsonResponse.responses, responseCount: jsonResponse.total_count });
    }
    // Customizable Area End
  };

  // Customizable Area Start
  handleResponsePagination = (e: any, val: any) => {
    this.setState({ page: val }, this.getResponseList);
  };

  getApiName = (questionType: string): string => {
    switch (questionType) {
      case "short-text":
        return configJSON.getShortResponsesApiEndpoint;
      case "long-text":
        return configJSON.getLongResponsesApiEndpoint;
      default:
        return "";
    }
  };
  // Customizable Area End
}
