Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.export = "Export";
exports.adhocReportEndPoint = "/bx_block_groups/ad_hoc_reporting";
exports.filterPlaceholders = {
  survey: "Select survey",
  tag: "Select any tag",
  authority: "Select local authority",
  industry: "Select Industry"
};
// Customizable Area End