import React from 'react'
import { defaultComponents } from './index'
import { PopoverContentProps } from '../type.d'
import { enableScroll } from '../../utils/util'

const PopoverContent: React.FC<PopoverContentProps> = ({
  styles,
  components = {},
  accessibilityOptions,
  hideActions,
  onClickClose,
  steps,
  setCurrentStep,
  currentStep,
  transition,
  setIsOpen,
  setTourLoading,
  nextButton,
  prevButton,
  rtl,
  showNavigation = true,
  setSteps,
  onNext,
  onBack
}) => {
  const step = steps[currentStep]
  const { Content, Navigation } =
    defaultComponents(components)

  function closeClickHandler() {
    if (!hideActions?.close) {
      setIsOpen(false)
      step?.OnClose?.()
      enableScroll()
      setCurrentStep(-1)
      onClickClose?.({
        setCurrentStep,
        setIsOpen,
        setTourLoading,
        currentStep,
        steps,
        setSteps,
      })
    }
  }

  return (
    <React.Fragment>
      <Content
        content={step?.content}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        transition={transition}
        setIsOpen={setIsOpen}
        setLoading={setTourLoading}
      />
      {showNavigation ? (
        <Navigation
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          setIsOpen={setIsOpen}
          setTourLoading={setTourLoading}
          steps={steps}
          styles={styles}
          aria-hidden={!accessibilityOptions?.showNavigationScreenReaders}
          nextButton={nextButton}
          prevButton={prevButton}
          hideActions={{ next: hideActions?.next, back: hideActions?.back }}
          rtl={rtl}
          onNext={onNext}
          onBack={onBack}
          closeClickHandler={closeClickHandler}
        />
      ) : null}
    </React.Fragment>
  )
}

export default PopoverContent
