Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.errorEmailNotValid = "Invalid email address";
exports.errorInvalidPasswordMessage = "Incorrect password. Please enter valid password";
exports.errorPasswordNotValid = "Password must contain 10 characters, 1 special character, 1 uppercase letter, 1 lowercase letter and one number";
exports.errorFieldRequired = "Error : Missing required field(s)";
exports.errorPrivacyPolicy = "Error : Please accept the privacy policy and terms of use";
exports.errorPasswordNotMatch = "Error : Passwords do not match";
exports.signUpSuccessMessage = "Your account has been created but need to be verified over email. Check your email to verify your account.";
exports.signUpSuccessHeader = "Congratulations!";
exports.verifyAccountHeader = "Success!";
exports.verifyAccounBodyMessage = "Your email has been verified. Please login with the password you just created to complete your signup.!";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.getCalendar = "bx_block_surveys/surveys";
exports.getGroup = "bx_block_groups/groups";
exports.getUser ="bx_block_groups/groups";
exports.putApiMethod = "PUT";
exports.getSurveyUsersEndPoint = "bx_block_user/users/users_email_list_for_groups"
exports.removeUserGroupApiEndPoint = "/bx_block_groups/groups/remove_user"
exports.selectUserGroupApiEndPoint = "/bx_block_groups/groups/add_user"
exports.editLiveSurvey = "bx_block_surveys/surveys/"
// Customizable Area End